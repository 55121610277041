// @flow
import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalcomponent.scss';
import PropTypes from 'prop-types';

const ModalComponent = (props) => {

	const handleClose = ()=>{
		props.handleClose();
	}
	return (
		<div>
			{props.open && (
				<Modal
					show={true}
					onHide={handleClose}
					//size="lg"
					centered
					dialogClassName={props.className ? props.className : 'setting-modals'}
					backdropClassName={props.overlayClassName || ''}
					keyboard
				>
					{props.header && <Modal.Header closeButton={props.closeButton}>{props.header}</Modal.Header>}
					<Modal.Body>{props.content}</Modal.Body>
					<Modal.Footer>{props.footer}</Modal.Footer>
				</Modal>
			)}
		</div>
	);
};

export default ModalComponent;

ModalComponent.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	className: PropTypes.string,
	overlayClassName: PropTypes.string,
	header: PropTypes.any,
	content: PropTypes.any,
	footer: PropTypes.element,
	closeButton: PropTypes.bool
};

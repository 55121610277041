import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ModalComponent from '../../component/modal/ModalComponent';
import CustomSelect from '../../component/customselect/CustomSelect';
import { showError, showSuccess } from '../../utils/toaster';
import { sortArrayString } from '../../utils/commonUtils';
import './addnewuser.scss';
import { getOrganization, updateUserDetail, addNewUser, deleteUserDetails } from './addUserHelper';
import StaticLabels from '../../utils/messages';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserDetail from './UserDetail';
import UserOrganization from './UserOrganization';
import UserRole from './UserRole';
import { showLoader } from '../../utils/commonUtils';
import CustomModalFooter from '../../component/modal/CustomModalFooter';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import auth0 from 'auth0-js';
import AUTH_CONFIG from '../../constants';

function AddUserContainer(props) {
	const [ key, setKey ] = useState('1');
	const [ userDetail, setUserDetail ] = useState({
		/* eslint-disable */
		first_name: '',
		last_name: '',
		email: '',
		phone_number: props.testPhoneNo ? props.testPhoneNo : '',
		enabled: 'true',
		id: 0,
		password: '',
		verify_email: false,
		roles: [],
		organization_id: ''
		/* eslint-enable */
	});
	const [ organization, setOrganization ] = useState([]);
	const [ roleList, setRoleList ] = useState([]);
	const [ errors, setError ] = useState([]);
	const [ orgId, setorgId ] = useState(0);
	const [ roles, setRoles ] = useState([]);
	const [ apiError, setApiError ] = useState('');
	const [ disabled, setDisabled ] = useState(false);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const statusOption = [
		{
			value: 'true',
			label: 'Active'
		},
		{
			value: 'false',
			label: 'Inactive'
		}
	];
	// const { rolesList } = props;

	const getRoleList = () => {
		let updateList = props.rolesList.map((role, index) => {
			let list = {};
			list = {
				id: index,
				name: role.name,
				value: role.displayName,
				isChecked: false
			};
			return list;
		});

		// userinforole = ['admin', 'researcher']
		// role=[{admin}, {rese},{cust}]
		// TODO-- filter updatelist wrt userinfo.roles and set ischecked = true

		if (props.userInfo) {
			props.userInfo.roles.map((userRole) => {
				return updateList.map((role) => {
					if (userRole === role.name) {
						return (role.isChecked = true);
					} else if (role.isChecked === true) {
						return role;
					}
					return role;
				});
			});

			const userOrgType = organization.filter((item) => item.value === orgId)[0].type;
			if (userOrgType === 'Customer') {
				updateList = updateList.filter((check) => check.name === 'ROLE_RESEARCH');
			}
		}
		setRoleList(updateList);
		const role = updateList.filter((check) => check.isChecked === true).map((check) => check.name);
		setRoles(role);
	};

	const generatePassword = () => {
		let pass = '';
		const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const str2 = 'abcdefghijklmnopqrstuvwxyz';
		const num = '0123456789';
		const specialChar = '@!"£$%^&*()';

		for (let i = 1; i <= 2; i++) {
			let char = Math.floor(Math.random() * str.length);
			pass += str.charAt(char);
			char = Math.floor(Math.random() * str2.length);
			pass += str2.charAt(char);
			char = Math.floor(Math.random() * num.length);
			pass += num.charAt(char);
			char = Math.floor(Math.random() * specialChar.length);
			pass += specialChar.charAt(char);
		}

		const newPass = pass
			.split('')
			.sort(() => {
				return 0.5 - Math.random();
			})
			.join('');

		setUserDetail({ ...userDetail, password: newPass });
		return newPass;
	};

	useEffect(
		() => {
			generatePassword();
			showLoader(true);
			getOrganization()
				.then((resp) => {
					showLoader(false);
					const response = resp && resp.data.payload;
					const updateList = response.map((org, index) => {
						//   let organisation = {};
						return {
							label: org.name,
							value: org.id,
							type: org.org_type,
							status: org.status
						};
					});

					if (props.addUser) {
						const activeOrgList = updateList.filter((check) => check.status === true);
						setOrganization(sortArrayString(activeOrgList, 'label', 'asc'));
					} else {
						setOrganization(sortArrayString(updateList, 'label', 'asc'));
					}
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.userInfo) {
				setUserDetail(props.userInfo);
				setDisabled(true);
				setorgId(props.userInfo.organization_id);
			}
		},
		[ props.addUser, props.userInfo ]
	);
	useEffect(
		() => {
			if (organization.length > 0) {
				getRoleList();
			}
		},
		[ organization ]
	);

	const handleCheck = (event) => {
		roleList.map((role) => {
			if (role.value === event.target.value) role.isChecked = event.target.checked;

			return role;
		});

		setRoleList(roleList);
		const role = roleList.filter((check) => check.isChecked === true).map((check) => check.name);
		setRoles(role);
	};

	const updatePhoneNumber = (value) => {
		setUserDetail({
			...userDetail,
			phone_number: value || ''	//eslint-disable-line
		});
	};

	const updateValue = (e) => {
		const value = e.target.value;

		setUserDetail({
			...userDetail,
			[e.target.name]: value
		});
	};

	const onOrganizationChange = (e) => {
		setorgId(e.value);
		setError([]);

		if (e.type === 'Customer') {
			const role = roleList.filter((check) => check.name === 'ROLE_RESEARCH');

			setRoleList(role);

			role.map((check) => check.name);
			// setRoles(role);
		} else {
			getRoleList();
		}
	};
	
	const resetUserList = () => {
		props.handleClose();
		props.getUserList();
		showLoader(false);
	};

	const onSave = (errorr) => {
		userDetail.roles = roles;
		userDetail.organization_id = orgId; //eslint-disable-line
		userDetail.enabled = userDetail.enabled.toString() === 'true' ? true : false;
		delete userDetail.user_roles;

		setUserDetail(userDetail);

		if (Object.keys(errorr).length === 0) {
			showLoader(true);
			if (props.userInfo) {
				updateUserDetail(userDetail)
					.then(() => {
						resetUserList();
						showSuccess(StaticLabels.Success_User_Updated);
					})
					.catch((error) => {
						showLoader(false);
						if (error && error.data) {
							setApiError(error.data.payload);
							console.log(error);
						} else {
							setApiError(StaticLabels.Error_Common);
						}
					});
			} else {
				addNewUser(userDetail)
					.then((resp) => {
						resetUserList();
						showSuccess(StaticLabels.Success_User_Added);
					})
					.catch((error) => {
						showLoader(false);
						if (error && error.data) {
							setApiError(error.data.payload);
							console.log(error);
						} else {
							setApiError(StaticLabels.Error_Common);
						}
					});
			}
		}
	};

	const handleNextClick = (newKey) => {
		const error = [];
		const { first_name, last_name, email, phone_number } = userDetail;   //eslint-disable-line

		setError([]);
		setApiError('');
		/* eslint-disable */
		if (key === '1') {
			if (!first_name.trim()) {
				error['first_name'] = StaticLabels.Error_Fname;
			}

			if (!last_name.trim()) {
				error['last_name'] = StaticLabels.Error_Lname;
			}

			if (!email.trim()) {
				error['email'] = StaticLabels.Error_Email;
			}

			if (email) {
				const regEmail = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
				if (!regEmail.test(email)) {
					error['email'] = StaticLabels.Error_Valid_Email;
				}
			}

			if (phone_number.trim()) {
				if (!isValidPhoneNumber(phone_number)) {
					error['phone_number'] = StaticLabels.Error_Invalid_Phone;
				}
			} else {
				error['phone_number'] = StaticLabels.Error_Phone;
			}
		}
		if (key === '2') {
			if (!orgId) {
				error['organization'] = StaticLabels.Error_Organization;
			}
		}
		if (key === '3') {
			if (!roles.length) {
				error['roles'] = StaticLabels.Error_Roles;
			}
		}
		/* eslint-enable */

		setError(error);

		const setOfError = error['first_name'] || error['last_name'] || error['email'] || error['phone_number'];

		if (key === '1' && !setOfError) {
			if (typeof newKey === 'string') {
				setKey(newKey);
			} else {
				setKey('2');
			}
			setError([]);
			setApiError('');
		} else if (key === '2' && !error['organization']) {
			if (typeof newKey === 'string') {
				setKey(newKey);
			} else {
				setKey('3');
			}
			setError([]);
			setApiError('');
		} else if (key === '3' && !props.userInfo) {
			if (!error['roles']) {
				if (typeof newKey === 'string') {
					setKey(newKey);
				} else {
					onSave(error);
				}
			}
		} else if (props.userInfo && key === '3') {
			if (!error['roles']) {
				if (typeof newKey === 'string') {
					setKey(newKey);
				} else {
					setKey('4');
				}
			}
		} else if (key === '4') {
			if (!setOfError) {
				if (typeof newKey === 'string') {
					setKey(newKey);
				} else {
					onSave(error);
				}
			}
		}
	};

	const handleSelect = (newKey) => {
		handleNextClick(newKey);
	};

	const onStatusChange = (e) => {
		setUserDetail({
			...userDetail,
			enabled: e.value
		});
	};

	const removeUser = () => {
		setConfirmDelete(true);
	};

	const closeDeleteModal = () => {
		setConfirmDelete(false);
	};

	const deleteUser = (userId) => () => {
		showLoader(true);
		deleteUserDetails(userId)
			.then((resp) => {
				resetUserList();
				// closeDeleteModal();
				showError(StaticLabels.Success_User_Deleted)
			})
			.catch((error) => {
				showLoader(false);
				if (error && error.response) {
					closeDeleteModal();
					if (error.response.status === 500) {
						setApiError(error.response.data.status);
					} else {
						setApiError(error.response.data.payload);
					}
				} else {
					setApiError(StaticLabels.Error_Common);
				}
			});
	};

	const resetPassword = () => {
		showLoader(true);
		if (userDetail.email) {
			const webAuth = new auth0.WebAuth({
				domain: AUTH_CONFIG.domainName,
				clientID: AUTH_CONFIG.clientID
			});

			// Validating the change password api end point for Auth0.
			webAuth.changePassword(
				{
					connection: AUTH_CONFIG.connection,
					email: userDetail.email
				},
				(err, authResult) => {
					userDetail.password = generatePassword();
					delete userDetail.user_roles;
					updateUserDetail(userDetail)
						.then(() => {
							resetUserList()
							showSuccess(StaticLabels.Success_User_Email_Sent);
						})
						.catch((error) => {
							showLoader(false);
							if (error && error.data) {
								setApiError(error.data.payload);
								console.log(error);
							} else {
								setApiError(StaticLabels.Error_Common);
							}
						});
				}
			);
		}
	};

	return (
		<ModalComponent
			open={props.open}
			handleClose={props.handleClose}
			centered
			dialogClassName={props.className ? props.className : 'setting-modals'}
			keyboard
			className="adduser-modal"
			header={props.userInfo ? StaticLabels.MP_Edit_User_Title : StaticLabels.MP_Add_User_Title}
			content={
				<React.Fragment>
					<div className="modal-tabs">
						<Tabs
							className="nav-pills nav-tabs-update"
							activeKey={key}
							onSelect={handleSelect}
							id="controlled-tab-example"
						>
							<Tab eventKey={1} title={StaticLabels.MP_User_Detail_Tab}>
								{' '}
								{
									<UserDetail
										updatePhoneNumber={updatePhoneNumber}
										onChange={updateValue}
										className="userDetail"
										handleNextClick={handleNextClick}
										errors={errors}
										userDetail={userDetail}
										disabled={disabled}
									/>
								}{' '}
							</Tab>{' '}
							<Tab eventKey={2} title={StaticLabels.MP_User_Organization_Tab}>
								<UserOrganization
									orgId={orgId}
									onChange={onOrganizationChange}
									organization={organization}
									disabled={disabled}
									errors={errors}
								/>{' '}
							</Tab>{' '}
							<Tab eventKey={3} title={StaticLabels.MP_User_Role_Tab}>
								<UserRole roleList={roleList} handleCheck={handleCheck} errors={errors} />{' '}
								{!props.userInfo && (
									<div className="error_field">
										{' '}
										{errors ? (
											<span>
												{' '}
												{errors['first_name'] ? (
													errors.first_name
												) : errors['last_name'] ? (
													errors.last_name
												) : errors['email'] ? (
													errors.email
												) : (
													errors.phone_number
												)}{' '}
											</span>
										) : null}
										{apiError ? (
											<span> {apiError[0].message ? apiError[0].message : apiError} </span>
										) : null}{' '}
									</div>
								)}{' '}
							</Tab>{' '}
							{props.userInfo && (
								<Tab eventKey={4} title={StaticLabels.MP_User_Setting_Tab}>
									<div className="section">
										<div className="section setting">
											<label id="password">{StaticLabels.MP_User_Password}</label>{' '}
											<ButtonNormal
												id="reset-password"
												className="adduser-btn btn btn-primary"
												onClick={resetPassword}
												text={StaticLabels.MP_User_Password_Btn}
											/>
										</div>
										{
											<div className="section setting">
												<label id="user-status">{StaticLabels.MP_User_Status}</label>{' '}
												<CustomSelect
													className={'custom-status-select'}
													placeholder={StaticLabels.MP_User_Organization_Placeholder}
													options={statusOption}
													value={
														userDetail.enabled.toString() === 'true' ? (
															{
																value: 'true',
																label: 'Active'
															}
														) : (
															{
																value: 'false',
																label: 'Inactive'
															}
														)
													}
													onChange={onStatusChange}
												/>{' '}
											</div>
										}
										<div className="delete_field">
											<span className="deleteuser" onClick={removeUser}>
												{StaticLabels.MP_User_Delete}
											</span>{' '}
										</div>
										{confirmDelete && (
											<ModalComponent
												open={true}
												header={StaticLabels.MP_Delete_Header}
												className="delete-modal"
												overlayClassName="delete-modal-overlay"
												handleClose={closeDeleteModal}
												content={
													<div>
														<p>{StaticLabels.MP_Delete_User}</p>
														<p>{StaticLabels.MP_Delete_Warning}</p>
													</div>
												}
												footer={
													<React.Fragment>
														<ButtonNormal
															buttonType="1"
															className="buttons cancel-btn"
															onClick={closeDeleteModal}
															text={StaticLabels.MP_Delete_Cancel_Btn}
														/>
														<ButtonNormal
															buttonType="3"
															className="buttons delete-btn"
															onClick={deleteUser(props.userInfo.id)}
															text={StaticLabels.MP_Delete_Btn}
														/>
													</React.Fragment>
												}
											/>
										)}
										{
											<div className="error_field" id="api-error">
												{' '}
												{errors ? (
													<span>
														{' '}
														{errors['first_name'] ? (
															errors.first_name
														) : errors['last_name'] ? (
															errors.last_name
														) : errors['email'] ? (
															errors.email
														) : (
															errors.phone_number
														)}{' '}
													</span>
												) : null}
												{apiError ? (
													<span>
														{' '}
														{apiError[0].message ? apiError[0].message : apiError}{' '}
													</span>
												) : null}{' '}
											</div>
										}{' '}
									</div>{' '}
								</Tab>
							)}{' '}
						</Tabs>{' '}
					</div>{' '}
				</React.Fragment>
			}
			footer={
				<CustomModalFooter
					tabKey={(props.addUser ? key === '3' : key === '4') ? true : false}
					handleClose={props.handleClose}
					handleNextClick={handleNextClick}
					Info={props.userInfo}
				/>
			}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		rolesList: state.redCommon.roles
	};
};

export default connect(mapStateToProps)(AddUserContainer);

AddUserContainer.propTypes = {
	userInfo: PropTypes.object,
	addUser: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	getUserList: PropTypes.func,
	className: PropTypes.string,
	rolesList: PropTypes.arrayOf(PropTypes.object),

};

import React from 'react';
import './studycontainer.scss';
import { connect } from 'react-redux';
import Header from '../../component/header/Header';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StudyContainer = (props) => {
	// const { loggedInUser, availableDataset, documentList, notificationList } = props;

	return (
		<div id="study-container">
			<Header preText='Study:' title='Galactical study' hideSearch={true} />
			<div className="study-info-box">
				<Tabs className="nav-pills nav-tabs-update" activeKey={1} id="controlled-tab-example">
					<Tab eventKey={1} title={'Overview'} />
					<Tab eventKey={2} title={'Design'} />
					<Tab eventKey={3} title={'Results'} />
					<Tab eventKey={4} title={'Validity'} />
					<Tab eventKey={5} title={'Export'} />
					<Tab eventKey={6} title={'Documents'} />
				</Tabs>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		availableDataset: state.redCommon.availableDataset
	};
};

export default connect(mapStateToProps)(StudyContainer);

StudyContainer.propTypes = {
	loggedInUser: PropTypes.object,
	availableDataset: PropTypes.array,
	documentList: PropTypes.array,
	notificationList: PropTypes.array,
};

import { apiGet, apiPut } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';
import store from '../../store';
import actionType from '../../utils/actionTypes';

export const getUserInfo = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetLoggedInUserInfo, {})
			.then((resp) => {
				const options = {
					type: actionType.USER_INFO,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getUserdetail = (userId) =>{
    return new Promise((resolve, reject) => {
        apiGet(`${ apiUrl.GetUserInfo }/${ userId }`).then(resp => {
            resolve(resp);
        }).catch((err) => {
            console.error(err);
            reject(err);
        })
    })
};

export const updateUserDetail = (userDetail) => {
    return new Promise((resolve, reject) => {
        apiPut(apiUrl.UserProfile, userDetail).then((resp) =>{
            resolve();
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    }) 
}
import axios from 'axios';
import constants from '../constants';
import history from './history';
// import store from '../store';
export function generateUrl(path) {
	return constants.API_URL + path;
}

export function apiPost(endpoint, data) {
	return apiReq(generateUrl(endpoint), data, 'post');
}

export function apiPut(endpoint, data = {}, header = {}) {
	return apiReq(generateUrl(endpoint), data, 'put', header);
}

export function apiDelete(endpoint, data = {}) {
	return apiReq(generateUrl(endpoint), data, 'delete');
}

/**************************************** 
     * Function Name: apiPostForm
     * Description: Convert an object to form data for post call
  ****************************************/

export function apiPostForm(endpoint, data) {
	var bodyFormData = new FormData();
	if (Array.isArray(data)) {
		bodyFormData.set('m_JSONArrayList', JSON.stringify(data));
	} else {
		Object.keys(data).forEach((val, index) => {
			bodyFormData.set(val, data[val]);
		});
	}

	return apiReq(generateUrl(endpoint), bodyFormData, 'post');
}

export function apiGet(endpoint, data, headers = {}) {
	return apiReq(generateUrl(endpoint), data, 'get', headers);
}

export function apiReq(endpoint, data, method, headers) {
	return new Promise((res, rej) => {
		const accessToken = sessionStorage.getItem('accessToken');

		headers = {
			...headers,
			Authorization: `Bearer ${accessToken}`
		};

		if (method === 'get') {
			data = {
				params: data,
				headers,
				withCredentials: true
			};
		}
		if (method === 'delete') {
			axios
				[method](endpoint, { headers })
				.then((result) => {
					return res(result);
				})
				.catch((err) => {
					//-- TODO:- hanldle 401, 404, 403
					// if (err.response.status === 401) {
					//   // removeItem('user');
					//   history.push('');
					//  // window.location.reload();
					//   return;
					// }
					console.log(err);
					return rej(err);
				});
		} else {
			axios
				[method](endpoint, data, { headers })
				.then((result) => {
					return res(result);
				})
				.catch((err) => {
					if(err.response) {
						if (err.response.status === 401) {
							if (sessionStorage.getItem('dbverified') === 'false') {
								sessionStorage.clear();
								history.push('/');
								sessionStorage.setItem('dbverified', 'false');
								window.location.reload();
								return rej(err.response);
							} else if(endpoint.indexOf('/checkenrollment') !== -1 ) {
								sessionStorage.clear();
								return rej(err.response);
							} else {
								sessionStorage.clear();
								history.push('/');
								window.location.reload();
								return rej(err.response);
							}
						} else if (err.response.status === 403) {
							sessionStorage.clear();
							history.push('/');
							window.location.reload();
							return rej(err.response);
						} else if (err.response.status === 400) {
							return rej(err.response);
						} else if (err.response.status === 500) {
							//-- TODO:- show error toaster
							return rej(err.response);
						} else if (err.response.status === 404) {
							//-- TODO:- show error toaster
							return rej(err.response);
						}
					} else {
						rej(err);
					}
					
				});
		}
	});
}

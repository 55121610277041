const initState = {
    currentUser: {}
}

const UserAuth = (state = initState, action) => {
    switch(action.type){
        // case 'UserInfo': 
        //     return{
        //         ...state,
        //         currentUser: action.payload
        //     }
        default: return state;
    }
}

export default UserAuth;
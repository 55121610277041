import store from '../../store';
import actionType from '../../utils/actionTypes'
import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const authenticateUser = (option) => {
    return new Promise((resolve, reject)=>{
        apiGet('/Test/authenticated', option).then((resp)=>{
            const options = {
                type: actionType.AUTHENTICATED,
                payload: resp.data.payload || ''
            }

            store.dispatch(options);
            resolve(resp);
        }).catch((err) => {
			console.log(err);
            reject(err);
        })
    });
}

export const getUserInfo = (option) => {
    return new Promise((resolve, reject)=>{
        apiGet('/users/info', option).then((resp)=>{
           
            const options = {
                type: actionType.USER_INFO,
                payload: resp.data.payload || ''
            }
            store.dispatch(options);
            resolve(resp);
        }).catch((err) => {
            console.log(err);
          
            reject(err);
        })
    });
}

export const getActiveDataset = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetActiveDataset, {})
			.then((resp) => {
				const options = {
					type: actionType.AVAILABLE_DATA_SET,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getStudyDocumentList = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetStudyDocumentList, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const setStudyDocumentList = (docList) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.STUDY_DOCUMENT_LIST,
			payload: docList
		};
		store.dispatch(options);
		resolve()
	});
}
// const userAction = {
//     authenticateUser: authenticateUser,
//     getUserInfo: getUserInfo
// }

// export default userAction;
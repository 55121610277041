import React, { useState, useEffect, Suspense, useRef, lazy } from 'react';
import './customerlist.scss';
import { connect } from 'react-redux';
import { getCustomerlist, getCustomerDetails } from './customerListHelper';
import Header from '../../component/header/Header';
import SortColumn from '../../component/sortcolumn/SortColumn';
// import PaginationComponent from '../../component/pagination/PaginationComponent';
import StaticLabels from '../../utils/messages';
import AddCustomerContainer from '../addcustomer/AddCustomerContainer';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import HighlightText from '../../component/highlighttext/HighlightText';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const InfiniteScroll = lazy(() => import('../../component/infiniteScroll/InfiniteScroll'));

const CustomerListContainer = (props) => {
	const customerSortCol = sessionStorage.getItem('customerSortCol') || 'created_date';
	const customerSortOrd = sessionStorage.getItem('customerSortOrd') || 'dsc';
	const sKey = 'customerRecPerPage';
	const sValue = sessionStorage.getItem(sKey);
	const [ customerList, setCustomerList ] = useState([]);
	const [ addCustomer, setAddCustomer ] = useState(false);
	const [ editCustomer, setEditCustomer ] = useState(false);
	const [ customerInfo, setCustomerInfo ] = useState({});
	const [ isDescending, setIsDescending ] = useState(customerSortOrd === 'dsc' ? true : false);
	const [ sortOrder, setSortOrder ] = useState(customerSortOrd);
	const [ sortedColumn, setSortedColumn ] = useState(customerSortCol);
	const [ pageNo, setPageNo ] = useState(1);
	const [ recordsPerPage, setRecordsPerPage ] = useState(sValue || 10);
	// const [ totalCustomerCount, setTotalCustomerCount ] = useState(0);
	const [ searchText, setSearchText ] = useState('');
	const [ toggleState, setToggleState ] = useState(false);
	const { loggedInUser } = props;
	const isRoot =
		loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ROOT');
	const isAdmin =
		loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ADMIN');

	const [ haveMoreUsers, setHaveMoreUsers ] = useState(false);
	const [ loading, setloading ] = useState(false);
	const isInitialMount = useRef(true);

	const setNextPage = () => {
		setPageNo((prevPageNo) => prevPageNo + 1);
	};

	const getCustomers = () => {
		getCustomerlist(pageNo, recordsPerPage, searchText, sortedColumn, isDescending)
			.then((resp) => {
				// setCustomerlist(resp.data.payload);
				// setTotalCustomerCount(resp.data.totalEntries);
				// showLoader(false);

				//add new records to list while scrolling
				const updatedList = [ ...customerList, ...resp.data.payload ];
				setCustomerList(updatedList);
				setHaveMoreUsers(resp.data.totalEntries > recordsPerPage * pageNo);
				setloading(false);
				showLoader(false);
			})
			.catch((error) => {
				showLoader(false);
				console.log(error);
			});
	};

	useEffect(
		() => {
			if (isInitialMount.current) {
				showLoader(true);
				getCustomers();
				isInitialMount.current = false;
				//call useEffect with setLoader(true) only when component mounts
			} else {
				setloading(true);
				setTimeout(() => {
					getCustomers();
				}, 2000);
			}
		},
		[ recordsPerPage, pageNo, searchText, isDescending, sortedColumn, toggleState ]
	);
	const sortColumn = (columnName, order) => {
		setSortedColumn(columnName);
		setSortOrder(order);
		sessionStorage.setItem('customerSortCol', columnName);
		sessionStorage.setItem('customerSortOrd', order);
		setPageNo(1);
		setCustomerList([]);
		setIsDescending(order === 'asc' ? false : true);
	};

	// const gotoPage = (pageNum, recordPerPage) => {
	// 	setPageNo(pageNum);
	// 	setRecordsPerPage(recordPerPage);
	// };

	const searchCustomer = (searchTxt) => {
		setSearchText(searchTxt);
		setCustomerList([]);
		setPageNo(1);
	};
	const handleClose = () => {
		setAddCustomer(false);
		setEditCustomer(false);
	};
	const showAddCustomerModel = () => {
		setAddCustomer(true);
	};

	const getCustomerDetail = (customerId) => () => {
		getCustomerDetails(customerId)
			.then((resp) => {
				setCustomerInfo(resp.data.payload);
				setEditCustomer(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const resetCustomers = () => {
		setCustomerList([]);
		setSearchText('');
		setSortedColumn('created_date');
		setSortOrder('dsc');
		setIsDescending(true);
		sessionStorage.setItem('customerSortCol', 'created_date');
		sessionStorage.setItem('customerSortOrd', 'dsc');
		setPageNo(1);
		setToggleState(!toggleState);
	};

	return (
		<div id="customer-container">
			<Header
				title={StaticLabels.Customer_Title}
				addClick={showAddCustomerModel}
				showAddButton={isRoot || isAdmin}
				showAddButtonText={StaticLabels.Customer_AddCustomer_Btn}
				searchFunction={searchCustomer}
				searchText={searchText}
			/>
			<div className="customerlist">
				<table className="tbl-list">
					<thead className="tbl-header">
						<tr>
							<td>
								<SortColumn
									className="status"
									displayName={StaticLabels.Cust_Theader_Status}
									columnName={'status'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="name"
									displayName={StaticLabels.Cust_Theader_Name}
									columnName={'name'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="type"
									displayName={StaticLabels.Cust_Theader_Type}
									columnName={'type'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="address"
									displayName={StaticLabels.Cust_Theader_Address}
									columnName={'address'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="date"
									displayName={StaticLabels.Cust_Theader_Date}
									columnName={'created_date'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td className="edit" />
						</tr>
					</thead>
					<tbody className="tbl-body">
						{// props.customerList.length > 0 &&
						// props.customerList.map((list, index) => {
						customerList.length > 0 &&
							customerList.map((list) => {
								const status = list.status;
								return (
									<tr key={list.id} className={`tbl-row ${!status ? 'inactive-row' : ''}`}>
										<td>
											<div className={`status  ${status ? 'active' : 'inactive'}`}>●</div>
										</td>
										<td>
											<HighlightText text={list.name} searchText={searchText} className="name" />
										</td>
										<td>
											<HighlightText text={list.type} searchText={searchText} className="type" />
										</td>
										<td>
											<OverlayTrigger
												trigger={[ 'hover', 'focus' ]}
												rootClose={true}
												id="email-popup"
												placement="auto"
												overlay={
													<Popover bsPrefix="popover">
														<Popover.Content>
															<span>{list.address}</span>
														</Popover.Content>
													</Popover>
												}
											>
												<div className="address-text">
													<div className="line-brk">
														<HighlightText
															text={list.address}
															searchText={searchText}
															className="address"
														/>
													</div>
												</div>
											</OverlayTrigger>
										</td>
										<td>
											<div className="date creation-date">{list.created_date}</div>
										</td>
										<td>
											<div className="edit" onClick={getCustomerDetail(list.id)}>
												{<i className="fa fa-pencil-alt" aria-hidden="true" />}
											</div>
										</td>
									</tr>
								);
							})}
						<Suspense
							fallback={
								<tr>
									<td>Loading...</td>
								</tr>
							}
						>
							<InfiniteScroll
								loading={loading}
								haveMoreRecords={haveMoreUsers}
								setNextPage={setNextPage}
							/>
						</Suspense>
					</tbody>
				</table>
				{!loading &&
				!haveMoreUsers && pageNo > 1 && (
					<div id="no-more-records" className="no-records no-more-records">
						No more records.
					</div>
				)}
				{loading && (
					<div className="loading-list">
						<div className="loading">loading</div>
						<div className="dot-pulse" />
					</div>
				)}
				{
					// 	props.customerList.length > 0 && (
					// 	<PaginationComponent
					// 		totalItemsCount={totalCustomerCount}
					// 		gotoPage={gotoPage}
					// 		currentPage={pageNo}
					// 		sessionKey={sKey}
					// 	/>
					// )
				}
				{addCustomer && (
					<Suspense fallback={<div>Loading...</div>}>
						<AddCustomerContainer
							open={addCustomer}
							addCustomer={addCustomer}
							handleClose={handleClose}
							getCustomers={resetCustomers}
						/>
					</Suspense>
				)}
				{editCustomer && (
					<AddCustomerContainer
						open={editCustomer}
						customerInfo={customerInfo}
						handleClose={handleClose}
						getCustomers={resetCustomers}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		customerList: state.redCommon.customerList
	};
};

export default connect(mapStateToProps)(CustomerListContainer);

CustomerListContainer.propTypes = {
	loggedInUser: PropTypes.object
};

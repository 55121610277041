import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';
const rawData = `<html>
<head>
	<meta content="text/html; charset=UTF-8" http-equiv="content-type">
	</head>
	<body class="c27">
		<h2 class="c52">
			<span class="c55">Verantos Virtual Appliance EHR Data Input Specification</span>
		</h2>
		<p class="c5 c15">
			<span class="c55"/>
		</p>
		<p class="c5">
			<span class="c21">The Verantos virtual appliance accepts electronic health record data using the format, vocabulary, and data dictionary defined in this document.</span>
		</p>
		<p class="c15 c57">
			<span class="c21"/>
		</p>
		<h3 class="c42">
			<span class="c51">Format</span>
		</h3>
		<p class="c5 c35">
			<span class="c21">One tab delimited file can be provided for each table specified in the data dictionary.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">The patient table is mandatory. Other tables are optional.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">The first row of each file must provide the names of the fields. All fields specified for a table in the data dictionary must be included in the header.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">The fields must occur in the order specified in the data dictionary.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">The data in a field must be of the data type specified in the data dictionary.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">Fields marked as mandatory must have a value that is not blank.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">Optional fields may have an empty string that indicates missing data and is equivalent to a null in a database field.</span>
		</p>
		<p class="c5 c35">
			<span class="c21">Integrity of foreign keys must be maintained so that any references to patient_id, encounter_id, provider_id, procedure_id, and care_site_id in a table will be satisfied by a unique row in the Patient, Encounter, Provider, Procedure, and Care_site tables respectively.</span>
		</p>
		<h3 class="c40">
			<span class="c51">Vocabulary</span>
		</h3>
		<p class="c5 c35">
			<span class="c21">The values for the following fields must be drawn from the vocabulary specified in the Code Lists section. Data destined for these fields must be translated to these codes before being populated in the fields.</span>
		</p>
		<p class="c5 c15">
			<span class="c21"/>
		</p>
		<a id="t.9d881a0a82ef9de9fec46e9c84dcd60a901fd67f"/>
		<a id="t.0"/>
		<p class="c5 c15">
			<span class="c21"/>
		</p>
		<p class="c5 c35">
			<span class="c21">For the following fields, codes must be mapped to standard vocabularies (such as SNOMED, ICD10CM, RxNorm, LOINC) where possible. If custom/proprietary code systems are used the codes and their descriptions from the proprietary code systems should be supplied in separate files.</span>
		</p>
		<p class="c5 c15">
			<span class="c21"/>
		</p>
		<a id="t.151551ae43079dd7eb1803c344492cbe421712d5"/>
		<a id="t.1"/>
		<p>METADATA_TABLE_profiling_metrics</p>
		<p class="c15 c45">
			<span class="c4"/>
		</p>
		<h3 class="c42">
			<span class="c51">Data dictionary</span>
		</h3>
		<a id="t.f78792b9106eb32afe88254babb7f37c2f16c34e"/>
		<a id="t.2"/>
		<p>METADATA_TABLE_structured_data_transform</p>
		<p class="c45 c15">
			<span class="c4"/>
		</p>
		<h3 class="c24">
			<span class="c51">Code lists</span>
		</h3>
		<h4 class="c44">
			<span class="c32">Gender</span>
		</h4>
		<a id="t.4151d42bb75c4a64b85ca946933f89b9077c734c"/>
		<a id="t.3"/>
		METADATA_TABLE_data_model
	</body>
</html>

`;

export const getDocumentContent = (documentName) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetDocumentContent.replace('##DOCUMENTNAME##', documentName), {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getDocumentRawContent = (templteId) => {
	return new Promise((resolve, reject) => {
		// console.log('rawData', rawHTML);
		// if (rawHTML) {
		// 	resolve(rawHTML);
		// } else {
		// 	reject('erorororor');
		// }
		apiGet(apiUrl.getDocumentTemplate.replace('##TEMPLATE-NAME##', templteId), {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});

		// apiGet(apiUrl.getDocumentTemplate.replace('##TEMPLATE-NAME##', templteId),{}, {responseType: 'blob'})
		// 	.then((response) => {
		// 		const file = new Blob([ response.data ], { type: 'application/pdf' });
		// 		//Build a URL from the file
		// 		const fileURL = URL.createObjectURL(file);
		// 		//Open the URL on new Window
		//         resolve({response, fileURL})
		// 		// window.open(fileURL);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 		reject(err);
		// 	});
	});
};

export const getMetadataTableContent = (tableId, studyId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.getMetadataTableContent.replace('##TABLE-ID##', tableId), {
			studyId: studyId,
			table: true
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getMetaDataProfilingMetrics = (studyId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.getMetaDataProfilingMetrics, {
			studyId: studyId
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getMetaDataStructuredDataTransform = (studyId, researchPartnerId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.getMetaDataStructuredDataTransform, {
			studyId: studyId,
			researchPartnerId: researchPartnerId
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getImageData = (imageId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.getMetatdataImage.replace('##IMAGE-ID##', imageId), {}, { responseType: 'arraybuffer' })
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

const rawHTML = {
	data: `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style>
    body {
    color: #2e3f55;
    letter-spacing: 0;
}
body h2 {
    font-weight: 300;
}
body h3 {
    font-weight: 300;
}
body h4 {
    font-weight: 300;
}
body p {
    font-size: 14px;
    line-height: 21px;
}
body ol {
    padding-left: 25px;
}
body ol > li {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-left: 10px;
}
body table {
    margin: 20px 0px;
    width: 100%;
}
body td > p {
    margin: 0;
    padding: 0;
}
body tr > td {
    box-sizing: border-box;
    border: 0.5px solid #979797;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
}
body tr:first-child td {
    background-color: #ededed;
    font-weight: bold;
}
</style>
</head>
<body>
<p>METADATA_IMAGE_logo.1932ddb9.png</p>
<p>METADATA_IMAGE_jpegtest.jpg</p>
<h2><span>Verantos Virtual Appliance EHR Data Input Specification</span>
</h2>
<p><span></span></p>
<p><span>The Verantos virtual appliance accepts electronic health record data using the format, vocabulary, and data dictionary defined in this document.</span>
</p>
<p><span></span></p>
<h3  class="header-h3"><span>Format</span></h3>
<ol start="1">
    <li><span>One tab delimited file can be provided for each table specified in the data dictionary.</span>
    </li>
    <li><span>The patient table is mandatory. Other tables are optional.</span></li>
    <li><span>The first row of each file must provide the names of the fields. All fields specified for a table in the data dictionary must be included in the header.</span>
    </li>
    <li><span>The fields must occur in the order specified in the data dictionary.</span>
    </li>
    <li><span
        >The data in a field must be of the data type specified in the data dictionary.</span></li>
    <li><span>Fields marked as mandatory must have a value that is not blank.</span></li>
    <li><span>Optional fields may have an empty string that indicates missing data and is equivalent to a null in a database field.</span>
    </li>
    <li><span>Integrity of foreign keys must be maintained so that any references to patient_id, encounter_id, provider_id, procedure_id, and care_site_id in a table will be satisfied by a unique row in the Patient, Encounter, Provider, Procedure, and Care_site tables respectively.</span>
    </li>
</ol>
<h3  class="header-h3"><span>Vocabulary</span></h3>
<ol start="1">
    <li><span>The values for the following fields must be drawn from the vocabulary specified in the Code Lists section. Data destined for these fields must be translated to these codes before being populated in the fields.</span>
    </li>
</ol>
<p><span></span></p><a></a><a></a>
<p>METADATA_TABLE_data-model</p>
<p><span></span></p>
<ol start="2">
    <li><span>For the following fields, codes must be mapped to standard vocabularies (such as SNOMED, ICD10CM, RxNorm, LOINC) where possible. If custom/proprietary code systems are used the codes and their descriptions from the proprietary code systems should be supplied in separate files.</span>
    </li>
</ol>
<p><span></span></p><a></a><a></a>
<p><span></span></p>
<h3  class="header-h3"><span>Data dictionary</span></h3><a
></a><a></a>
<p>METADATA_TABLE_structured-data-transform</p>
<p><span></span></p>
<h3  class="header-h3"><span>Code lists</span></h3><h4  class="header-h4"><span
    >Gender</span></h4><a></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>F</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Female</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>M</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Male</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>U</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Unknown</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span>Race</span></h4><a
></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1</span></p></td>
        <td colspan="1" rowspan="1"><p><span>American Indian or Alaska Native</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Asian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Black or African American</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Native Hawaiian or Other Pacific Islander</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5</span></p></td>
        <td colspan="1" rowspan="1"><p><span>White</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1.01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>American Indian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1.02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Alaska Native</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Asian Indian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Bangladeshi</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.03</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Bhutanese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.04</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Burmese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.05</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Cambodian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.06</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Chinese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.07</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Taiwanese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.08</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Filipino</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.09</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Hmong</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.10</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indonesian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.11</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Japanese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.12</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Korean</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.13</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Laotian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.14</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Malaysian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.15</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Okinawan</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.16</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Pakistani</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.17</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Sri Lankan</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.18</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Thai</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.19</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Vietnamese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.20</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Iwo Jiman</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.21</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Maldivian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.22</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Nepalese</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.23</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Singaporean</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2.24</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Madagascar</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Black</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>African American</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.03</span></p></td>
        <td colspan="1" rowspan="1"><p><span>African</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.04</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Bahamian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.05</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Barbadian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.06</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Dominican</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.07</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Dominica Islander</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.08</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Haitian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.09</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Jamaican</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.10</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Tobagoan</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.11</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Trinidadian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3.12</span></p></td>
        <td colspan="1" rowspan="1"><p><span>West Indian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4.01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Polynesian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4.02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Micronesian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4.03</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Melanesian</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4.04</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Pacific Islander</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5.01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>European</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5.02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Middle Eastern or North African</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5.03</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Arab</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span>Ethnicity</span></h4><a
></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>Code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>Hispanic</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Hispanic or Latino</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>Not Hispanic</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Not Hispanic or Latino</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span>Beneficiary relationship</span>
</h4><a></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>Code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Self</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Spouse</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Child</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Common Law Spouse</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span>Smoking status</span>
</h4><a></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1</span></p></td>
        <td colspan="1" rowspan="1"><p><span>current</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2</span></p></td>
        <td colspan="1" rowspan="1"><p><span>former</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3</span></p></td>
        <td colspan="1" rowspan="1"><p><span>never</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4</span></p></td>
        <td colspan="1" rowspan="1"><p><span>unknown</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span
    >CMS place of service</span></h4><a></a><a
></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>01</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Pharmacy</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>02</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Telehealth</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>03</span></p></td>
        <td colspan="1" rowspan="1"><p><span>School</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>04</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Homeless Shelter</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>05</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indian Health Service Free-standing Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>06</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indian Health Service Provider-based Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>07</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Tribal 638 Free-standing Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>08</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Tribal 638 Provider-based Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>09</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Prison/Correctional Facility</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>11</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Office</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>12</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Home</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>13</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Assisted Living Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>14</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Group Home</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>15</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Mobile Unit</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>16</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Temporary Lodging</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>17</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Walk-in Retail Health Clinic</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>18</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Place of Employment-Worksite</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>19</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Off Campus-Outpatient Hospital</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>20</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Urgent Care Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>21</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Inpatient Hospital</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>22</span></p></td>
        <td colspan="1" rowspan="1"><p><span>On Campus-Outpatient Hospital</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>23</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Emergency Room &ndash; Hospital</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>24</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Ambulatory Surgical Center</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>25</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Birthing Center</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>26</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Military Treatment Facility</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>31</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Skilled Nursing Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Nursing Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>33</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Custodial Care Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>34</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Hospice</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>41</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Ambulance - Land</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>42</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Ambulance &ndash; Air or Water</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>49</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Independent Clinic</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>50</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federally Qualified Health Center</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>51</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Inpatient Psychiatric Facility</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>52</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Psychiatric Facility-Partial Hospitalization</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>53</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Community Mental Health Center</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>54</span></p></td>
        <td colspan="1" rowspan="1"><p>
                <span>Intermediate Care Facility/ Individuals with Intellectual Disabilities</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>55</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Residential Substance Abuse Treatment Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>56</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Psychiatric Residential Treatment Center</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>57</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Non-residential Substance Abuse Treatment Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>58</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Non-residential Opioid Treatment Facility</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>60</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Mass Immunization Center</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>61</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Comprehensive Inpatient Rehabilitation Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>62</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Comprehensive Outpatient Rehabilitation Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>65</span></p></td>
        <td colspan="1" rowspan="1"><p><span>End-Stage Renal Disease Treatment Facility</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>71</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Public Health Clinic</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>72</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Rural Health Clinic</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>81</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Independent Laboratory</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>99</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Place of Service</span></p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p><h4  class="header-h4"><span>PHDSC</span></h4><a
></a><a></a>
<table>
    <tbody>
    <tr>
        <td colspan="1" rowspan="1"><p><span>code</span></p></td>
        <td colspan="1" rowspan="1"><p><span>name</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>1</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>2</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3</span></p></td>
        <td colspan="1" rowspan="1"><p>
                <span>Other Government Federal/State/Local, excluding Department of Corrections</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>4</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Department of Corrections</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>5</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Private Health Insurance</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>6</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross/Blue Shield</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>7</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care, Unspecified</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>8</span></p></td>
        <td colspan="1" rowspan="1"><p><span>No Payment from an Organization/Agency/Program/Private Payer Listed</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>9</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Miscellaneous Program</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>11</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>111</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Health Maintenance Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>112</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>113</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Point of Service (POS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>119</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Managed Care Other</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>12</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Non-managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>121</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Fee for Service (FFS)</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>122</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Drug Benefit</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>123</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Medicare Medical Savings Account (MSA)</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>129</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Non-managed Care - Other</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>13</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Hospice</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>14</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Dual Eligibility Medicare/Medicaid</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>19</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Other</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>191</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare Pharmacy Benefit Manager</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>21</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>211</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>212</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>213</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Primary Care Case Management (PCCM)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>219</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Managed Care - Other</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>22</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Non-managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>23</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid/State Children&#39;s Health Insurance Program (SCHIP)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>24</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Applicant</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>25</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Out of State</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>26</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Long Term Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>29</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid - Other</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>291</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Pharmacy Benefit Manager</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>299</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicaid Dental</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>31</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Department of Defense</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>311</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE, Civilian Health and Medical Program of the Uniformed Services (CHAMPUS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3111</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE Prime - Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3112</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE Extra - Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3113</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE Standard - Fee For Service (FFS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3114</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE For Life - Medicare Supplement</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3115</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD TRICARE Reserve Select</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3116</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD Uniformed Services Family Health Plan (USFHP) - Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3119</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD other</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>312</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Military Treatment Facility</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3121</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Military Treatment Facility - Enrolled Prime - HMO</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3122</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Military Treatment Facility - Non-enrolled Space Available</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3123</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Military Treatment Facility - TRICARE For Life (TFL)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>313</span></p></td>
        <td colspan="1" rowspan="1"><p><span>DoD Dental - Stand Alone</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Department of Veterans Affairs</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>321</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Veteran Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3211</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Direct Care - Care provided in VA facilities</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3212</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Indirect Care - Care provided outside VA facilities</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32121</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >VA Outside VA facilities - Fee Basis</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32122</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - Foreign Fee/Foreign Medical Program (FMP)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32123</span></p></td>
        <td colspan="1" rowspan="1"><p>
                <span>VA Outside VA facilities - Contract Nursing Home/Community Nursing Home</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32124</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - State Veterans Home</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32125</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - Sharing Agreements</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32126</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - Other Federal Agency</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32127</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - Dental</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>32128</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Outside VA facilities - Vision</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>322</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Non-Veteran Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3221</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Civilian Health and Medical Program for the VA (CHAMPVA)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3222</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >VA Spina Bifida Health Care Program (SB)</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3223</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Children of Women Vietnam Veterans (CWVV)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3229</span></p></td>
        <td colspan="1" rowspan="1"><p><span>VA Other Non-Veteran Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>33</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Indian Health Service (IHS) Program</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>331</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indian Health Service - Regular</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>332</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indian Health Service - Contract</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>333</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Indian Health Service - Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>334</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indian Tribe - Sponsored Coverage</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>34</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Health Resources and Services Administration (HRSA) Program</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>341</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Title V Maternal and Child Health (MSH) Services Block Grant Program</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>342</span></p></td>
        <td colspan="1" rowspan="1"><p><span>HRSA Migrant Health Program</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>343</span></p></td>
        <td colspan="1" rowspan="1"><p><span>HRSA Ryan White HIV/AIDS Program</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>349</span></p></td>
        <td colspan="1" rowspan="1"><p><span>HSRA - Other</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>35</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Division of Coal Mine Workers&#39; Compensation (DCMWC) - Black Lung</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>36</span></p></td>
        <td colspan="1" rowspan="1"><p><span>State Government Program</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>361</span></p></td>
        <td colspan="1" rowspan="1"><p><span>State Children&#39;s Health Insurance Program (SCHIP) - individual state code</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>362</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Specific state program (list/local code)</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>369</span></p></td>
        <td colspan="1" rowspan="1"><p><span>State program - not otherwise specified (other state)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>37</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government Program</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>371</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government Managed Care</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3711</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3712</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3713</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Local Government Point of Service (POS)</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>372</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government Fee for Service (FFS) Indemnity</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>379</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Local Government FFS/Indemnity not otherwise specified (other local, county)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>38</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Government (Federal, State, Local not specified)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>381</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal, State, Local not specified Managed Care</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3811</span></p></td>
        <td colspan="1" rowspan="1"><p>
                <span>Federal, State, Local not specified - Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3812</span></p></td>
        <td colspan="1" rowspan="1"><p>
                <span>Federal, State, Local not specified - Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3813</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal, State, Local not specified - Point of Service (POS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>3819</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal, State, Local not specified - not specified managed care</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>382</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal, State, Local not specified - Fee for Service (FFS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>389</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal, State, Local not specified - Other</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>39</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Federal Program</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>391</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Federal Employee Health Plan</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>41</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Corrections Federal</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>42</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Corrections State</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>43</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Corrections Local</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>44</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Corrections Unknown Level</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>51</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Private Health Insurance - Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>511</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Managed Care - Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>512</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Managed Care - Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>513</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Managed Care - Point of Service (POS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>514</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Exclusive Provider Organization</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>515</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Gatekeeper Preferred Provider Organization (GPPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>516</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Managed Care - Pharmacy Benefit Manager</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>517</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Managed Care - Dental</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>519</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care, Other (non HMO)</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>52</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Private Health Insurance - Indemnity</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>521</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Commercial Indemnity</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>522</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Self-insured (ERISA) Administrative Services Only (ASO) plan</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>523</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Medicare supplemental policy (as second payer)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>524</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Indemnity Insurance - Dental</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>529</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Private health insurance - other commercial Indemnity</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>53</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care (private) or private health insurance (indemnity), not otherwise specified</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>54</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Organized Delivery System</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>55</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Small Employer Purchasing Group</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>56</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Specialized Stand Alone Plan</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>561</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Dental</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>562</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Vision Other Private Insurance</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>61</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>611</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care - Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>612</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care - Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>613</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care - Point of Service (POS)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>614</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care - Dental</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>619</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Managed Care - Other</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>62</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Insurance Indemnity</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>621</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Indemnity</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>622</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Self-insured (ERISA) - Administrative Services Only (ASO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>623</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Blue Cross Medicare Supplemental Plan</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>629</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Blue Cross Indemnity - Dental</span></p>
        </td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>71</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>72</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care Preferred Provider Organization (PPO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>73</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Managed Care POS</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>79</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>81</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Self-pay</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>82</span></p></td>
        <td colspan="1" rowspan="1"><p><span>No Charge</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>821</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Charity</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>822</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Professional Courtesy</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>823</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Research/Clinical Trial</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>83</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Refusal to Pay/Bad Debt</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>84</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Hill-Burton Free and Reduced-Cost Health Care</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>85</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Research/Donor</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>89</span></p></td>
        <td colspan="1" rowspan="1"><p><span>No Payment - Other</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>91</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Foreign National</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>92</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other Non-government</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>93</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Disability Insurance</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>94</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Long-term Care Insurance</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>95</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Worker&#39;s Compensation</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>951</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Worker&#39;s Compensation Health Management Organization (HMO)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>953</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Worker&#39;s Comp Fee-for-Service (FFS)</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>954</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Worker&#39;s Comp Other Managed Care</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>959</span></p></td>
        <td colspan="1" rowspan="1"><p><span
                >Worker&#39;s Comp, Other unspecified</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>96</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Auto Insurance</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>97</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Liability Insurance</span></p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>98</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Other specified but not otherwise classifiable (includes Hospice - Unspecified plan)</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>99</span></p></td>
        <td colspan="1" rowspan="1"><p><span>No Typology Code available for payment source</span>
            </p></td>
    </tr>
    <tr>
        <td colspan="1" rowspan="1"><p><span>9999</span></p></td>
        <td colspan="1" rowspan="1"><p><span>Unavailable / Unknown/ No Payer Specified / Blank</span>
            </p></td>
    </tr>
    </tbody>
</table>
<p><span></span></p></body>
</html>`
};

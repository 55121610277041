import React, { useState } from 'react';
import ModalComponent from './../modal/ModalComponent';
import './studymodal.scss';
import StaticLabels from '../../utils/messages';
import ButtonNormal from '../buttonnormal/ButtonNormal';
import CustomSelect from '../customselect/CustomSelect';
import PropTypes from 'prop-types';

const StudyModal = (props) => {
	// const studyId = props.studyId;
	const [ studyPlaceholder, setStudyPlaceholder ] = useState(StaticLabels.MP_Study_Placeholder);

	const closeModal = () => {
		props.closeStudyModal();
	};

	return (
		<div>
			<ModalComponent
				open={true}
				className="study-modal"
				handleClose={closeModal}
				header={<p className="study-title">{StaticLabels.MP_Study_Title}</p>}
				closeButton={true}
				content={
					<React.Fragment>
						<p className="study-message">{StaticLabels.MP_Study_Message}</p>
						<CustomSelect
							className="study-select"
							placeholder={studyPlaceholder}
							name="active-study"
							options={props.activeStudy}
							onFocus={() => setStudyPlaceholder('')}
							onBlur={() => setStudyPlaceholder(StaticLabels.MP_Study_Placeholder)}
							// value={studyId || props.activeStudy.filter(({ value }) => value === studyId)}
							onChange={props.onStudySelect}
						/>
						<textarea placeholder="Add description" />
						{props.error && (
							<div className="error">
								<span>{props.error}</span>
							</div>
						)}
					</React.Fragment>
				}
				footer={
					<React.Fragment>
						<ButtonNormal
							buttonType="2"
							className="buttons"
							onClick={props.studyNextClick}
							text={StaticLabels.MP_Study_Btn_Next}
						/>
						<span className="cancel" onClick={closeModal}>
							{StaticLabels.MP_Study_Btn_Cancel}
						</span>
					</React.Fragment>
				}
			/>
		</div>
	);
};

export default StudyModal;

StudyModal.propTypes = {
	studyId: PropTypes.number.isRequired,
	closeStudyModal: PropTypes.func.isRequired,
	studyNextClick: PropTypes.func,
	error: PropTypes.string,
	onStudySelect: PropTypes.func,
	activeStudy: PropTypes.array
};

// import store from '../../store';
// import actionType from '../../utils/actionTypes';
import { apiPost } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const checkUserEnrolled = (username, password) => {
	return new Promise((resolve, reject) => {
		apiPost(apiUrl.IsUserEnrolled, { username, password })
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const triggerEnrolleUser = (mfaToken, otp, oobCode) => {
	return new Promise((resolve, reject) => {
		apiPost(apiUrl.EnrolleUser, { mfaToken, otp, oobCode })
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

import React, { useState, useEffect, Suspense, useRef, lazy } from 'react';
import { getNotificationList } from './notificationHelper';
import StaticLabels from '../../utils/messages';
import Header from '../../component/header/Header';
import './notificationcontainer.scss';
import { Link } from 'react-router-dom';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format, isToday, isYesterday } from 'date-fns';

const InfiniteScroll = lazy(() => import('../../component/infiniteScroll/InfiniteScroll'));

const NotificationContainer = (props) => {
	const [ notificationlist, setNotificationList ] = useState([]);
	const [ haveMoreUsers, setHaveMoreUsers ] = useState(false);
	const [ loading, setloading ] = useState(false);
	const [ pageNo, setPageNo ] = useState(1);
	// const [ recordsPerPage, setRecordsPerPage ] = useState(10);
	const recordsPerPage = 10;
	const [ newNotifFlag, setNewNotifFlag ] = useState(false);
	const isInitialMount = useRef(true);
	const { listenChange } = props;

	const setNextPage = () => {
		setPageNo((prevPageNo) => prevPageNo + 1);
	};

	const getNotificationDetailList = () => {
		getNotificationList(pageNo, recordsPerPage)
			.then((resp) => {

				const updatedList = [ ...notificationlist, ...resp.data.payload ];
				// const sortedList = updatedList.sort((a, b) => {
				// 	return a.readByUser < b.readByUser ? -1 : a.readByUser > b.readByUser ? 1 : 0;
				// });
				setNotificationList(updatedList);
				setHaveMoreUsers(resp.data.payload.length > 0);
				// setHaveMoreUsers(resp.data.totalEntries > recordsPerPage * pageNo);
				setloading(false);
				showLoader(false);
			})
			.catch((err) => {
				showLoader(false);
				console.log(err);
			});
	};

	useEffect(
		() => {
			if (sessionStorage.getItem('prevChange') && sessionStorage.getItem('prevChange') !== listenChange.toString()) {
				setNotificationList([]);
				setPageNo(1)
				setNewNotifFlag(!newNotifFlag)
			}
		},
		[ listenChange ]
	);

	useEffect(
		() => {
			const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.history.replaceState({}, document.title, url);

			if (isInitialMount.current) {
				showLoader(true);
				getNotificationDetailList();
				isInitialMount.current = false;
			} else {
				setloading(true);
				setTimeout(() => {
					getNotificationDetailList();
				}, 2000);
			}
		},
		[ recordsPerPage, pageNo, newNotifFlag ]
	);

	const getDateFormat = (strDate) =>{
		try {
			if(strDate){
				if(isToday(new Date(strDate))){
					return `Today ${format(new Date(strDate), 'hh:mm aaaa')}`;
				}
				if(isYesterday(new Date(strDate))){
					return `Yesterday ${format(new Date(strDate), 'hh:mm aaaa')}`
				}
				return format(new Date(strDate), 'MM/dd/yy hh:mm aaaa');
			} 
			return '';
		} catch {
			return 'format invalid';
		}
	}

	return (
		<div className="notification-container">
			<Header title={StaticLabels.Notification_Title} showAddButton={false} hideSearch={true} />
			<div className="notifications">
				{notificationlist &&
					notificationlist.map((noti) => {
						return (
							<div
								key={noti.id}
								className={`${!noti.readByUser
									? 'notification-info  notification-info-read'
									: ' notification-info  notification-info-unread'}`}
							>
								<div className="notification-title">
									<span className="notification-for">
										{noti.module}
									</span>
									<span className="notif-datetime">
										{getDateFormat(noti.dateTime)}
									</span>
								</div>
								<div className="notification-msg">
									{(noti.type === 'ERROR' || noti.type ==='ACQUISITION_FAILED' || noti.type ==='INACTIVE' || noti.type ==='REJECTED') ? (
										<i
											className={`fa fa-exclamation toast-icon icon-type-${noti.type.toLowerCase()}`}
											aria-hidden="true"
										/>
									) : (
										<i
											className={`fa fa-check toast-icon icon-type-${noti.type.toLowerCase()}`}
											aria-hidden="true"
										/>
									)}
									<span className="notif-message">{noti.message}</span>
									<Link
										className="notif-link"
										to={`/dataadmin/${noti.researchPartnerId}`}
									>
										{StaticLabels.Notification_Detail_Link}
									</Link>
								</div>
							</div>
						);
					})}
				<Suspense fallback={<div>Loading...</div>}>
					<InfiniteScroll id='dataset-list' loading={loading} haveMoreRecords={haveMoreUsers} setNextPage={setNextPage} />
				</Suspense>
			</div>
			{!loading &&
			!haveMoreUsers &&
			pageNo > 1 && (
				<div id="no-more-records" className="no-records no-more-records">
					No more records.
				</div>
			)}
			{loading && (
				<div className="loading-list">
					<div className="loading">loading</div>
					<div className="dot-pulse" />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		listenChange: state.redCommon.listenChange
	};
};

NotificationContainer.propTypes = {
	listenChange: PropTypes.bool
}
export default connect(mapStateToProps)(NotificationContainer);
import { apiGet, apiPut } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';
import store from '../../store';
import actionType from '../../utils/actionTypes';

export const getDatasetProfile = (datasetId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetDatasetProfile.replace('##DATASETID##', datasetId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getDatasetDetail = (datasetId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.DatasetDetail + datasetId)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const updateDatasetDetail = (id, datasetDescription) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.UpdateDatasetDetail.replace('##ID##', id), datasetDescription, { 'Content-Type': 'text/plain' })
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const deactivateDataset = (datasetId, imageBytes, reason) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.DeactivateDataset + datasetId, {
			action_reason: reason, //eslint-disable-line
			digital_signature: imageBytes //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const sendOTP = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.SendOTP)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const verifyOTP = (code, otp) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.VerifyOTP, {
			authentication_code: code, //eslint-disable-line
			otp_code: otp //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const processDataset = (datasetId, imageBytes, studyId) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.ProcessDataset + datasetId, {
			digital_signature: imageBytes, //eslint-disable-line
			study_id: studyId //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getStudyActiveList = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetStudyActiveList, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getDatasetActivity = (id) => {
	return new Promise((resolve, reject) => {
		apiGet(`${apiUrl.GetDatasetActivity}/${id}/activity`)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getReasonForAction = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.ReasonsForAction, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const updateDatasetList = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.UPDATED_DATASET_LIST,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

import React from 'react';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';
import Input from 'react-phone-number-input/input';

const UserDetail = (props) => {
	/* eslint-disable */
	const { first_name, last_name, email, phone_number } = props.userDetail;
	const errors = props.errors;

	return (
		<form id="userdetail" className="userDetail" onSubmit={props.handleNextClick}>
			<section className="section">
				<div className="labels">
					<label id="fname" className="label-margin">{StaticLabels.MP_User_First_Name}</label>{' '}
					<label id="lname">{StaticLabels.MP_User_Last_Name}</label>{' '}
				</div>{' '}
				<div className="inputfields">
					<input
						type="text"
						id="fname_input"
						name="first_name"
						className="inputs"
						value={first_name}
						onChange={props.onChange}
					/>{' '}
					<input
						type="text"
						id="lname_input"
						name="last_name"
						value={last_name}
						onChange={props.onChange}
					/>{' '}
				</div>{' '}
			</section>
			<section className="section">
				<div className="labels">
					<label id="email" className="label-margin">{StaticLabels.MP_User_Email}</label>{' '}
					<label id="phone">{StaticLabels.MP_User_Phone}</label>{' '}
				</div>{' '}
				<div className="inputfields">
					<input
						type="text"
						name="email"
						id="email_input"
						value={email}
						className="inputs"
						onChange={props.onChange}
						disabled={props.disabled}
					/>{' '}
					<Input
						className='phone-input'
						id="phone_input"
						type='text'
						name="phone_number"
						international
						value={phone_number}
						onChange={props.updatePhoneNumber}
					/>
				</div>{' '}
			</section>
			<div className="error_field">
				{' '}
				{errors ? (
					<span>
						{' '}
						{errors['first_name'] ? (
							errors.first_name
						) : errors['last_name'] ? (
							errors.last_name
						) : errors['email'] ? (
							errors.email
						) : (
							errors.phone_number
						)}{' '}
					</span>
				) : null}{' '}
			</div>{' '}
		</form>
	);
};

export default UserDetail;

UserDetail.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	handleNextClick: PropTypes.func,
	errors: PropTypes.array,
	userDetail: PropTypes.object,
	disabled: PropTypes.bool,
	updatePhoneNumber: PropTypes.func,
};

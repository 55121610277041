import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './auditreport.scss';
import { connect } from 'react-redux';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import Header from '../../component/header/Header';
import StaticLabels from '../../utils/messages';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { generateAuditReport, triggerAuditReport, downloadAuditReport } from './auditReportHelper';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import calendarIcon from '../../images/calendar.svg';
import downloadIcon from '../../images/download_active.svg';
import downloadInactiveIcon from '../../images/download_inactive.svg';

const AuditReportContainer = (props) => {
	// const { loggedInUser } = props;
	// const isRoot =
	// 	loggedInUser.userRoles && loggedInUser.userRoles.length > 0 && loggedInUser.userRoles.indexOf('ROLE_ROOT');
	// const isAdmin =
	// 	loggedInUser.userRoles && loggedInUser.userRoles.length > 0 && loggedInUser.userRoles.indexOf('ROLE_ADMIN');
	const [ startDate, setStartDate ] = useState(new Date());
	const [ endDate, setEndDate ] = useState(new Date());
	const [ showError, setShowError ] = useState(false);
	const [ auditReport, setAuditReport ] = useState([]);
	const [ downloadFile, setDownloadFile ] = useState([]);
	const [ apiError, setApiError ] = useState('');
	const csvLink = useRef();
	const [ fileDownloadable, setFileDownloadable ] = useState(false);
	const [ refresh, setRefresh ] = useState(false);
	const { auditReportList } = props;

	const getAuditReportList = () => {
		setRefresh(true);
		showLoader(true);
		generateAuditReport()
			.then((resp) => {
				showLoader(false);
			})
			.catch((err) => {
				showLoader(false);
				console.log(err);
			});
	};

	useEffect(
		() => {
			getAuditReportList();

			if (csvLink && csvLink.current && fileDownloadable) {
				csvLink.current.link.click();
				setFileDownloadable(false);
			}
		},
		[ fileDownloadable ]
	);

	useEffect(
		() => {
			if (auditReportList) {
				setAuditReport(auditReportList);
				setRefresh(false);
			}
		},
		[ auditReportList ]
	);

	const triggerReport = () => {
		setApiError('');
		if (!startDate || !endDate) {
			setShowError(true);
			return;
		}
		setShowError(false);
		showLoader(true);
		//generateReport(startDate.replace(/\//g, ''), endDate.replace(/\//g, ''))
		//generateReport(startDate.toLocaleDateString(), endDate.toLocaleDateString())
		// generateReport(format(startDate, 'MMddyyyy'), format(endDate, 'MMddyyyy'))
		// 	.finally(() => {
		// 		showLoader(false);
		// 		getAuditReportList();
		// 	})
		// 	.catch((err) => {
		// 		setApiError(err)
		// 		showLoader(false);
		// 	});

		triggerAuditReport(startDate.toLocaleDateString(), endDate.toLocaleDateString())
			.then((resp) => {
				showLoader(false);
				getAuditReportList();
			})
			.catch((err) => {
				setApiError(err.data.payload);
				console.log(err);
				showLoader(false);
			});
	};

	// const getDateString = (string) => {
	// 	let newDate = new Date(string);
	// 	newDate = format(newDate, 'MM/dd/yy');
	// 	return newDate;
	// };

	const splitString = (string) => {
		const newString = string.split('-');
		let newDateString = [];
		newDateString = newString.map((str) => {
			let newDate = new Date(str);
			newDate = format(newDate, 'MM/dd/yy');
			return newDate;
		});
		return newDateString.join(' - ');
	};

	const downloadReport = (reportId) => {
		showLoader(true);
		downloadAuditReport(reportId)
			.then((resp) => {
				setDownloadFile(resp.data);
				showLoader(false);
				// getAuditReportList()
				setFileDownloadable(true);
			})
			.catch((err) => {
				showLoader(false);
				console.log(err);
			});
	};

	const setStartdt = (date) => {
		setStartDate(date);
	};

	const setEnddt = (date) => {
		setEndDate(date);
	};
	return (
		<div id="auditreport-container">
			<Header title={StaticLabels.AR_Title} showAddButton={false} hideSearch={true} />
			<div className="auditreport">
				<div className="generate-report">
					<span className="sub-title">{StaticLabels.AR_Subtitle}</span>
					<div className="calendar-container">
						<div className="start">
							<span className="txt">{StaticLabels.AR_StartDate}</span>
							<span className="icon-calendar">
								{/* <i className="far fa-calendar-alt" /> */}
								<img
										src={calendarIcon}
										className="calendar-icon"
									/>
							</span>
							<DatePicker
								id="startdate"
								dateFormat="MM/dd/yy"
								maxDate={new Date()}
								selected={startDate}
								onChange={setStartdt}
							/>
						</div>
						<div className="end">
							<span className="txt">{StaticLabels.AR_EndDate}</span>
							<span className="icon-calendar">
								{/* <i className="far fa-calendar-alt" /> */}
								<img
										src={calendarIcon}
										className="calendar-icon"
									/>
							</span>
							<DatePicker
								id="enddate"
								dateFormat="MM/dd/yy"
								maxDate={new Date()}
								selected={endDate}
								onChange={setEnddt}
							/>
						</div>
						<ButtonNormal className="report_btn" text={StaticLabels.AR_Button} onClick={triggerReport} />
					</div>
				</div>
				<div className="error-container">
					{showError ? (
						<p className="error">{StaticLabels.AR_Date_Mandatory}</p>
					) : apiError ? (
						<p className="error">{apiError}</p>
					) : (
						''
					)}
				</div>
				<div className="list-report">
					<div className="list-header">
						<span className="list-title">{StaticLabels.AR_Table_Title}</span>
						<span className="refresh-list" onClick={getAuditReportList}>
							<i className={`fas fa-redo-alt refresh-logo ${refresh ? 'fa-spin' : ''}`} />
							{/* {StaticLabels.AR_Refresh_List} */}
						</span>
					</div>
					<table className="tbl-list">
						<thead className="tbl-header">
							<tr>
								<td id="requesteddate">
									<div>{StaticLabels.AR_Date_Requested}
									</div>
									</td>
								<td id="status">
									<div>{StaticLabels.AR_Status}</div></td>
								<td id="daterange"><div>{StaticLabels.AR_Date_Range}</div></td>
								<td id="lastdownload"><div>{StaticLabels.AR_Last_Download}</div></td>
								<td id='download-link'/>
							</tr>
						</thead>
						<tbody className="tbl-body">
							{auditReport &&
								auditReport.map((report, index) => {
									const status = report.status.toLowerCase();

									return (
										//eslint-disable-next-line
										<tr key={report.report_id} className="tbl-row">
											<td>
												<div>{format(new Date(report.trigger_datetime), 'MM/dd/yy')}</div>
											</td>
											<td>
												<div
													className={`status  ${status === 'running' || status === 'queued'
														? 'processing'
														: status === 'succeeded' ? 'processed' : 'failed'}`}
												>
													{status === 'running' || status === 'queued' ? (
														'Processing'
													) : status === 'succeeded' ? (
														'Ready'
													) : (
														'Failed'
													)}
												</div>
											</td>
											<td>
												<div>{splitString(report.query_description)}</div>
											</td>
											<td>
												<div>
													{report.last_downloaded &&
														format(new Date(report.last_downloaded), 'MM/dd/yy')}
												</div>
											</td>
											<td>
												<div
													className={`download 
													${status !== 'succeeded' ? 'disabled' : ''}`}
												>
													<span
														id={`download${index}`}
														onClick={(e) =>
															status === 'running' || status === 'failed'
																? e.preventDefault()
																: downloadReport(report.report_id)}
													>
														{/* {StaticLabels.AR_Download_Report} */}
														<img src={`${status !== 'succeeded' ? downloadInactiveIcon : downloadIcon}`} />
													</span>

													<CSVLink
														data={downloadFile}
														filename="data.csv"
														className="hidden"
														ref={csvLink}
														target="_blank"
													/>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		auditReportList: state.redCommon.auditReportList
	};
};

export default connect(mapStateToProps)(AuditReportContainer);

AuditReportContainer.propTypes = {
	loggedInUser: PropTypes.object,
	auditReportList: PropTypes.array
};

import React from 'react';
import { connect } from 'react-redux';
import './App.scss';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

const App = (props) => {
	return (
		<React.Fragment>
			{props.loader && (
				<div className="overlay">
					<i className="fas fa-spinner spinner" />
				</div>
			)}
			{props.children}
			<ToastContainer className='toast-container' limit={5} />
		</React.Fragment>
	);
};
const mapStateToProps = (state) => {
	return { loader : state.redCommon.loader };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
	children: PropTypes.element,
	loader: PropTypes.bool
}
import React, { useEffect } from 'react';
import queryString from 'querystring';
import './authenticatecontainer.scss';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getUserInfo,
	getActiveDataset,
	getStudyDocumentList,
	setStudyDocumentList
} from './authHelper';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../../constants';
import { getRoleKeys } from '../../utils/commonUtils';
import PropTypes from 'prop-types';

const webAuth = new auth0.WebAuth({
	domain: AUTH_CONFIG.domainName,
	clientID: AUTH_CONFIG.clientID
});

function AuthenticateContainer(props) {
	const history = useHistory();
	const { userInfo } = props;
	const authorise = () => {
		if (sessionStorage.getItem('accessToken')) {
			history.push('/sysadmin/userlist');
		} else {
			history.push('/');
		}
	};

	useEffect(() => {
		let hash = window.location.href;
		hash = hash.split('#').pop();
		hash = queryString.parse(hash);
		sessionStorage.setItem('accessToken', hash.access_token);
		getUserInfo();
	}, []);

	useEffect(
		() => {
			// getUserInfo().then((userInfo) => {
			if (userInfo) {
				sessionStorage.setItem('dbverified', 'true');
				const userRoles = getRoleKeys(userInfo.user_roles);
				const isDAAdmin = userRoles.indexOf('ROLE_DADMIN') !== -1 ? true : false;
				const isAdmin = userRoles.indexOf('ROLE_ADMIN') !== -1 ? true : false;
				const isRoot = userRoles.indexOf('ROLE_ROOT') !== -1 ? true : false;
				const isResearchPartnerUser = userRoles.indexOf('ROLE_RESEARCH_PARTNER_USER') !== -1 ? true : false;
				const isCustomerUser = userRoles.indexOf('ROLE_RESEARCH') !== -1 ? true : false
				if (isRoot || isAdmin) {
					// sessionStorage.setItem('SysAdmin', true);
					sessionStorage.setItem('Role', 'SysAdmin');
					authorise(userInfo);
				} else if (isDAAdmin) {
					sessionStorage.setItem('Role', 'DataAdmin');
					getActiveDataset()
						.then((resp) => {
							if (resp.data.payload.length > 0) {
								history.push(`/dataadmin/${resp.data.payload[0].id}`);
							}
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (isResearchPartnerUser || isCustomerUser) {
					sessionStorage.setItem('Role', 'KnowledgeBase');
					getStudyDocumentList()
						.then((resp) => {
							const updatedresp = resp.data.payload.map((res) => {
								return {
									studyName: res.studyName,
									studyId: res.studyId,
									documentList: [
										{
											versionId: res.versionId,
											name: res.name,
											templateId: res.templateId
										}
									]
								};
							});
							const mergedResponse = updatedresp.reduce((obj, item) => {
								obj[item.studyId]
									? obj[item.studyId].documentList.push(...item.documentList)
									: (obj[item.studyId] = { ...item });
								return obj;
							}, {});
							const mergedResponseArray = Object.values(mergedResponse);
							setStudyDocumentList(mergedResponseArray);
							
							sessionStorage.setItem('toggleStudyDocId', mergedResponseArray[0].studyId)

							if (resp.data.payload.length > 0) {
								history.push(`/documentation/template/${mergedResponseArray[0].documentList[0].templateId}`);
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}
			// });
		},
		[ props.userInfo ]
	);

	// const enroll = (transaction, method) => {
	// 	if (transaction.isEnrolled()) {
	// 		return;
	// 	}

	// 	var enrollData = {};

	// 	if (method === 'sms') {
	// 		enrollData.phoneNumber = prompt('Phone number'); // Collect phone number
	// 	}

	// 	return transaction.enroll(method, enrollData, function(err, otpEnrollment) {
	// 		if (err) {
	// 			console.error(err);
	// 			return;
	// 		}

	// 		var uri = otpEnrollment.getUri();
	// 		if (uri) {
	// 			console.log(uri);
	// 			// showQR(uri);
	// 		}

	// 		var confirmData = {};
	// 		if (method === 'otp' || method === 'sms') {
	// 			confirmData.otpCode = prompt('Otp code'); // Collect verification otp
	// 		}

	// 		otpEnrollment.confirm(confirmData);
	// 	});
	// };

	return <div />;
}

const mapStateToProps = (state) => {
	return {
		userInfo: state.redUserInfo.userInfo
	};
};

export default connect(mapStateToProps)(AuthenticateContainer);

AuthenticateContainer.propTypes = {
	userInfo: PropTypes.object
};

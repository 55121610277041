import store from '../../store';
import actionTypes from '../../utils/actionTypes';
import { apiGet, apiPost } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const generateReport = (startDate, endDate) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GenerateReport, {
			startDate,
			endDate
		})
        .then((resp) => {
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        });
	});
};

export const generateAuditReport = (from_Date, to_Date) => {       //eslint-disable-line
	return new Promise((resolve, reject) => {
		apiPost(apiUrl.GetAuditReport, {
			from_Date,  //eslint-disable-line
			to_Date     //eslint-disable-line
		})
        .then((resp) => {
            const options = {
                type: actionTypes.AUDIT_REPORT_LIST,
                payload: resp.data.payload || ''
            }
            store.dispatch(options)
            resolve(resp);
        })
        .catch((err) => {
            reject(err);
        });
	});
};

export const triggerAuditReport = (from_Date, to_Date) => {  //eslint-disable-line
    return new Promise((resolve, reject) => {
        apiPost(apiUrl.TriggerAuditReport, {
            from_Date,  //eslint-disable-line
            to_Date     //eslint-disable-line
        }).then((resp) => {
            resolve(resp);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const downloadAuditReport = (reportId) => {
    return new Promise((resolve, reject) => {
        apiGet(apiUrl.DownloadAuditReport, {
            report_id: reportId     //eslint-disable-line
        }).then((resp) => {
            resolve(resp);
        }).catch((err) => {
            reject(err);
        })
    })
}
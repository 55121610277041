import store from '../../store';
import actionType from '../../utils/actionTypes';
import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl'

export const getCustomerlist = (pageNo, recordsPerPage, searchText, sortedColumn, isDescending) => {
	return new Promise((resolve, reject) => {
		const option = {
            pageNumber:pageNo,
            pageSize:recordsPerPage,
			searchQuery: searchText,
			sort_by : sortedColumn,	// eslint-disable-line
			descending: isDescending
        }
		apiGet(apiUrl.GetCustomerList, option)
			.then((resp) => {
				const options = {
					type: actionType.CUSTOMER_LIST,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getCustomerDetails = (customerId) =>{
    return new Promise((resolve, reject) => {
        apiGet(`${ apiUrl.GetCustomerInfo }/${ customerId }`).then(resp => {
            resolve(resp);
        }).catch((err) => {
            console.error(err);
            reject(err);
        })
    })
};
import React from 'react';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';

const UserRole = (props) => {
	const roleList = props.roleList;
	const errors = props.errors;

	return (
		<div className="roles">
			<div className="labels">
				<label className="rolelabel" id="selectRole">
					{StaticLabels.MP_User_Role}
				</label>{' '}
				<div className="rightrolebox">
					{' '}
					{roleList &&
						roleList.map((list, index) => {
							return (
								<span key={list.id} id={`role${index}`}>
									<input
										className="checkbox"
										key={`${list.id}_checkbox`}
										id={list.id}
										onClick={props.handleCheck}
										onChange={props.handleCheck}
										type="checkbox"
										checked={list.isChecked}
										value={list.value}
									/>{' '}
									<label key={`${list.id}_label`} htmlFor={list.id} className="check-label">
										{' '}
										{list.value}{' '}
									</label>{' '}
								</span>
							);
						})}{' '}
				</div>{' '}
			</div>{' '}
			{
				// <div className="labels">
				// 	<label className="rolelabel" id="eSignature">
				// 		{StaticLabels.MP_User_E_Signature}
				// 	</label>{' '}
				// 	<div className="rightrolebox">
				// 		<textarea />
				// 	</div>{' '}
				// </div>{' '}
			}
			<div className="error_field error_role">{errors ? <span>{errors.roles}</span> : null}</div>
		</div>
	);
};

export default UserRole;

UserRole.propTypes = {
	roleList: PropTypes.arrayOf(PropTypes.object),
	handleCheck: PropTypes.func,
	errors: PropTypes.array
};

import React from 'react';
import StaticLabels from '../../utils/messages';
import './custommodalfooter.scss';
import ButtonNormal from '../buttonnormal/ButtonNormal';
import PropTypes from 'prop-types';

const CustomModalFooter = (props) => {
	return (
		<div className="footer">
			<ButtonNormal
				buttonType="1"
				className="buttons cancelbtn"
				onClick={props.handleClose}
				text={StaticLabels.MP_User_Cancel_Btn}
			/>
			{props.tabKey ? (
				<ButtonNormal
					buttonType="2"
					className="buttons"
					onClick={props.handleNextClick}
					text={StaticLabels.MP_User_Save_Btn}
				/>
			) : props.tabKey && props.Info ? (
				<ButtonNormal
					buttonType="2"
					className="buttons"
					onClick={props.handleNextClick}
					text={StaticLabels.MP_User_Save_Btn}
				/>
			) : (
				<ButtonNormal
					buttonType="2"
					className="buttons"
					id="next-button"
					onClick={props.handleNextClick}
					text={StaticLabels.MP_User_Next_Btn}
				/>
			)}
		</div>
	);
};
export default CustomModalFooter;

CustomModalFooter.propTypes = {
	tabKey: PropTypes.bool,
	handleClose: PropTypes.func,
	handleNextClick: PropTypes.func,
	Info: PropTypes.object,
	buttonType: PropTypes.string
};
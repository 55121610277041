import actionTypes from './actionTypes';
import store from '../store';
export const sortArrayString = (list, columnName, sortOrder) => {
	let sortedArray = [];
	if (sortOrder === 'asc') {
		try {
			sortedArray = list.sort(function(a, b) {
				if (a[columnName].toString().toLowerCase() < b[columnName].toString().toLowerCase()) {
					return -1;
				}
				if (a[columnName].toString().toLowerCase() > b[columnName].toString().toLowerCase()) {
					return 1;
				}
				return 0;
			});
		} catch (err) {
			console.log(err);
		}
	} else {
		try {
			sortedArray = list.sort(function(a, b) {
				if (a[columnName].toString().toLowerCase() < b[columnName].toString().toLowerCase()) {
					return 1;
				}
				if (a[columnName].toString().toLowerCase() > b[columnName].toString().toLowerCase()) {
					return -1;
				}
				return 0;
			});
		} catch (err) {
			console.log(err);
		}
	}
	return sortedArray;
};

// export const sortArrayList = (list, columnName, sortOrder) => {
	
// 	let sortedArray = [];
// 	if (sortOrder === 'asc') {
// 		try {
// 			sortedArray = list.sort(function(a, b) {
// 				if (a[columnName].join(',').toString() < b[columnName].join(',').toString()) {
// 					return -1;
// 				}
// 				if (a[columnName].join(',').toString() > b[columnName].join(',').toString()) {
// 					return 1;
// 				}
// 				return 0;
// 			});
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	} else {
// 		try {
// 			sortedArray = list.sort(function(a, b) {
// 				if (a[columnName].join(',').toString() < b[columnName].join(',').toString()) {
// 					return 1;
// 				}
// 				if (a[columnName].join(',').toString() > b[columnName].join(',').toString()) {
// 					return -1;
// 				}
// 				return 0;
// 			});
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	}
// 	return sortedArray;
// };

export const showLoader = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionTypes.SHOW_LOADING,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const getRoleKeys = (roles) =>{
	
	const userRoles = [];
	if(roles){
		roles.map(items=>{
			userRoles.push(items.user_role)
		});
	}
	return userRoles;
}

export const setToastId = (id) => {
	return new Promise((resolve, reject) => {
		const options ={
			type: actionTypes.TOAST_ID,
			payload: id
		}	
		store.dispatch(options);
		resolve();
	})
}

export const getNotificationListForRP = (message) => {
	return new Promise((resolve, reject) => {
		const options ={
			type: actionTypes.NOTIFICATION_LIST,
			payload: message
		}	
		store.dispatch(options);
		resolve();
	})
}
import React from 'react';
import StaticLabels from '../../utils/messages';
import CustomSelect from '../../component/customselect/CustomSelect';
import PropTypes from 'prop-types';

const ResearchPartnerDetail = (props) => {
	const { name, type, address } = props.researchPatnerDetail;
	const { senderId, sqsQueueUrl } = props.attributes;
	const errors = props.errors;

	return (
		<form id="researchPartnerDetail" className="researchPartnerDetail" onSubmit={props.handleNextClick}>
			<section className="section">
				<div className="labels">
					<label id="resp-name">{StaticLabels.MP_RP_Name}</label>
					<label id="resp-type">{StaticLabels.MP_RP_Type}</label>{' '}
				</div>{' '}
				<div className="inputfields">
					<input type="text" id="resp-name-input" name="name" value={name} onChange={props.onChange} />{' '}
					<CustomSelect
						className="custom-search-select"
						placeholder={StaticLabels.MP_RP_Type_Placeholder}
						id="resp-type-input"
						name="selectTypeInput"
						options={props.researchPartnerTypes}
						value={type && props.researchPartnerTypes.filter(({ value }) => value === type)}
						onChange={props.onTypeChange}
					/>
				</div>{' '}
			</section>
			<section className="section">
				<div className="labels">
					<label id="sid">{StaticLabels.MR_RP_Senderid}</label>
					<label id="sqsid">{StaticLabels.MR_RP_SQS}</label>{' '}
				</div>{' '}
				<div className="inputfields">
					<input type="text" id="resp-sid-input" name="senderId" value={senderId} onChange={props.onChange} />{' '}
					<input type="text" className="sqs-input" id="resp-sqsid-input" name="sqsQueueUrl" value={sqsQueueUrl} onChange={props.onChange} />{' '}
				</div>{' '}
			</section>
			<section className="section">
				<div className="labels">
					<label id="address">{StaticLabels.MP_RP_Address}</label>
				</div>{' '}
				<div className="inputfields">
					<textarea name="address" id="address-input" value={address} onChange={props.onChange} />{' '}
				</div>{' '}
			</section>
			<div className="error_field">
				{' '}
				{errors.length > 0 ? (
					<span> {errors[0].name && errors[0].name} </span>
				) : props.apiError ? (
					<span> {props.apiError[0].message ? props.apiError[0].message : props.apiError} </span>
				) : null}{' '}
			</div>{' '}
		</form>
	);
};

export default ResearchPartnerDetail;

ResearchPartnerDetail.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	handleNextClick: PropTypes.func,
	errors: PropTypes.array,
	researchPatnerDetail: PropTypes.object,
	researchPartnerTypes: PropTypes.array,
	onTypeChange: PropTypes.func,
	apiError: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	  ]),
	attributes: PropTypes.object
};
export default {
    AUTHENTICATED: 'AUTHENTICATED',
    VERIFY_AUTH_LOGIN: 'VERIFY_AUTH_LOGIN',
    USER_INFO: 'USER_INFO',
    USER_ORGANISATION: 'USER_ORGANISATION',
    ROLE_LIST: 'ROLE_LIST',
    CUSTOMER_INFO: 'CUSTOMER_INFO',
    SHOW_LOADING: 'SHOW_LOADING',
    AVAILABLE_DATA_SET: 'AVAILABLE_DATA_SET',
    DATA_SET_LIST : 'DATA_SET_LIST',
    RESEARCH_PARTNER_LIST:'RESEARCH_PARTNER_LIST',
    USER_LIST:'USER_LIST',
    CUSTOMER_LIST:'CUSTOMER_LIST',
    CUSTOMER_TYPE_LIST:'CUSTOMER_TYPE_LIST',
    RP_TYPE_LIST:'RP_TYPE_LIST',
    AUDIT_REPORT_LIST:'AUDIT_REPORT_LIST',
    LISTEN_CHANGE:'LISTEN_CHANGE',
    DOCUMENT_LIST: 'DOCUMENT_LIST',
    ACQUISITION_PROGRESS_DATA: 'ACQUISITION_PROGRESS_DATA',
    STUDY_PROGRESS_CHANGE:'STUDY_PROGRESS_CHANGE',
    TOAST_ID: 'TOAST_ID',
    NOTIFICATION_LIST: 'NOTIFICATION_LIST',
    UNREAD_NOTIFICATION_COUNT: 'UNREAD_NOTIFICATION_COUNT',
    ACTIVE_NOTIFICATION: 'ACTIVE_NOTIFICATION',
    UPDATED_DATASET:'UPDATED_DATASET',
    STUDY_DOCUMENT_LIST: 'STUDY_DOCUMENT_LIST',
    UPDATED_DATASET_LIST: 'UPDATED_DATASET_LIST',
    NOTIFI_LIST:'NOTIFI_LIST'
}
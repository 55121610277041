import React, { useState, useRef } from 'react';
import SockJsClient from 'react-stomp';
import {
	listenChange,
	studyProgressChange,
	acquisitionProgressData,
	getUnreadNotification,
	setActiveNotification,
	getUpdatedDataset,
	getNotificationList
} from './container/sidebar/sidebarHelper';
import { withRouter } from 'react-router-dom';
import { getNotificationListForRP } from './utils/commonUtils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { AUTH_CONFIG } from './constants';

const SockJsClientConnection = (props) => {
	const { notificationList } = props;
	const clientRef = useRef(null);
	const [ change, setChange ] = useState(
		sessionStorage.getItem('prevChange') && sessionStorage.getItem('prevChange') === 'true' ? false : true
	);

	const getUnreadNotiCount = () => {
		getUnreadNotification().then((resp) => {
			const response = resp.data.payload;
			const matchpath = matchPath(props.location.pathname, {
				path: [ '/dataadmin/:id', '/researchnetworkdetail/:id/:rp_id' ],
				exact: true,
				strict: false
			});
			const rpId = matchpath ? (matchpath.params.rp_id ? matchpath.params.rp_id : matchpath.params.id) : '';
			if (response.length > 0) {
				if (response.length === 1 && response[0].researchPartnerId.toString() === rpId) {
					setActiveNotification(false);
				} else {
					setActiveNotification(true);
				}
			} else {
				setActiveNotification(false);
			}
		});
	};

	const updateDatasetStatus = (data) => {
		sessionStorage.setItem('prevChange', change);
		setChange(!change);
		listenChange(!change);
		getUpdatedDataset(data);
	};

	const datasetProgressStatus = (data) => {
		acquisitionProgressData(data);
	};

	const updateStudyStatus = (data) => {
		studyProgressChange(data);
	};

	return (
		<SockJsClient
			url={AUTH_CONFIG.webSocketUrl}
			topics={[
				'/topic/dataset',
				'/topic/dataset/processing',
				'/topic/dataset/progress',
				'/topic/study/progress'
			]}
			onMessage={(data, topic) => {
				console.log('Subscription', data, topic);
				let msg = '';
				const matchpath = matchPath(props.location.pathname, {
					path: [ '/dataadmin/:id', '/researchnetworkdetail/:id/:rp_id' ],
					exact: true,
					strict: false
				});
				if (props.location.pathname !== '/') {
					if (topic === '/topic/dataset') {
						const researchPartnerName = props.availableDataset.filter((ds) => {
							if (ds.id === data.researchPartnerId) {
								return ds;
							} else {
								return;
							}
						});

						if(researchPartnerName.length){
							msg = `Dataset ${data.datasetName} is now being ${data.status.toLowerCase()} for research partner ${researchPartnerName[0]
							.name}`;
						}
						const rpId = matchpath
							? matchpath.params.rp_id ? matchpath.params.rp_id : matchpath.params.id
							: '';
						if (rpId === data.researchPartnerId.toString()) {
							const newMsgs = [ { msg: msg, status: data.status.toLowerCase() }, ...notificationList ];
							getNotificationListForRP(newMsgs);
							getNotificationList(data.researchPartnerId, 1, 10);
							updateDatasetStatus(data);
						} else {
							getUnreadNotiCount();
							updateDatasetStatus();
						}
					} else if (topic === '/topic/dataset/processing') {
						msg = `Dataset ${data.datasetName} being processed for study ${data.studyName}`;
						const rpId = matchpath
							? matchpath.params.rp_id ? matchpath.params.rp_id : matchpath.params.id
							: '';
						if (rpId === data.researchPartnerId.toString()) {
							const newMsgs = [
								{ msg: msg, status: data.studyStatus.toLowerCase() },
								...notificationList
							];
							getNotificationListForRP(newMsgs);
							getNotificationList(data.researchPartnerId, 1, 10);
							updateDatasetStatus(data);
						} else {
							getUnreadNotiCount();
							updateDatasetStatus();
						}
					} else if (topic === '/topic/dataset/progress') {
						datasetProgressStatus(data);
					} else if (topic === '/topic/study/progress') {
						updateStudyStatus(data);
					}
				}
			}}
			onConnect={() => {
				console.log('onConnect');
			}}
			onDisconnect={() => {
				console.log('disconnected');
			}}
			ref={clientRef}
		/>
	);
};

SockJsClientConnection.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			rp_id: PropTypes.any, //eslint-disable-line
			docid: PropTypes.any,
			id: PropTypes.any
		})
	}),
	history: PropTypes.object.isRequired,
	notificationList: PropTypes.array,
	updatedDataset: PropTypes.object,
	availableDataset: PropTypes.array,
	location: PropTypes.object
};
const mapStateToProps = (state) => {
	return {
		notificationList: state.redCommon.notificationList,
		updatedDataset: state.redCommon.updatedDataset,
		availableDataset: state.redCommon.availableDataset
	};
};

// export default connect(mapStateToProps)(SockJsClientConnection);

export default withRouter(connect(mapStateToProps)(SockJsClientConnection));

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './pdfviewer.scss';
import PropTypes from 'prop-types';

function PdfViewer(props) {
	const [ numPages, setNumPages ] = useState(null);
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}
	return (
		<div className="pdf-document-container" id="pdf-document">
			<Document
				file={props.fileUrl}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				{Array.from(new Array(numPages), (el, index) => {
					return (
						<div className='document-pages'>
							<Page key={`page_${index + 1}`} pageNumber={index + 1} />
							<div className='page-number'>{`Page ${index + 1} of ${numPages}`}</div>
						</div>
					);
				})}
			</Document>
		</div>
	);
}

PdfViewer.propTypes = {
    fileUrl: PropTypes.string
};

export default PdfViewer;
import React, { useState, useEffect, Suspense, useRef, lazy } from 'react';
import './researchpartner.scss';
import { connect } from 'react-redux';
import { getResearchPartnerList, getResearchPartnerInfo } from './researchPartnerHelper';
import Header from '../../component/header/Header';
import SortColumn from '../../component/sortcolumn/SortColumn';
// import PaginationComponent from '../../component/pagination/PaginationComponent';
import AddResearchPartner from './../addresearchpatner/AddResearchPartner';
import StaticLabels from '../../utils/messages';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import HighlightText from '../../component/highlighttext/HighlightText';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const InfiniteScroll = lazy(() => import('../../component/infiniteScroll/InfiniteScroll'));

const ResearchPartnerContainer = (props) => {
	const researchSortCol = sessionStorage.getItem('researchSortCol') || 'created_date';
	const researchSortOrd = sessionStorage.getItem('researchSortOrd') || 'dsc';
	const sKey = 'researchpRecPerPage';
	const sValue = sessionStorage.getItem(sKey);
	const [ researchPartnerList, setResearchPartnerList ] = useState([]);
	const [ sortOrder, setSortOrder ] = useState(researchSortOrd);
	const [ sortedColumn, setSortedColumn ] = useState(researchSortCol);
	const [ pageNo, setPageNo ] = useState(1);
	const [ recordsPerPage, setRecordsPerPage ] = useState(sValue || 10);
	// const [ totalCustomerCount, setTotalCustomerCount ] = useState(0);
	const [ searchText, setSearchText ] = useState('');
	const [ addResearchPartner, setAddResearchPartner ] = useState(false);
	const [ editResearchPartner, setEditResearchPartner ] = useState(false);
	const [ researchPartnerInfo, setResearchPartnerInfo ] = useState({});
	const [ isDescending, setIsDescending ] = useState(researchSortOrd === 'dsc' ? true : false);
	const [ toggleState, setToggleState ] = useState(false);
	const { loggedInUser } = props;
	const isRoot =
		loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ROOT');
	const isAdmin =
		loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ADMIN');

	const [ haveMoreUsers, setHaveMoreUsers ] = useState(false);
	const [ loading, setloading ] = useState(false);
	const isInitialMount = useRef(true);
	const setNextPage = () => {
		setPageNo((prevPageNo) => prevPageNo + 1);
	};

	const getResearchPartner = () => {
		getResearchPartnerList(pageNo, recordsPerPage, searchText, sortedColumn, isDescending)
			.then((resp) => {
				// setResearchPartnerList(resp.data.payload);
				// setTotalCustomerCount(resp.data.totalEntries);
				// showLoader(false);

				const updatedList = [ ...researchPartnerList, ...resp.data.payload ];
				setResearchPartnerList(updatedList);
				setHaveMoreUsers(resp.data.totalEntries > recordsPerPage * pageNo);
				setloading(false);
				showLoader(false);
			})
			.catch((error) => {
				showLoader(false);
				console.log(error);
			});
	};

	useEffect(
		() => {
			if (isInitialMount.current) {
				showLoader(true);
				getResearchPartner();
				isInitialMount.current = false;
			} else {
				setloading(true);
				setTimeout(() => {
					getResearchPartner();
				}, 2000);
			}
		},
		[ recordsPerPage, pageNo, searchText, isDescending, sortedColumn, toggleState ]
	);

	const sortColumn = (columnName, order) => {
		setSortedColumn(columnName);
		setSortOrder(order);
		sessionStorage.setItem('researchSortCol', columnName);
		sessionStorage.setItem('researchSortOrd', order);
		setPageNo(1);
		setResearchPartnerList([]);
		setIsDescending(order === 'asc' ? false : true);
	};

	// const gotoPage = (pageNum, recordPerPage) => {
	// 	setPageNo(pageNum);
	// 	setRecordsPerPage(recordPerPage);
	// };

	const searchReasearchPartner = (searchTxt) => {
		setResearchPartnerList([]);
		setSearchText(searchTxt);
		setPageNo(1);
	};

	const handleClose = () => {
		setAddResearchPartner(false);
		setEditResearchPartner(false);
	};

	const showAddResearchPartnerModal = () => {
		setAddResearchPartner(true);
	};

	const getResearchPartnerinfo = (researchPId) => () => {
		getResearchPartnerInfo(researchPId)
			.then((resp) => {
				setResearchPartnerInfo(resp.data.payload);
				setEditResearchPartner(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const resetResearchPartner = () => {
		setResearchPartnerList([]);
		setSearchText('');
		setSortedColumn('created_date');
		setSortOrder('dsc');
		setIsDescending(true);
		sessionStorage.setItem('researchSortCol', 'created_date');
		sessionStorage.setItem('researchSortOrd', 'dsc');
		setPageNo(1);
		setToggleState(!toggleState);
	};

	return (
		<div id="researchpartner-container">
			<Header
				title={StaticLabels.ResearchPartner_title}
				addClick={showAddResearchPartnerModal}
				showAddButton={isRoot || isAdmin}
				showAddButtonText={StaticLabels.RP_AddResearchP_Btn}
				searchFunction={searchReasearchPartner}
				searchText={searchText}
			/>
			<div className="researchPartnerList">
				<table className="tbl-list">
					<thead className="tbl-header">
						<tr>
							<td>
								<SortColumn
									className="status"
									displayName={StaticLabels.RP_Theader_Status}
									columnName={'status'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="name"
									displayName={StaticLabels.RP_Theader_Name}
									columnName={'name'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="type"
									displayName={StaticLabels.RP_Theader_Type}
									columnName={'type'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="address"
									displayName={StaticLabels.RP_Theader_Address}
									columnName={'address'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td>
								<SortColumn
									className="date"
									displayName={StaticLabels.RP_Theader_Date}
									columnName={'created_date'}
									sortOrder={sortOrder}
									sortedColumnName={sortedColumn}
									sortColumn={sortColumn}
								/>
							</td>
							<td className="edit" />
						</tr>
					</thead>
					<tbody className="tbl-body">
						{// props.researchPartnerList.length > 0 &&
						// props.researchPartnerList.map((list, index) => {
						researchPartnerList.length > 0 &&
							researchPartnerList.map((list) => {
								const status = list.status;
								return (
									<tr key={list.id} className={`tbl-row ${!status ? 'inactive-row' : ''}`}>
										<td>
											<div className={`status  ${status ? 'active' : 'inactive'}`}>●</div>
										</td>
										<td>
											<HighlightText text={list.name} searchText={searchText} className="name" />
										</td>
										<td>
											<HighlightText text={list.type} searchText={searchText} className="type" />
										</td>
										<td>
											<OverlayTrigger
												trigger={[ 'hover', 'focus' ]}
												rootClose={true}
												id="email-popup"
												placement="auto"
												overlay={
													<Popover bsPrefix="popover">
														<Popover.Content>
															<span>{list.address}</span>
														</Popover.Content>
													</Popover>
												}
											>
												<div className="address-text">
													<div className="line-brk">
														<HighlightText
															text={list.address}
															searchText={searchText}
															className="address"
														/>
													</div>
												</div>
											</OverlayTrigger>
										</td>
										<td>
											<div className="date">{list.created_date}</div>
										</td>
										<td>
											<div className="edit" onClick={getResearchPartnerinfo(list.id)}>
												{<i className="fa fa-pencil-alt" aria-hidden="true" />}
											</div>
										</td>
									</tr>
								);
							})}
						<Suspense
							fallback={
								<tr>
									<td>Loading...</td>
								</tr>
							}
						>
							<InfiniteScroll
								loading={loading}
								haveMoreRecords={haveMoreUsers}
								setNextPage={setNextPage}
							/>
						</Suspense>
					</tbody>
				</table>

				{!loading &&
				!haveMoreUsers && pageNo > 1 && (
					<div id="no-more-records" className="no-records no-more-records">
						No more records.
					</div>
				)}
				{loading && (
					<div className="loading-list">
						<div className="loading">loading</div>
						<div className="dot-pulse" />
					</div>
				)}
				{addResearchPartner && (
					<AddResearchPartner
						open={addResearchPartner}
						addResearchPartner={addResearchPartner}
						handleClose={handleClose}
						getResearchPartner={resetResearchPartner}
					/>
				)}

				{editResearchPartner && (
					<AddResearchPartner
						open={editResearchPartner}
						researchPartnerInfo={researchPartnerInfo}
						handleClose={handleClose}
						getResearchPartner={resetResearchPartner}
					/>
				)}

				{
					// 	props.researchPartnerList.length > 0 && (
					// 	<PaginationComponent
					// 		totalItemsCount={totalCustomerCount}
					// 		gotoPage={gotoPage}
					// 		currentPage={pageNo}
					// 		sessionKey={sKey}
					// 	/>
					// )
				}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		researchPartnerList: state.redCommon.researchPartnerList
	};
};

export default connect(mapStateToProps)(ResearchPartnerContainer);

ResearchPartnerContainer.propTypes = {
	loggedInUser: PropTypes.object
};

import React from 'react';
import { Button } from 'react-bootstrap';
import './buttonnormal.scss';
import PropTypes from 'prop-types';

/* 
	ButtonType: 1 -> cancelButton -> Button Grey
			 	2 -> SaveButton -> Button Blue
				3 -> DeleteButton -> Button Red  
			else -> normal button -> Button Green	
*/
const ButtonNormal = (props) => {
	return (
		// eslint-disable-next-line
		<Button 
			className={`${ props.className } 
							${ props.buttonType === '1' ? 'cancel-button' 
							: (props.buttonType === '2' ? 'save-button' 
							: (props.buttonType === '3' ? 'delete-button' : 'btn-normal')) }`} 
			id={props.id} 
			onClick={props.onClick}
			type={props.type}
			onKeyPress={props.onKeyPress}
			disabled={props.disabled}
		>
			{props.add && '+'}
			{// <i className="fa fa-plus plus-icon" aria-hidden="true" /> : ''
			}
			{`  ${ props.text }`}
		</Button>
	);
};
export default ButtonNormal;

ButtonNormal.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	id: PropTypes.string,
	type: PropTypes.string,
	text: PropTypes.string,
	buttonType: PropTypes.string,
	onKeyPress: PropTypes.func,
	add: PropTypes.string,
	disabled: PropTypes.bool
};

import React, { useState, useEffect } from 'react';
import './researchnetworkdetail.scss';
import { connect } from 'react-redux';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import ReasearchDataset from '../../component/researchdataset/ResearchDataset';
import {
	getDatasetDetail,
	getDatasetProfile,
	deactivateDataset,
	sendOTP,
	verifyOTP,
	processDataset,
	getStudyActiveList,
	getDatasetActivity,
	getReasonForAction,
	updateDatasetDetail,
	updateDatasetList
} from './researchNetworkDetailHelper';
import StaticLabels from '../../utils/messages';

// const types = {
// 	AVAILABLE: 'Available',
// 	ACQUIRING: 'Acquiring',
// 	ACQUISITION_FAILED: 'Error',
// 	ACTIVE: 'Active',
// 	REJECTED: 'Rejected',
// 	INACTIVE: 'Inactive'
// };

// const studyTypes = {
// 	PROCESSED: 'Processed',
// 	PROCESSING: 'Processing',
// 	ACQUISITION_FAILED: 'Error'
// };
// const typesKey = {
// 	AVAILABLE: 1,
// 	ACQUIRING: 2,
// 	ACQUISITION_FAILED: 3,
// 	ACTIVE: 4,
// 	REJECTED: 5,
// 	INACTIVE: 6
// };

// const studyTypeKey = {
// 	PROCESSED: 1,
// 	PROCESSING: 2,
// 	ACQUISITION_FAILED: 3
// };

const ResearchNetworkDetailContainer = (props) => {
	const datasetId = props.match.params.id;
	const [ datasetDetail, setDatasetDetail ] = useState([]);
	const [ datasetProfile, setDatasetProfile ] = useState({});
	const [ activeStudy, setActiveStudy ] = useState([]);
	const [ datasetActivity, setDatasetActivity ] = useState([]);
	const [ actionReason, setActionReason ] = useState([]);
	const [ datasetDescription, setDatasetDescription ] = useState('');
	const [ invalidDatasetForRP, setInvalidDatasetForRP ] = useState('');

	const getDetail = (loader) => {
		if (datasetId) {
			if (loader) {
				showLoader(true);
			}
			getStudyActiveList()
				.then((resp) => {
					// showLoader(false);
					const response = resp && resp.data.payload.sort();
					const updateList = response.map((study, index) => {
						return {
							label: `${study.customerName} - ${study.studyName}`,
							value: study.studyId
						};
					});
					setActiveStudy(updateList);
				})
				.catch((error) => {
					console.log(error);
				});
			getDatasetDetail(datasetId)
				.then((resp) => {
					const reseachPartnerId = props.match.params.rp_id;

					if (resp.data.payload.researchPartnerId.toString() === reseachPartnerId) {
						setDatasetDetail(resp.data.payload);
						setDatasetDescription(resp.data.payload.description);
						// showLoader(false);
					} else {
						setInvalidDatasetForRP(StaticLabels.DS_InvalidRP_Error);
					}
				})
				.catch((err) => {
					if (err.status === 400) {
						setInvalidDatasetForRP(StaticLabels.DS_InvalidRP_Error);
					}
					console.log(err);
					showLoader(false);
				});
			getDatasetProfile(datasetId)
				.then((resp) => {
					setDatasetProfile(resp.data.payload);
					// showLoader(false);
				})
				.catch((err) => {
					showLoader(false);
				});
			getDatasetActivity(datasetId)
				.then((resp) => {
					setDatasetActivity(resp.data.payload.activity);
				})
				.catch((err) => {
					console.log(err);
				});
			getReasonForAction()
				.then((resp) => {
					const updatedList = resp.data.payload.map((reason) => {
						return {
							label: reason,
							value: reason
						};
					});
					setActionReason(updatedList);
				})
				.catch((err) => {
					console.log(err);
				});
			showLoader(false);
		}
	};

	useEffect(() => {
		getDetail(true);
	}, []);

	useEffect(
		() => {
			if (
				sessionStorage.getItem('prevChange') &&
				sessionStorage.getItem('prevChange') !== props.listenChange.toString()
			) {
				setTimeout(() => {
					getDetail();
				}, 300);
			}
		},
		[ props.listenChange ]
	);

	const deactivateData = (dsId, imageBytes, reason) => {
		// showLoader(false);
		deactivateDataset(dsId, imageBytes, reason)
			.then((resp) => {
				// getDetail();
				const rpid = props.match.params.rp_id;
				props.history.push(`/dataadmin/${rpid}`);
				showLoader(false);
			})
			.catch((err) => {
				showLoader(false);
			});
	};

	const goBack = () => {
		updateDatasetList([]);
		const rpid = props.match.params.rp_id;
		props.history.push(`/dataadmin/${rpid}`);
	};

	const sendOtp = () => {
		return new Promise((resolve, reject) => {
			showLoader(true);
			sendOTP()
				.then((resp) => {
					showLoader(false);
					resolve(resp.data.payload);
				})
				.catch((err) => {
					reject(err);
					showLoader(false);
				});
		});
	};

	const verfiyOtp = (code, otp) => {
		// verifyOTP(code,otp).
		return new Promise((resolve, reject) => {
			showLoader(true);
			verifyOTP(code, otp)
				.then((resp) => {
					showLoader(false);
					resolve(resp.data.payload);
				})
				.catch((err) => {
					reject(err);
					showLoader(false);
				});
		});
	};

	const processDataSet = (dsId, imageBytes, studyId) => {
		processDataset(dsId, imageBytes, studyId)
			.then((resp) => {
				getDetail();
				// props.history.push(`/dataadmin/${datasetId}`);
			})
			.catch((err) => {
				showLoader(false);
			});
	};

	const editDatasetDesc = (e) => {
		setDatasetDescription(e.target.value);
	};
	const cancelEditing = () => {
		setDatasetDescription(datasetDetail.description);
	};

	const saveDatasetDetail = () => {
		showLoader(true);
		updateDatasetDetail(datasetDetail.datasetId, datasetDescription)
			.then((resp) => {
				showLoader(false);
			})
			.catch((err) => {
				console.log(err);
				showLoader(false);
			});
	};
	return (
		<div className="research-network-detail-container">
			<div className="header-container">
				<span className="goback" onClick={goBack}>
					{'<'} Back
				</span>
			</div>
			{invalidDatasetForRP ? (
				<div className="unauthorised-user-forRP">
					<h3>{invalidDatasetForRP}</h3>
				</div>
			) : (
				Object.keys(datasetProfile).length > 0 && (
					<ReasearchDataset
						{...datasetDetail}
						editDatasetDesc={editDatasetDesc}
						description={datasetDescription}
						cancelEditing={cancelEditing}
						saveDatasetDetail={saveDatasetDetail}
						studies={datasetDetail.studies}
						datasetMetrics={datasetProfile.metrics}
						sendOTP={sendOtp}
						verfiyOtp={verfiyOtp}
						datasetId={datasetId}
						showDetail={true}
						deactivateDataset={deactivateData}
						processDataset={processDataSet}
						getDetail={getDetail}
						activeStudy={activeStudy}
						datasetActivity={datasetActivity}
						actionReason={actionReason}
					/>
				)
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		listenChange: state.redCommon.listenChange
	};
};

export default connect(mapStateToProps)(ResearchNetworkDetailContainer);

ResearchNetworkDetailContainer.propTypes = {
	loggedInUser: PropTypes.object,
	history: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.any,
			rp_id: PropTypes.any	//eslint-disable-line
		})
	}),
	listenChange: PropTypes.bool
};

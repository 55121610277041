import React, { useState, useEffect } from 'react';
import './researchdataset.scss';
import { connect } from 'react-redux';
import StaticLabels from '../../utils/messages';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import IconLoading from '../../images/loading_icon.svg';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import ModalComponent from '../../component/modal/ModalComponent';
import DatasetActivity from '../../container/datasetactivity/DatasetActivity';
import SignatureModal from '../signaturemodal/SignatureModal';
import StudyModal from './../studymodal/StudyModal';
import IconSign from './../../images/sig_icon.svg';
import SignaturePopup from './../popupsignature/SignaturePopup';
import { ProgressBar } from 'react-bootstrap';

const types = {
	AVAILABLE: 'Available',
	ACQUIRING: 'Acquiring',
	ACQUISITION_FAILED: 'Error',
	ACTIVE: 'Active',
	REJECTED: 'Rejected',
	INACTIVE: 'Inactive',
	ACQUISITION_REQUESTED: 'Acquisition requested'
};

const studyTypes = {
	PROCESSED: 'Processed',
	PROCESSING: 'Processing',
	PROCESSING_FAILED: 'Error'
};
const typesKey = {
	AVAILABLE: 1,
	ACQUIRING: 2,
	ACQUISITION_FAILED: 3,
	ACTIVE: 4,
	REJECTED: 5,
	INACTIVE: 6,
	ACQUISITION_REQUESTED: 7
};

const studyTypeKey = {
	PROCESSED: 1,
	PROCESSING: 2,
	PROCESSING_FAILED: 3
};

const optionsPieChart = {
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,
		backgroundColor: 'transparent',
		type: 'pie',
		height: 200
	},
	legend: {
		align: 'right',
		verticalAlign: 'middle',
		layout: 'vertical',
		itemMarginTop: 5,
		itemMarginBottom: 5,
		itemStyle: {
			lineHeight: '14px'
		}
	},
	credits: {
		enabled: false
	},
	title: {
		text: ''
	},
	accessibility: {
		point: {
			valueSuffix: '%'
		}
	},
	plotOptions: {
		pie: {
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: false
			},
			showInLegend: true,
			innerSize: '80%',
			depth: 5
		}
	},
	series: [
		{
			name: '',
			data: []
		}
	]
};

const optionColumnChart = {
	chart: {
		type: 'column',
		backgroundColor: 'transparent'
	},
	credits: {
		enabled: false
	},
	title: {
		text: ''
	},
	xAxis: {
		title: {
			enabled: false
		}
	},
	yAxis: {
		title: {
			enabled: false
		}
	},
	legend: {
		enabled: false
	},
	plotOptions: {
		column: {
			pointPadding: 0.2,
			borderWidth: 0,
			borderRadiusTopLeft: 5,
			borderRadiusTopRight: 5
		}
	},
	series: [
		{
			name: '',
			data: [],
			pointWidth: 10
		}
	]
};

const generateGenderData = (data) => {
	const graphData = [];
	try {
		Object.keys(data).map((key) => {
			graphData.push([ key, parseInt(data[key]) ]);
		});
	} catch (error) {
		return graphData;
	}
	return graphData;
};

const generateAgeData = (data) => {
	const graphCategories = [];
	const graphData = [];

	try {
		Object.keys(data).map((key) => {
			graphCategories.push(key);
			graphData.push(parseInt(data[key]));
		});
	} catch (error) {
		return {
			categories: graphCategories,
			data: graphData
		};
	}
	return {
		categories: graphCategories,
		data: graphData
	};
};

const ResearchDataset = (props) => {
	const [ rejectDataset, setRejectdataset ] = useState(false);
	const [ showSignature, setShowSignature ] = useState(false);
	const [ sigFlag, setSigFlag ] = useState(0);
	const [ sigBytes, setSigBytes ] = useState('');
	const [ dataSetMetrics, setDataSetMetrics ] = useState(props.datasetMetrics || {});
	const [ ageData, setAgeData ] = useState({});
	const [ genderData, setGenderData ] = useState({});
	const [ activeStudy, setActiveStudy ] = useState([]);
	const [ addStudy, setAddStudy ] = useState(false);
	const [ studyId, setStudyId ] = useState(0);
	const [ error, setError ] = useState('');
	const [ showImage, setShowImage ] = useState(false);
	const [ imageString, setImageString ] = useState('');
	const [ progress, setProgress ] = useState(props.acquisitionProgress);
	const [ reasonForAction, setReasonForAction ] = useState('');
	const [ studyProgress, setStudyProgress ] = useState(
		props.studies &&
			props.studies.map((study) => {
				if (study.status === 'PROCESSING') {
					const studyObj = {
						studyId: study.studyId,
						progress: study.studyProcessingProgress,
						datasetId: props.datasetId
					};
					return studyObj;
				}
			})
	);
	const [ editDesc, setEditDesc ] = useState(false);

	const processingStudies =
		props.studies &&
		props.studies.filter((study) => {
			if (study.status === 'PROCESSING') {
				return study;
			}
		});

	useEffect(
		() => {
			if (props.datasetMetrics) {
				setDataSetMetrics(props.datasetMetrics);
				optionsPieChart.series = [
					{
						name: '',
						data: generateGenderData(props.datasetMetrics.gender)
					}
				];
				setGenderData(optionsPieChart);
				const ageDataa = generateAgeData(props.datasetMetrics.age);

				optionColumnChart.xAxis = {
					categories: ageDataa.categories,
					crosshair: true
				};
				optionColumnChart.series = [
					{
						name: '',
						data: ageDataa.data,
						color: '#17DF6D',
						pointWidth: 10,
						dataLabels: {
							enabled: true
						}
					}
				];
				setAgeData(optionColumnChart);
			}
		},
		[ props.datasetMetrics ]
	);

	useEffect(
		() => {
			if (props.activeStudy && props.activeStudy.length >= 0) {
				setActiveStudy(props.activeStudy);
			}
		},
		[ props.activeStudy ]
	);

	const datasetClick = (e) => {
		if (e.target.id !== 'sign-icon') {
			if (props.status === 'ACTIVE' && !props.showDetail) {
				props.history.push('/researchnetworkdetail/' + props.datasetId + '/' + props.researchPartnerId);
			}
		}
	};

	const rejectDatasetClick = () => {
		setRejectdataset(true);
		setShowSignature(false);
		showLoader(false);
	};

	const rejectDatasetConfirm = (datasetId) => {
		if (props.rejectDatasetClick) {
			props.rejectDatasetClick(datasetId, sigBytes, reasonForAction);
		}
		setRejectdataset(false);
	};

	const closeRejectModal = () => {
		setReasonForAction('');
		setRejectdataset(false);
		setShowSignature(false);
		setSigFlag(0);
	};

	const selectReason = (e) => {
		setReasonForAction(e.value);
	};

	const captureSignature = (datasetId, imageBytes) => {
		setSigBytes(imageBytes);
		if (sigFlag === 1) {
			if (props.acquireDataSet) {
				props.acquireDataSet(datasetId, imageBytes, props.researchPartnerId, props.datasetName);
				setRejectdataset(false);
				setShowSignature(false);
			}
		} else if (sigFlag === 2) {
			rejectDatasetClick();
		} else if (sigFlag === 3) {
			if (props.activateDataset) {
				props.activateDataset(datasetId, imageBytes);
				setRejectdataset(false);
				setShowSignature(false);
			}
		} else if (sigFlag === 4) {
			if (props.deactivateDataset) {
				props.deactivateDataset(datasetId, imageBytes, reasonForAction);
				setRejectdataset(false);
				setShowSignature(false);
			}
		} else if (sigFlag === 5) {
			if (props.processDataset) {
				props.processDataset(datasetId, imageBytes, studyId);
				setRejectdataset(false);
				setStudyId(0);
				setShowSignature(false);
				// props.getDetail();
			}
		}
	};
	const setSignatureFlag = (flagNumber) => {
		// 1 - Acquire
		// 2 - Reject
		// 3 - Activate
		// 4 - Deactivate
		// 5 - Process
		setSigFlag(flagNumber);
		setShowSignature(true);
	};

	const rejectbtnClick = () => {
		setSigFlag(2);
		setShowSignature(true);
	};

	const openStudyModal = () => {
		setAddStudy(true);
	};

	const closeStudyModal = () => {
		setError('');
		setAddStudy(false);
	};

	const studyNextClick = () => {
		if (studyId === 0) {
			setError(StaticLabels.MP_Study_Error);
		} else {
			closeStudyModal();
			setSignatureFlag(5);
		}
	};

	const selectStudy = (e) => {
		setStudyId(e.value);
		setError('');
	};

	const showSignImage = (imgString) => {
		setShowImage(true);
		setImageString(imgString);
	};

	const closeImage = () => {
		setShowImage(false);
	};

	const editDescClick = () => {
		setEditDesc(true);
	};

	const editDatasetDesc = (e) => {
		props.editDatasetDesc(e);
	}
	const cancelEditing = () => {
		props.cancelEditing();
		setEditDesc(false);
	}

	const saveDatasetDetail = () => {
		props.saveDatasetDetail();
		setEditDesc(false);
	};

	useEffect(
		() => {
			if (props.datasetId === props.acquisitionProgressData.datasetId) {
				setProgress(props.acquisitionProgressData.progress);
			}
			if (props.datasetId == props.studyProgressChange.datasetId) {
				setStudyProgress(props.studyProgressChange);
			}
		},
		[ props.acquisitionProgressData, props.studyProgressChange ]
	);
	return (
		<React.Fragment>
			<div
				key={`${props.status}_${props.datasetId}`}
				className={`dataset-container ${props.status === 'ACTIVE' ? 'active' : ''}`}
				onClick={datasetClick}
			>
				<div className={`status type_${typesKey[props.status]}`}>{types[props.status]}</div>
				<div className="dataset-info">
					<div
						className={`${props.status === 'ACQUIRING' || props.status === 'ACQUISITION_REQUESTED'
							? 'left-panel100'
							: 'left-panel'}`}
					>
						<div className="dataset-title">
							{processingStudies &&
							processingStudies.length > 0 &&
							props.status === 'ACTIVE' && <img src={IconLoading} className="loading-icon" />}

							{(props.status === 'ACQUIRING' || props.status === 'ACQUISITION_REQUESTED') && (
								<img src={IconLoading} className="loading-icon" />
							)}
							<span className={`title-type-${typesKey[props.status]}`}>{props.datasetName}</span>
						</div>
						{props.status === 'ACQUISITION_FAILED' && (
							<div className="dataset-error">
								<strong>
									<i>{StaticLabels.DS_Error} </i>
								</strong>{' '}
								{StaticLabels.DS_Error_Message}{' '}
							</div>
						)}
						{
						// (props.status === 'ACQUIRING' || props.status === 'ACQUISITION_REQUESTED') && (
						// 	<div className="acquiring-progressbar">
						// 		{(props.acquisitionProgress || props.acquisitionProgress === 0) && (
						// 			<ProgressBar
						// 				// animated
						// 				// now={props.acquisitionProgress}
						// 				now={progress}
						// 				className="acquiring-progress"
						// 			/>
						// 		)}
						// 	</div>
						// )
						}
						<div className="dataset-detail">
							<div className="dataset-description">
								<div className="heading">
									<div className="heading-desc">{StaticLabels.DS_Description}</div>
									{props.showDetail &&
									!editDesc && (
										<i className="fas fa-pencil-alt" aria-hidden="true" onClick={editDescClick} />
									)}
								</div>
								{props.showDetail && editDesc ? (
									<div className="edit-desc-detail">
										<textarea
											type="text"
											className="detail detail-input"
											value={props.description}
											name="description"
											onChange={editDatasetDesc}
										/>
										<div className="detail-footer">
											<ButtonNormal
												className="cancel-btn"
												buttonType="1"
												text={StaticLabels.UP_Cancel_Btn}
												onClick={cancelEditing}
											/>
											<ButtonNormal
												className="save-description-btn"
												buttonType="2"
												text={StaticLabels.UP_Save_Btn}
												onClick={saveDatasetDetail}
											/>
										</div>
									</div>
								) : (
									<div className="detail">{props.description}</div>
								)}
							</div>
							<div className="dataset-date-container">
								<div className="dataset-date">
									<div className="date-heading">{StaticLabels.DS_Date_Received}</div>
									<div className="detail-date">
										{props.dateCreated && format(new Date(props.dateCreated), 'MM/dd/yy hh:mm a')}
									</div>
								</div>
								{!props.showDetail && (
									<div className="dataset-date">
										<div className="date-heading">{StaticLabels.DS_Date_Updated}</div>
										<div className="detail-date">
											{props.dateUpdated ? (
												format(new Date(props.dateUpdated), 'MM/dd/yy hh:mm a')
											) : (
												format(new Date(props.dateCreated), 'MM/dd/yy hh:mm a')
											)}
										</div>
									</div>
								)}
							</div>
						</div>
						{(props.status === 'REJECTED' ||
							props.status === 'INACTIVE' ||
							props.status === 'ACQUIRING' ||
							props.status === 'ACQUISITION_REQUESTED') && (
							<div className="dataset-sign-info">
								<span className="desc-date">{`${format(
									new Date(props.lastActionDate),
									'MM/dd/yy'
								)}`}</span>{' '}
								<i id="dataset-description">
									{`  ${props.lastActionDescription ? props.lastActionDescription : ''}`}
								</i>
								{props.lastActionSignature && (
									<img
										className="sign-icon"
										src={IconSign}
										alt="Sign icon"
										id="sign-icon"
										onClick={() => showSignImage(props.lastActionSignature)}
									/>
								)}
							</div>
						)}
					{
						(props.status === 'ACQUIRING' || props.status === 'ACQUISITION_REQUESTED') && (
							<div className="acquiring-progressbar">
								{(props.acquisitionProgress || props.acquisitionProgress === 0) && (
									<ProgressBar
										// animated
										// now={props.acquisitionProgress}
										now={progress}
										className="acquiring-progress"
									/>
								)}
							</div>
						)
						}
					</div>

					{(props.status === 'AVAILABLE' || props.status === 'ACQUISITION_FAILED') && (
						<div className="right-panel">
							<ButtonNormal
								className="btn-acquire"
								text={StaticLabels.DS_Acquire_Btn}
								onClick={() => setSignatureFlag(1)}
							/>
							<br />
							<a className="btn-reject" onClick={rejectbtnClick}>
								{' '}
								{StaticLabels.DS_Reject_Btn}
							</a>
						</div>
					)}
					{props.status === 'INACTIVE' && (
						<div className="right-panel">
							<ButtonNormal
								className="btn-acquire"
								text={StaticLabels.DS_Activate_Btn}
								onClick={() => setSignatureFlag(3)}
							/>
						</div>
					)}
					{props.status === 'ACTIVE' &&
					props.showDetail && (
						<div className="right-panel">
							<ButtonNormal
								className="btn-process"
								text={StaticLabels.DS_Process_Btn}
								onClick={openStudyModal}
								disabled={processingStudies.length ? true : false}
							/>
							<br />
							{props.studies &&
							props.studies.length === 0 && (
								<a className="btn-reject" onClick={() => setSignatureFlag(4)}>
									{' '}
									{StaticLabels.DS_Deactivate_Btn}
								</a>
							)}
						</div>
					)}
				</div>
				{rejectDataset && (
					<ModalComponent
						open={true}
						className="reject-modal"
						handleClose={closeRejectModal}
						header={'  '}
						closeButton={true}
						content={
							<div>
								<p>{`${StaticLabels.DS_Reject_Message} ${props.datasetName} ?`}</p>
							</div>
						}
						footer={
							<React.Fragment>
								<ButtonNormal
									className="buttons"
									onClick={() => rejectDatasetConfirm(props.datasetId)}
									text={StaticLabels.DS_Reject_Modal_Btn}
								/>
								<span className="cancel" onClick={closeRejectModal}>
									{StaticLabels.DS_Cancel_Btn}
								</span>
							</React.Fragment>
						}
					/>
				)}
				{addStudy && (
					<StudyModal
						activeStudy={activeStudy}
						closeStudyModal={closeStudyModal}
						studyNextClick={studyNextClick}
						studyId={studyId}
						onStudySelect={selectStudy}
						error={error}
					/>
				)}

				{props.studies && props.studies.length > 0 &&
					<div className="dataset-studies">
						 <div className="heading">{StaticLabels.DS_Studies}</div>
						{
							props.studies.map((item) => {
								return (
									<StudyRow
										datasetId={props.datasetId}
										key={`studyrow_${props.datasetId}${item.studyId}`}
										{...item}
										imageString={imageString}
										showImage={showImage}
										closeImage={closeImage}
										showSignImage={() => showSignImage(item.signatureBase64)}
										studyProgress={studyProgress}
									/>
								);
							})
						}
					</div>
				}
				{props.showDetail &&
				dataSetMetrics && (
					<div className="dataset-detail-info">
						<div className="dataset-activity">
							<div className="heading">{StaticLabels.DS_Activity}</div>
							<DatasetActivity datasetActivity={props.datasetActivity} />
						</div>
						<div className="dataset-profile-gender">
							<div className="heading">{StaticLabels.DS_Profile}</div>
							{Object.keys(dataSetMetrics).length > 0 ? (
								<React.Fragment>
									<div className="profile-info-container">
										<div className="box">
											<div className="info-row">
												<div className="text">{StaticLabels.DS_Total_Patients}</div>
												<div className="value">
													{(dataSetMetrics.basic &&
														parseFloat(
															dataSetMetrics.basic['Total patients']
														).toLocaleString()) ||
														''}
												</div>
											</div>
											<div className="info-row">
												<div className="text">{StaticLabels.DS_Total_Encounters}</div>
												<div className="value">
													{(dataSetMetrics.basic &&
														parseFloat(
															dataSetMetrics.basic['Total encounters']
														).toLocaleString()) ||
														''}
												</div>
											</div>
										</div>
										<div className="box">
											<div className="info-row">
												<div className="text">{StaticLabels.DS_Earliest_Encounter}</div>
												<div className="value">
													{(dataSetMetrics.basic &&
														dataSetMetrics.basic['Earliest encounter']) ||
														''}
												</div>
											</div>
											<div className="info-row">
												<div className="text">{StaticLabels.DS_Latest_Encounter}</div>
												<div className="value">
													{(dataSetMetrics.basic &&
														dataSetMetrics.basic['Latest encounter']) ||
														''}
												</div>
											</div>
										</div>
									</div>
									<div className="box gender-container">
										<div className="heading">{StaticLabels.DS_Gender}</div>
										<div className="chart">
											<HighchartsReact highcharts={Highcharts} options={genderData} />
										</div>
									</div>
									<div className="box age-container">
										<div className="heading">{StaticLabels.DS_Age}</div>
										<div className="chart">
											<HighchartsReact highcharts={Highcharts} options={ageData} />
										</div>
									</div>
								</React.Fragment>
							) : (
								<div className="no-dataset-profile">{'No profile available.'}</div>
							)}
						</div>
					</div>
				)}
			</div>
			{showSignature && (
				<SignatureModal
					loggedInUser={props.loggedInUser}
					datasetId={props.datasetId}
					closeSignatureModal={closeRejectModal}
					captureSignature={captureSignature}
					sendOTP={props.sendOTP}
					otpCode-={props.otpCode}
					verfiyOtp={props.verfiyOtp}
					sigFlag={sigFlag}
					actionReason={props.actionReason}
					selectReason={selectReason}
					reasonForAction={reasonForAction}
				/>
			)}
			{showImage && <SignaturePopup imageString={imageString} handleClose={closeImage} />}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		acquisitionProgressData: state.redCommon.acquisitionProgressData,
		studyProgressChange: state.redCommon.studyProgressChange
	};
};

export default withRouter(connect(mapStateToProps)(ResearchDataset));

ResearchDataset.propTypes = {
	loggedInUser: PropTypes.object,
	status: PropTypes.string,
	history: PropTypes.object,
	rejectDatasetClick: PropTypes.func,
	description: PropTypes.string,
	datasetName: PropTypes.string,
	dateCreated: PropTypes.string,
	datasetId: PropTypes.any,
	showDetail: PropTypes.bool,
	sendOTP: PropTypes.func.isRequired,
	datasetMetrics: PropTypes.object,
	studies: PropTypes.array,
	activeStudy: PropTypes.array,
	researchPartnerId: PropTypes.any,
	acquireDataSet: PropTypes.func,
	activateDataset: PropTypes.func,
	processDataset: PropTypes.func,
	deactivateDataset: PropTypes.func,
	lastActionDate: PropTypes.string,
	lastActionDescription: PropTypes.string,
	lastActionSignature: PropTypes.string,
	acquisitionProgress: PropTypes.number,
	datasetActivity: PropTypes.array,
	otpCode: PropTypes.func,
	verfiyOtp: PropTypes.func,
	actionReason: PropTypes.array,
	showSignImage: PropTypes.string,
	signatureBase64: PropTypes.string,
	acquisitionProgressData: PropTypes.object,
	studyProgressChange: PropTypes.object,
	dateUpdated: PropTypes.string,
	saveDatasetDetail: PropTypes.func,
	editDatasetDesc: PropTypes.func,
	cancelEditing: PropTypes.func
};

const StudyRow = (props) => {
	const capitalize = (str) => {
		return str[0].toUpperCase() + str.slice(1);
	};

	const getSignImage = (e) => {
		e.stopPropagation();
		props.showSignImage(props.signatureBase64);
	};

	return (
		<React.Fragment>
			{props.status === 'PROCESSING' && (
				<div className="processing-study">
					<div className="processing-study-row">
						<div className="study-date">
							<strong className="dark">{format(new Date(props.dateCreated), 'MM/dd/yy')}</strong>
						</div>
						<div className="study-name">
							{props.customerName}
							{': '}
							{props.studyName.toUpperCase()}
						</div>
						<div className="processedby">
							<i>
								{StaticLabels.DS_std_processedby}{' '}
								<span className="processedby-username">{capitalize(props.processingUser)}</span>
							</i>
							{props.signatureBase64 && (
								<img
									className="sign-icon"
									src={IconSign}
									alt="Sign icon"
									id="sign-icon"
									onClick={(e) => getSignImage(e)}
								/>
							)}
						</div>
						<div className={`study-status-${studyTypeKey[props.status]}`}>
							<strong>{studyTypes[props.status]}</strong>
						</div>
					</div>
					<ProgressBar
						animated
						now={
							props.studyProgress &&
							props.studyProgress.datasetId == props.datasetId && //compare value irrespect to datatype
							props.studyId == props.studyProgress.studyId ? (
								props.studyProgress.progress
							) : (
								props.studyProcessingProgress
							)
						}
						className="success-progress-bar"
					/>
				</div>
			)}
			{props.status === 'PROCESSED' && (
				<React.Fragment>
					<div className="processed-study-row">
						<div className="study-date">
							<strong className="dark">{format(new Date(props.dateCreated), 'MM/dd/yy')}</strong>
						</div>
						<div className="study-name">
							{props.customerName}
							{': '}
							{props.studyName.toUpperCase()}
						</div>
						<div className="records">
							<i>{StaticLabels.DS_std_Records_Processed}</i>{' '}
							<span className="dark">{props.recordsProcessed}</span>
						</div>
						<div className="time">
							<i>{StaticLabels.DS_std_Processing_time}</i>{' '}
							<span className="dark">{props.processingTime}</span>
						</div>
						<div className="processedby">
							<i>{StaticLabels.DS_std_ProcessedBy}</i>{' '}
							<span className="dark">{capitalize(props.processingUser)}</span>
							{props.signatureBase64 && (
								<img
									className="sign-icon"
									src={IconSign}
									alt="Sign icon"
									onClick={(e) => {
										getSignImage(e);
									}}
								/>
							)}
						</div>
						<div className={`study-status-${studyTypeKey[props.status]}`}>
							<strong>{studyTypes[props.status]}</strong>
						</div>
					</div>
				</React.Fragment>
			)}
			{props.status === 'PROCESSING_FAILED' && (
				<div className="study-row error">
					<div className="study-date">
						<span className="err">!</span>
						<i>
							<strong>{format(new Date(props.dateCreated), 'MM/dd/yy')}</strong>{' '}
						</i>
					</div>
					<div className="study-name">
						{props.customerName}
						{': '} {props.studyName}
					</div>

					<div className="study-error">
						<i>
							<strong>
								{StaticLabels.DS_std_Error}
								{':'}
							</strong>
						</i>{' '}
						<span className="msg">{props.statusDescription}</span>
					</div>
					<div className={`study-status-${studyTypeKey[props.status]}`}>
						<strong>{studyTypes[props.status]}</strong>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};
StudyRow.propTypes = {
	status: PropTypes.string,
	history: PropTypes.object,
	dateCreated: PropTypes.string,
	showSignImage: PropTypes.func,
	signatureBase64: PropTypes.string,
	// otpCode: PropTypes.func,
	processingUser: PropTypes.string,
	studyProcessingProgress: PropTypes.number,
	customerName: PropTypes.string,
	studyName: PropTypes.string,
	recordsProcessed: PropTypes.number,
	processingTime: PropTypes.string,
	statusDescription: PropTypes.string,
	studyProgress: PropTypes.array,
	datasetId: PropTypes.any,
	studyId: PropTypes.number
};

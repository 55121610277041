import React from 'react';
import './highlighttext.scss';

export default function HighlightText(props) {
	const { text, searchText, className } = props;
	if (searchText) {
		return props.type === 'email' ? (
			<a
				href={`mailto:${text}`}
				dangerouslySetInnerHTML={{
					__html: text.replace(
						new RegExp(`(${searchText})`, 'gi'),
						(str) => `<span class = "highlight">${str}</span>`
					)
				}}
				className={className}
				onClick={props.onClick}
			/>
		) : (
			<span
				dangerouslySetInnerHTML={{
					__html: text.replace(
						new RegExp(`(${searchText})`, 'gi'),
						(str) => `<span class = "highlight">${str}</span>`
					)
				}}
				className={className}
			/>
		);
	} else {
		return props.type === 'email' ? (
			<a
				href={`mailto:${text}`}
				dangerouslySetInnerHTML={{
					__html: text
				}}
				className={className}
			/>
		) : (
			<span
				dangerouslySetInnerHTML={{
					__html: text
				}}
				className={className}
			/>
		);
	}
}

import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const virtualApplicancesList = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.VAMonitorList, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../component/header/Header';
import StaticLabels from '../../utils/messages';
import './virtualappliance.scss';
import { virtualApplicancesList } from './virtualapplianceHelper';
import { format } from 'date-fns';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const VirtualApplianceMonitor = () => {
	const [ VAMonitorList, setVAMonitorList ] = useState();

	useEffect(() => {
		virtualApplicancesList()
			.then((resp) => {
				setVAMonitorList(resp.data.payload);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<div>
			<Header title={StaticLabels.VA_Title} showAddButton={false} hideSearch={true} />
			<div className="virtual-appliance">
				<div className="table-header">{StaticLabels.VA_Table_Header}</div>
				<table className="tbl-list">
					<tbody className="tbl-body">
						{VAMonitorList &&
							VAMonitorList.map((list, index) => {
								return (
									<tr key={index} className={`tbl-row ${list.status !== 'UP' ? 'inactive' : ''}`}>
										<td>
											<div className={`status  ${list.status === 'UP' ? 'active' : 'inactive'}`}>
												●{' '}
											</div>
										</td>
										<td className='rp-name'>
											<OverlayTrigger
												trigger={[ 'hover', 'focus' ]}
												rootClose={true}
												id="email-popup"
												placement="top"
												overlay={
													<Popover bsPrefix="email-popover popover">
														<Popover.Content className="email-popover-content">
															<div className="dataset-element">
																{list.researchPartnerName}
															</div>
														</Popover.Content>
													</Popover>
												}
											>
												<div className="dataset-element">{list.researchPartnerName}</div>
											</OverlayTrigger>
										</td>
										<td className="time">{`last heartbeat ${list.lastUptime &&
											format(new Date(list.lastUptime), 'MM/dd/yy hh:mm a')}`}</td>
										<td>{`${list.uptimePercentage.toFixed(1)}% up time `}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps)(VirtualApplianceMonitor);

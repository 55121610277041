import React, { useEffect, useState } from 'react';
import './documentcontainer.scss';
import { getDocumentRawContent } from './documentHelper';
import { connect } from 'react-redux';
import { showLoader } from '../../utils/commonUtils';
import StaticLabels from '../../utils/messages';
import DocumentRawContent from './DocumentRawContent';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const DocumentContainer = (props) => {
	const [ headerNodes, setHeaderNodes ] = useState([]);
	const [ tblOfContent, setTblOfContent ] = useState([]);
	const [ fileContent, setFileContent ] = useState('');
	const [ studyName, setStudyName ] = useState('');
	const [ studyDocName, setStudyDocName ] = useState('');
	const [ pdfFileContent, setPdfFileContent ] = useState('');
	const [ docType, setDoctype ] = useState('');

	useEffect(() => {
		showLoader(true);
		const docName = props.match.params.docid;
		getDocumentRawContent(docName)
			.then((resp) => {
				if (resp.headers['content-type'] === 'application/pdf') {
					setDoctype(resp.headers['content-type']);
					setPdfFileContent(resp);
				} else {
					setFileContent(resp.data);
				}

				var el = document.createElement('html');
				el.innerHTML = resp.data;
				// // el.querySelectorAll('style').forEach(item => item.remove())
				// const bodyContent = el.querySelector('body')
				// setFileContent(bodyContent.outerHTML);

				const nodes = el.querySelectorAll('.header-h3, .header-h4');
				setHeaderNodes(nodes);
				showLoader(false);
			})
			.catch((err) => {
				showLoader(false);
				console.log(err);
			});
	}, []);

	useEffect(
		() => {
			props.studyDocumentList.map((item) => {
				item.documentList.map((doclist) => {
					if (doclist.templateId === props.match.params.docid) {
						setStudyDocName(doclist.name[0].toUpperCase() + doclist.name.slice(1));
						setStudyName(item.studyName);
					}
				});
			});
		},
		[ props.studyDocumentList ]
	);

	useEffect(
		() => {
			const contentArray = [];

			headerNodes.forEach((node) => {
				contentArray.push(
					`<${node.localName} class='${node.className}' id='${node.id}'>${node.innerHTML}</${node.localName}>`
				);
			});

			// for (var i = 0; i < headerNodes.length; i++) {
			// 	contentArray.push(
			// 		`<${headerNodes[i].localName} class='${headerNodes[i].className}' id='${headerNodes[i]
			// 			.id}'>${headerNodes[i].innerHTML}</${headerNodes[i].localName}>`
			// 	);
			// }
			setTblOfContent(contentArray);
		},
		[ headerNodes ]
	);

	const scroll = (event) => {
		if (event.type === 'click') {
			var activeElements = document.getElementsByClassName('subheader active');
			while (activeElements.length > 0) {
				activeElements[0].classList.remove('active');
			}

			var activeoverview = document.getElementsByClassName('overview active');
			while (activeElements.length > 0) {
				activeoverview[0].classList.remove('active');
			}

			event.currentTarget.classList.add('active');
			//TODO index--sequnce
			const nodeClass = event.currentTarget.firstChild.classList[0];
			const nodes = Array.from(document.getElementsByClassName(nodeClass));
			const index = nodes.indexOf(event.currentTarget.firstChild);
			const rightContent = document.getElementById('document-content');
			const respectiveHeader = rightContent.getElementsByClassName(nodeClass)[index];
			respectiveHeader.scrollIntoView({ behavior: 'smooth' });
			// const targetId = event.currentTarget.firstChild.id;
			// const id = targetId.includes('.') ? targetId.replace('.', '\\.') : targetId;
			// const section = document.querySelectorAll(`#${id}`);

			// if (section && section.length > 1) {
			// 	// setDidScroll(false);
			// 	section[1].scrollIntoView({ behavior: 'smooth' });
			// } else {
			// 	// setDidScroll(false);
			// 	section.scrollIntoView({ behavior: 'smooth' });
			// }
		} else {
			var elements = event.target.querySelectorAll('.header-h3, .header-h4');
			const nodesH3 = Array.from(event.target.querySelectorAll('.header-h3'));
			const nodesH4 = Array.from(event.target.querySelectorAll('.header-h4'));

			elements.forEach((element) => {
				// console.log(element.getBoundingClientRect());
				const positionTop = element.getBoundingClientRect();

				if (positionTop.y > -13 && positionTop.y < 100) {
					let index = -1; // nodes.indexOf(event.currentTarget.firstChild);
					let nodeClass = '';
					if (element.nodeName === 'H3') {
						index = nodesH3.indexOf(element);
						nodeClass = 'header-h3';
					}
					if (element.nodeName === 'H4') {
						index = nodesH4.indexOf(element);
						nodeClass = 'header-h4';
					}

					const leftContent = document.getElementById('subheader-section');
					const respectiveHeader = leftContent.getElementsByClassName(nodeClass)[index];
					var removeCls = document.getElementsByClassName('subheader active');
					while (removeCls.length > 0) {
						removeCls[0].classList.remove('active');
					}
					respectiveHeader.parentElement.classList.add('active');
					//TODO -- --index or sequence
					// const id = element.id.includes('.') ? element.id.replace('.', '\\.') : element.id;
					// const activeElements = document.querySelectorAll(`#${id}`);
					// var removeCls = document.getElementsByClassName('subheader active');
					// while (removeCls.length > 0) {
					// 	removeCls[0].classList.remove('active');
					// }
					// activeElements[0].parentElement.classList.add('active');
				}
			});
		}
	};

	const scrollTop = () => {
		let section;
		if(docType === 'application/pdf') {
			section = document.getElementById('pdf-document')
		} else {
			section = document.getElementById('document-content');
		}
		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
		}
	};
	return (
		<div className="document-container">
			<div className="doc-left-content">
				<OverlayTrigger
					trigger={[ 'hover', 'focus' ]}
					rootClose={true}
					id="doc-popup"
					placement="bottom"
					overlay={
						<Popover bsPrefix="doc-popover popover">
							<Popover.Content className="doc-popover-content">
								<span>
									<p className="study-name">{studyName}</p>
								</span>
							</Popover.Content>
						</Popover>
					}
				>
					<p className="study-name">{studyName}</p>
				</OverlayTrigger>
				<OverlayTrigger
					trigger={[ 'hover', 'focus' ]}
					rootClose={true}
					id="doc-popup"
					placement="top"
					overlay={
						<Popover bsPrefix="doc-popover popover">
							<Popover.Content className="doc-popover-content">
								<span>
									<span className="dataset-element">{studyDocName}</span>
								</span>
							</Popover.Content>
						</Popover>
					}
				>
					<p className="study-document-name">{studyDocName}</p>
				</OverlayTrigger>

				<h2 className="overview">{StaticLabels.Doc_Overview}</h2>
				<div id="subheader-section" className="subheader-section">
					{
						// headerNodes && Object.keys(headerNodes).map(key=>{
						// 	let node = headerNodes[key];
						// 	return `<${node.localName} class='${node.className}' id='${node.id}'>${node.innerHTML}</${node.localName}>`
						// })
					}
					{tblOfContent &&
						tblOfContent.map((node, index) => {
							return (
								<div
									key={`content${index}`}
									className={`subheader ${index === 0 ? 'active' : ''}`}
									dangerouslySetInnerHTML={{ __html: node }}
									onClick={(e) => scroll(e)}
								/>
							);
						})}
				</div>
			</div>
			<div className="doc-right-content" onScroll={(e) => scroll(e)}>
				{(fileContent || pdfFileContent) && (
					<DocumentRawContent
						docType={docType}
						fileContent={docType ? pdfFileContent : fileContent}
						{...props}
					/>
				)}{' '}
				{/* <DocumentContent fileContent={fileContent} /> */}
				<div className="scroll-top">
					<span className="scroll-to-top" onClick={scrollTop}>
						{StaticLabels.Doc_Scroll_Top}
					</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		studyDocumentList: state.redCommon.studyDocumentList
	};
};

export default connect(mapStateToProps)(DocumentContainer);

DocumentContainer.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			docid: PropTypes.string
		})
	}),
	studyDocumentList: PropTypes.array
};

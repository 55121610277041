import actionTypes from '../utils/actionTypes';

const initState = {
  success: false,
  userInfo: {},
  authMessage: '',
  organisation: '',
};

export const UserInfo = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATED:
      return{
        ...state,
        authMessage: action.payload,
      }

    case actionTypes.USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };

    case actionTypes.USER_ORGANISATION: 
      return{
        ...state,
        organisation : action.payload,
      }

    default:
      return state;
  }
};

export default UserInfo;

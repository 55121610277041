import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ModalComponent from '../../component/modal/ModalComponent';
import CustomSelect from '../../component/customselect/CustomSelect';
import { showError, showSuccess } from '../../utils/toaster';
import './addresearchpartner.scss';
import {
	addNewResearchPartner,
	getResearchPartnerTypes,
	updateResearchPartnerDetail,
	deleteResearchPartnerDetails
} from './addResearchPartnerHelper';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';
import ResearchPartnerDetail from './ResearchParterDetail';
import { showLoader } from '../../utils/commonUtils';
import CustomModalFooter from '../../component/modal/CustomModalFooter';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { connect } from 'react-redux';

function AddResearchPatner(props) {
	const [ key, setKey ] = useState('1');
	const [ researchPatnerDetail, setResearchPatnerDetail ] = useState({
		name: '',
		type: '',
		address: '',
		status: 'true',
		id: 0
	});
	const [ attributes, setAttributes ] = useState({
		senderId: '',
		sqsQueueUrl: '',
		uptimeAvailability: ''
	});
	// const { researchPartnerTypeList } = props;
	const [ errors, setError ] = useState([]);
	const [ researchPartnerType, setResearchPartnerType ] = useState();
	const [ apiError, setApiError ] = useState('');
	const [ researchPartnerTypes, setResearchPartnerTypes ] = useState([]);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const statusOption = [
		{
			value: 'true',
			label: 'Active'
		},
		{
			value: 'false',
			label: 'Inactive'
		}
	];
	const [ isChecked, setIsChecked ] = useState(
		props.researchPartnerInfo && props.researchPartnerInfo.attributes.UPTIME_AVAILABILITY === 'true' ? true : false
	);

	const getRPTypeList = () => {
		showLoader(true);
		getResearchPartnerTypes()
			.then((resp) => {
				showLoader(false);
				// const response = resp && resp.data.payload.sort();
				// const updateList = response.map((ctype, index) => {
				// 	return {
				// 		label: ctype,
				// 		value: ctype,
				// 		type: ctype,
				// 		status: true
				// 	};
				// });
				// setResearchPartnerTypes(updateList);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(
		() => {
			getRPTypeList();
			if (props.researchPartnerInfo) {
				setResearchPatnerDetail(props.researchPartnerInfo);
				setAttributes({
					senderId: props.researchPartnerInfo.attributes.S3_BUCKET_NAME,
					sqsQueueUrl: props.researchPartnerInfo.attributes.SQS_QUEUE_URL,
					uptimeAvailability: props.researchPartnerInfo.attributes.UPTIME_AVAILABILITY
				});
				setResearchPartnerType(props.researchPartnerInfo.type);
			}
		},
		[ props.addResearchPartner, props.researchPartnerInfo ]
	);

	useEffect(
		() => {
			if (props.researchPartnerTypeList.length > 0) {
				const response = props.researchPartnerTypeList.sort();
				const updateList = response.map((ctype, index) => {
					return {
						label: ctype,
						value: ctype,
						type: ctype,
						status: true
					};
				});
				setResearchPartnerTypes(updateList);
			}
		},
		[ props.researchPartnerTypeList ]
	);

	const updateTypeValue = (e) => {
		setResearchPartnerType(e.value);
		researchPatnerDetail.type = e.value;
		setError([]);
	};

	const updateValue = (e) => {
		const value = e.target.value;
		const targetName = e.target.name;
		if (targetName === 'senderId' || targetName === 'sqsQueueUrl') {
			setAttributes({
				...attributes,
				[e.target.name]: value
			});
		} else {
			setResearchPatnerDetail({
				...researchPatnerDetail,
				[e.target.name]: value
			});
		}
	};

	const resetResearchPartnerList = () => {
		props.handleClose();
		props.getResearchPartner();
		showLoader(false);
	};

	const onSave = (errorr) => {
		researchPatnerDetail.type = researchPartnerType;
		researchPatnerDetail.status = researchPatnerDetail.status.toString() === 'true' ? true : false;

		setResearchPatnerDetail(researchPatnerDetail);

		if (Object.keys(errorr).length === 0) {
			showLoader(true);
			if (props.researchPartnerInfo) {
				updateResearchPartnerDetail(researchPatnerDetail, attributes)
					.then(() => {
						resetResearchPartnerList();
						showSuccess(StaticLabels.Success_RP_Updated);
					})
					.catch((error) => {
						showLoader(false);
						console.log(error);
						setApiError(error.data.payload);
					});
			} else {
				addNewResearchPartner(researchPatnerDetail, attributes)
					.then((resp) => {
						resetResearchPartnerList();
						showSuccess(StaticLabels.Success_RP_Added);
					})
					.catch((error) => {
						showLoader(false);
						console.log(error);
						setApiError(error.data.payload);
					});
			}
		}
	};

	const handleNextClick = (newKey) => {
		const error = [];

		setError([]);
		setApiError();

		if (key === '1') {
			const { name, type } = researchPatnerDetail;

			if (!name.trim()) {
				error.push({ name: StaticLabels.Error_RP_Name });
			} else if (!type) {
				error.push({ name: StaticLabels.Error_RP_Type });
			}
		}

		setError(error);

		if (key === '1' && !props.researchPartnerInfo) {
			if (!error.length) {
				onSave(error);
			}
		} else if (props.researchPartnerInfo && key === '1') {
			if (!error.length) {
				setKey('2');
			}
		} else if (key === '2') {
			if (!error.length) {
				if (typeof newKey === 'string') {
					setKey(newKey);
				} else {
					onSave(error);
				}
			}
		}
	};

	const handleSelect = (newKey) => {
		handleNextClick(newKey);
	};
	
	const onStatusChange = (e) => {
		setResearchPatnerDetail({
			...researchPatnerDetail,
			status: e.value
		});
	};

	const removeResearchPartner = () => {
		setConfirmDelete(true);
	};

	const closeDeleteModal = () => {
		setConfirmDelete(false);
	};

	const deleteResearchPartner = (id) => () => {
		showLoader(true);
		deleteResearchPartnerDetails(id)
			.then((resp) => {
				resetResearchPartnerList();
				showError(StaticLabels.Success_RP_Deleted)
			})
			.catch((error) => {
				showLoader(false);
				if (error && error.response) {
					closeDeleteModal();
					if (error.response.status === 500) {
						setApiError(error.response.data.status);
					} else {
						setApiError(error.response.data.payload);
					}
				} else {
					setApiError(StaticLabels.Error_Common);
				}
			});
	};

	const onUptimeChange = () => {
			setIsChecked(!isChecked);
			setAttributes({
				...attributes,
				uptimeAvailability: !isChecked
			});
	}
	return (
		<ModalComponent
			open={props.open}
			handleClose={props.handleClose}
			centered
			dialogClassName={props.className ? props.className : 'setting-modals'}
			keyboard
			className="researchpartner-modal"
			header={props.researchPartnerInfo ? StaticLabels.MP_Edit_RP_Title : StaticLabels.MP_Add_RP_Title}
			content={
				<React.Fragment>
					<div className="modal-tabs">
						<Tabs
							// className="nav-pills nav-tabs-update"
							className={`nav-pills ${props.addResearchPartner ? 'nav-tabs-add' : 'nav-tabs-update'}`}
							activeKey={key}
							onSelect={handleSelect}
							id="controlled-tab-example"
						>
							<Tab eventKey={1} title={StaticLabels.MP_RP_Detail_Tab}>
								{' '}
								{
									<ResearchPartnerDetail
										onChange={updateValue}
										className="ResearchPartnerDetail"
										onSubmit={handleNextClick}
										errors={errors}
										researchPatnerDetail={researchPatnerDetail}
										attributes={attributes}
										researchPartnerTypes={researchPartnerTypes}
										onTypeChange={updateTypeValue}
										apiError={apiError}
									/>
								}{' '}
							</Tab>{' '}
							{props.researchPartnerInfo && (
								<Tab eventKey={2} title={StaticLabels.MP_RP_Setting_Tab}>
									<div className="section">
										<div className="section setting">
											<label id="rp-status">{StaticLabels.MP_RP_Status}</label>{' '}
											<CustomSelect
												className={'custom-status-select'}
												placeholder={StaticLabels.MP_RP_Status_Placeholder}
												options={statusOption}
												value={
													researchPatnerDetail.status.toString() === 'true' ? (
														{
															value: 'true',
															label: 'Active'
														}
													) : (
														{
															value: 'false',
															label: 'Inactive'
														}
													)
												}
												onChange={onStatusChange}
											/>{' '}
										</div>
										<div className="section setting">
											<label id="rp-uptime">{StaticLabels.MP_RP_UPTIME_REPORTING}</label>
											<span className="checkbox-contain">
												<input
													className="checkbox"
													id="check"
													name="check"
													onClick={onUptimeChange}
													onChange={onUptimeChange}
													type="checkbox"
													checked={isChecked}
												/>
											</span>
										</div>
										<div className="delete_field">
											<span className="deleteresearchpartner" onClick={removeResearchPartner}>
												{StaticLabels.MP_RP_Delete}
											</span>{' '}
										</div>
										{confirmDelete && (
											<ModalComponent
												open={true}
												header={StaticLabels.MP_Delete_Header}
												className="delete-modal"
												handleClose={closeDeleteModal}
												overlayClassName="delete-modal-overlay"
												content={
													<div>
														<p>{StaticLabels.MP_Delete_RP}</p>
														<p>{StaticLabels.MP_Delete_Warning}</p>
													</div>
												}
												footer={
													<React.Fragment>
														<ButtonNormal
															buttonType="1"
															className="buttons cancel-btn"
															onClick={closeDeleteModal}
															text={StaticLabels.MP_Delete_Cancel_Btn}
														/>
														<ButtonNormal
															buttonType="3"
															className="buttons delete-btn"
															onClick={deleteResearchPartner(
																props.researchPartnerInfo.id
															)}
															text={StaticLabels.MP_Delete_Btn}
														/>
													</React.Fragment>
												}
											/>
										)}
										{
											<div className="error_field" id="api-error">
												{' '}
												{apiError ? (
													<span>
														{' '}
														{apiError[0].message ? apiError[0].message : apiError}{' '}
													</span>
												) : null}{' '}
											</div>
										}{' '}
									</div>{' '}
								</Tab>
							)}{' '}
						</Tabs>{' '}
					</div>{' '}
				</React.Fragment>
			}
			footer={
				<CustomModalFooter
					tabKey={(props.addResearchPartner ? key === '1' : key === '2') ? true : false}
					handleClose={props.handleClose}
					handleNextClick={handleNextClick}
					Info={props.researchPartnerInfo}
				/>
			}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		researchPartnerTypeList: state.redCommon.researchPartnerTypeList
	};
};

export default connect(mapStateToProps)(AddResearchPatner);

AddResearchPatner.propTypes = {
	researchPartnerInfo: PropTypes.object,
	addResearchPartner: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	getResearchPartner: PropTypes.func,
	className: PropTypes.string,
	researchPartnerTypeList: PropTypes.array
};

import React, { useState, useEffect, useRef } from 'react';
import './sidebarcontainer.scss';
import verantosIcon from '../../images/Verantos_icon.svg';
import logoVerantos from '../../images/Verantoslogos.svg';
import logoSysAdmin from '../../images/Sys_adm_icon_inactive.svg';
import logoSysAdminActive from '../../images/Sys_adm_icon_active.svg';
import logoDataAdminActive from '../../images/Data_adm_icon_active.svg';
import logoDataAdminInactive from '../../images/Data_adm_icon_inactive.svg';
import logoDocumentActive from '../../images/document_active.svg';
import logoDocumentInactive from '../../images/document_inactive.svg';
import logoBellIcon from '../../images/notification_bell_blank.svg';
import logoBellIconActive from '../../images/notification_bell.svg';
import collapseIcon from '../../images/collapse-icon.svg';
import homeIcon from '../../images/home.svg';
import { Link } from 'react-router-dom';
import {
	getUserInfo,
	getRolesList,
	getActiveDataset,
	getStudyDocumentList,
	setStudyDocumentList,
	getUnreadNotification,
	getNotificationList,
	setReadNotification,
	setActiveNotification
} from './sidebarHelper';
import { connect } from 'react-redux';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';
import { AUTH_CONFIG } from '../../constants';
import auth0 from 'auth0-js';
import { getRoleKeys } from '../../utils/commonUtils';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { showToast, updateToast } from '../../utils/toaster';
import { getNotificationListForRP, setToastId } from '../../utils/commonUtils';
import { toast } from 'react-toastify';

const SidebarContainer = (props) => {
	const {
		loggedInUser,
		availableDataset,
		notificationList,
		unreadNotificationCount,
		activeNotification,
		listenChange,
		studyDocumentList,
		notifiList
	} = props;
	const menuState = (sessionStorage.getItem('collapse') && sessionStorage.getItem('collapse').toString()) || '';
	// const viewState = (sessionStorage.getItem('SysAdmin') && sessionStorage.getItem('SysAdmin').toString()) || '';
	const [ isUserList, setIsUserList ] = useState(false);
	const [ isCustomerList, setIsCustomerList ] = useState(false);
	const [ isResearchList, setIsResearchList ] = useState(false);
	const [ isAuditReportList, setIsAuditReportList ] = useState(false);
	const [ isVirtualApplianceMonitor, setIsVirtualApplianceMonitor ] = useState(false);
	const [ isNotificationList, setIsNotificationList ] = useState(false);
	const [ isDocumentation, setIsDocumentation ] = useState(false);
	const [ collapsed, setCollapsed ] = useState(menuState === 'true' ? true : false);
	// const [ SysAdmin, setSysAdmin ] = useState(viewState === 'true' ? true : false);
	const [ endOfList, setEndofList ] = useState(false);
	const [ endOfDocList, setEndOfDocList ] = useState(false);
	const refMainMenu = useRef();

	const datasetId = availableDataset.length > 0 ? availableDataset[0].id : 0;
	const activeDataset = availableDataset;
	const userRoles = getRoleKeys(loggedInUser.user_roles);
	const isDAAdmin = userRoles.indexOf('ROLE_DADMIN') !== -1 ? true : false;
	const isAdmin = userRoles.indexOf('ROLE_ADMIN') !== -1 ? true : false;
	const isRoot = userRoles.indexOf('ROLE_ROOT') !== -1 ? true : false;
	// const isResearchPartnerUser = userRoles.indexOf('ROLE_RESEARCH_PARTNER_USER') !== -1 ? true : false;
	const topLevelNavigation = (sessionStorage.getItem('Role') && sessionStorage.getItem('Role').toString()) || '';

	const logoutUser = () => {
		toast.dismiss();

		sessionStorage.removeItem('accessToken');
		const webAuth = new auth0.WebAuth({
			domain: AUTH_CONFIG.domainName,
			clientID: AUTH_CONFIG.clientID //eslint-disable-line
		});

		webAuth.logout({
			returnTo: AUTH_CONFIG.returnTo,
			client_id: AUTH_CONFIG.clientID //eslint-disable-line
		});
	};

	const [ activeRP, setActiveRP ] = useState(0);
	const [ activeDoc, setActiveDoc ] = useState('');
	const toggleDocId = (sessionStorage.getItem('toggleStudyDocId') && sessionStorage.getItem('toggleStudyDocId')) || 0;
	const [ toggleStudyDocId, setToggleStudyDocId ] = useState(parseInt(toggleDocId));

	const isInitialNotif = useRef(true);

	const toastId = useRef(null);

	const pageNo = 1;
	const pageSize = 10;

	const getUnreadNotiCount = () => {
		getUnreadNotification().then((resp) => {
			const response = resp.data.payload;

			if (response.length > 0) {
				if (response.length === 1 && response[0].researchPartnerId.toString() === props.match.params.id) {
					setActiveNotification(false);
				} else {
					setActiveNotification(true);
				}
			} else {
				setActiveNotification(false);
			}
		});
	};

	const onClickToast = () => {
		getNotificationListForRP([]);
		toastId.current = '';
		toast.dismiss();
	};

	useEffect(() => {
		onClickToast(); //reset notificationlist for previous RP

		getUserInfo();
		getRolesList();
		getActiveDataset().then((resp) => {
			//  setTimeout(() => {
			// 	var element = document.getElementById(`rd_${activeRP}`);
			// 	if(element){
			// 		element.scrollIntoView();
			// 	}
			//  }, 1000);
		});

		// getStudyDocumentList()
		// 	.then((resp) => {
		// 		const updatedresp = resp.data.payload.map((res) => {
		// 			return {
		// 				studyName: res.studyName,
		// 				studyId: res.studyId,
		// 				documentList: [
		// 					{
		// 						versionId: res.versionId,
		// 						name: res.name,
		// 						templateId: res.templateId
		// 					}
		// 				]
		// 			};
		// 		});
		// 		const mergedResponse = updatedresp.reduce((obj, item) => {
		// 			obj[item.studyId]
		// 				? obj[item.studyId].documentList.push(...item.documentList)
		// 				: (obj[item.studyId] = { ...item });
		// 			return obj;
		// 		}, {});
		// 		const mergedResponseArray = Object.values(mergedResponse);
		// 		setStudyDocumentList(mergedResponseArray);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	}, []);

	useEffect(
		() => {
			getUnreadNotiCount();

			const url = window.location.pathname;
			if (url.indexOf('/sysadmin/') !== -1) {
				sessionStorage.setItem('Role', 'SysAdmin');
			} else if (url.indexOf('/dataadmin') !== -1 || url.indexOf('/researchnetworkdetail') !== -1) {
				sessionStorage.setItem('Role', 'DataAdmin');
			} else if (url.indexOf('/study') !== -1) {
				sessionStorage.setItem('Role', 'ObservationalResearch');
			} else if (url.indexOf('/documentation') !== -1) {
				sessionStorage.setItem('Role', 'KnowledgeBase');
			}

			if (url.indexOf('/sysadmin/userlist') !== -1) {
				setIsUserList(true);
			} else if (url.indexOf('/sysadmin/customerlist') !== -1) {
				setIsCustomerList(true);
			} else if (url.indexOf('/researchnetworkdetail') !== -1) {
				const rpId = props.match.params.rp_id;
				setActiveRP(rpId);
				// setTimeout(() => {
				// 	var element = document.getElementById(`rd_${rpId}`);
				// 	element.scrollIntoView();
				// }, 2000);
			} else if (url.indexOf('/sysadmin/research') !== -1) {
				setIsResearchList(true);
			} else if (url.indexOf('/sysadmin/auditreport') !== -1) {
				setIsAuditReportList(true);
			} else if (url.indexOf('/dataadmin') !== -1) {
				const rpId = props.match.params.id;
				setActiveRP(rpId);
				// setTimeout(() => {
				// 	var element = document.getElementById(`rd_${rpId}`);
				// 	element.scrollIntoView();
				// }, 2000);
			} else if (url.indexOf('/virtualappliancemonitor') !== -1) {
				setIsVirtualApplianceMonitor(true);
			} else if (url.indexOf('/notification') !== -1) {
				setIsNotificationList(true);
			} else if (url.indexOf('/documentation') !== -1) {
				setIsDocumentation(true);
				const docId = props.match.params.docid;
				setActiveDoc(docId);
			}
		},
		[ datasetId, activeNotification, activeRP, toggleStudyDocId ]
	);

	useEffect(
		() => {
			setTimeout(() => {
				var element = document.getElementById(`rd_${activeRP}`) || document.getElementById(`doc_${activeDoc}`);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}, 500);
		},
		[ activeRP, activeDoc ]
	);

	useEffect(
		() => {
			const url = window.location.pathname;
			if (url.indexOf('/dataadmin') !== -1 && url.indexOf('/dataadmin/') === -1) {
				props.history.push(`/dataadmin/${datasetId}`);
			} else {
				toast.dismiss();
			}
		},
		[ datasetId ]
	);

	useEffect(
		() => {
			if (activeRP !== 0) {
				getNotificationList(activeRP, pageNo, pageSize).then((resp) => {
					const notificationIds = [];
					resp.data.payload.map((list) => {
						if (list.readByUser === false) {
							notificationIds.push(list.id);
						}
					});
					unreadNotificationCount.map((unread) => {
						if (unread.researchPartnerId.toString() === props.match.params.id) {
							notificationIds && setReadNotification(notificationIds.join(','));
						} else {
							return;
						}
					});
				});
				getUnreadNotiCount();
			}
		},
		[ listenChange ]
	);

	useEffect(
		() => {
			const notificationIds = [];
			notifiList.map((list) => {
				if (list.readByUser === false && list.researchPartnerId.toString() === props.match.params.id) {
					notificationIds.push(list.id);
				}
			});
			notificationIds.length && setReadNotification(notificationIds.join(','));
		},
		[ notifiList, notificationList ]
	);
	
	useEffect(
		() => {
			setToastId(toastId);
			const url = window.location.pathname;
			if (
				activeRP !== 0 &&
				(url.indexOf('/dataadmin/') !== -1 || url.indexOf('/researchnetworkdetail/') !== -1) &&
				notificationList.length
			) {
				if (isInitialNotif.current) {
					isInitialNotif.current = false;
					showToast(notificationList, toastId, onClickToast);
				} else {
					if (notificationList.length === 1) {
						isInitialNotif.current = false;
						showToast(notificationList, toastId, onClickToast);
					} else {
						updateToast(notificationList, props.toastId, onClickToast);
					}
				}
			}
		},
		[ notificationList ]
	);
	const toggleCollapse = () => {
		sessionStorage.setItem('collapse', !collapsed);
		setCollapsed(!collapsed);
	};

	const toggleRoles = (role) => () => {
		if (role === 'SysAdmin') {
			sessionStorage.setItem('Role', 'SysAdmin');
			props.history.push('/sysadmin/userlist');
		} else if (role === 'DataAdmin') {
			sessionStorage.setItem('Role', 'DataAdmin');
			props.history.push(`/dataadmin/${datasetId}`);
		} else if (role === 'KnowledgeBase') {
			sessionStorage.setItem('Role', 'KnowledgeBase');
			props.history.push(`/documentation/template/${studyDocumentList[0]?.documentList[0].templateId}`);
			sessionStorage.setItem('toggleStudyDocId', studyDocumentList[0]?.studyId);
		} else if (role === 'ObservationalResearch') {
			sessionStorage.setItem('Role', 'ObservationalResearch');
			props.history.push('/study/1');
		}
	};

	const handleScroll = (event) => {
		const element = event.target;
		const scrolledPercent = (element.scrollTop + element.clientHeight) / element.scrollHeight * 100;

		if (element.classList.contains('document-list')) {
			if (scrolledPercent > 90) {
				setEndOfDocList(true);
			} else {
				setEndOfDocList(false);
			}
		} else {
			if (scrolledPercent > 90) {
				setEndofList(true);
			} else {
				setEndofList(false);
			}
		}
	};

	const tooltip = (prop) => {
		return (
			<Popover bsPrefix="popover">
				<Popover.Content>{prop}</Popover.Content>
			</Popover>
		);
	};

	const getLogoText = () => {
		let logoText = '';
		switch (topLevelNavigation) {
			case 'SysAdmin':
				logoText = StaticLabels.Sidenav_Logo_SA_Text;
				break;
			case 'DataAdmin':
				logoText = StaticLabels.Sidenav_Logo_DA_Text;
				break;
			case 'KnowledgeBase':
				logoText = StaticLabels.Sidenav_Logo_KB_Text;
				break;
			case 'ObservationalResearch':
				logoText = StaticLabels.Sidenav_Logo_OS_Text;
				break;
		}
		return logoText;
	};

	const toggleDocument = (id) => {
		sessionStorage.setItem('toggleStudyDocId', id);
		if (toggleStudyDocId === id) {
			setToggleStudyDocId(0);
		} else {
			setToggleStudyDocId(id);
		}
	};
	return (
		<React.Fragment>
			<nav ref={refMainMenu} className={`main-menu ${collapsed ? 'collapsed' : 'open'} `}>
				<div className="left-col">
					<div>
						<img
							className="logo-icon ver-logo"
							src={verantosIcon}
							alt={StaticLabels.Sidenav_DA_Icon_Text}
						/>
					</div>
					{isRoot && (
						<React.Fragment>
							<div>
								<OverlayTrigger
									trigger={[ 'hover', 'focus' ]}
									rootClose={true}
									placement="top"
									overlay={tooltip(StaticLabels.Sidenav_DA)}
								>
									<img
										onClick={toggleRoles('DataAdmin')}
										className="logo-icon data-admin"
										src={
											topLevelNavigation === 'DataAdmin' ? (
												logoDataAdminActive
											) : (
												logoDataAdminInactive
											)
										}
										alt={StaticLabels.Sidenav_DA_Icon_Text}
									/>
								</OverlayTrigger>
							</div>

							<div>
								<OverlayTrigger
									trigger={[ 'hover', 'focus' ]}
									rootClose={true}
									placement="top"
									overlay={tooltip(StaticLabels.Sidenav_SA)}
								>
									<img
										onClick={toggleRoles('SysAdmin')}
										className="logo-icon sys-admin"
										src={topLevelNavigation === 'SysAdmin' ? logoSysAdminActive : logoSysAdmin}
										alt={StaticLabels.Sidenav_SA_Icon_Text}
									/>
								</OverlayTrigger>
							</div>
							{
								// 	<div>
								// 	<OverlayTrigger
								// 		trigger={[ 'hover', 'focus' ]}
								// 		rootClose={true}
								// 		placement="top"
								// 		overlay={tooltip('Observational research')}
								// 	>
								// 		<img
								// 			onClick={toggleRoles('ObservationalResearch')}
								// 			className="logo-icon"
								// 			src={topLevelNavigation === 'ObservationalResearch' ? logoSysAdminActive : logoSysAdmin}
								// 			alt={StaticLabels.Sidenav_SA_Icon_Text}
								// 		/>
								// 	</OverlayTrigger>
								// </div>
							}
						</React.Fragment>
					)}
					{!isRoot && (
						<React.Fragment>
							{isAdmin && (
								<div>
									<OverlayTrigger
										trigger={[ 'hover', 'focus' ]}
										rootClose={true}
										placement="top"
										overlay={tooltip(StaticLabels.Sidenav_SA)}
									>
										<img
											onClick={toggleRoles('SysAdmin')}
											className="logo-icon sys-admin"
											src={topLevelNavigation === 'SysAdmin' ? logoSysAdminActive : logoSysAdmin}
											alt={StaticLabels.Sidenav_SA_Icon_Text}
										/>
									</OverlayTrigger>
								</div>
							)}
							{isDAAdmin && (
								<div>
									<OverlayTrigger
										trigger={[ 'hover', 'focus' ]}
										rootClose={true}
										placement="top"
										overlay={tooltip(StaticLabels.Sidenav_DA)}
									>
										<img
											onClick={toggleRoles('DataAdmin')}
											className="logo-icon data-admin"
											src={
												topLevelNavigation === 'DataAdmin' ? (
													logoDataAdminActive
												) : (
													logoDataAdminInactive
												)
											}
											alt={StaticLabels.Sidenav_DA_Icon_Text}
										/>
									</OverlayTrigger>
								</div>
							)}
						</React.Fragment>
					)}
					{
						// <div>
						// <OverlayTrigger
						// 	trigger={[ 'hover', 'focus' ]}
						// 	rootClose={true}
						// 	placement="top"
						// 	overlay={tooltip(StaticLabels.Sidenav_Doc)}
						// >
						// 	<img
						// 		onClick={toggleRoles('KnowledgeBase')}
						// 		className="logo-icon kn-base"
						// 		src={topLevelNavigation === 'KnowledgeBase' ? logoDocumentActive : logoDocumentInactive}
						// 		alt={StaticLabels.Sidenav_KB_Icon_Text}
						// 	/>
						// </OverlayTrigger>
						// </div>
					}
				</div>
				<div className="right-col">
					<div className="logo-container">
						{
							//  <img className="logo_icon" src={logoIcon} alt="Logo icon" />
						}
						<div className="logo-with-text">
							<img className="logo_img" src={logoVerantos} alt="Verantos logo" />
							{/* <span className="logo-text">{getLogoText()}</span> */}
						</div>
					</div>
					<div className={'collapse-icon'} onClick={toggleCollapse}>
						{/* <i className="fas fa-angle-left arrow-left" /> */}
						<img
								className="collapse-img"
								src={collapseIcon}
							/>
					</div>
					{(isRoot || isAdmin) &&
					topLevelNavigation === 'SysAdmin' && (
						<ul className="nav-links">
							<li className={`list ${isUserList ? 'active' : ''}`}>
								<Link to={'/sysadmin/userlist'} onClick={props.toggleStateMethod} tabIndex="-1">
									<span className="nav-text">{StaticLabels.Sidenav_Users}</span>
								</Link>
							</li>
							<li className={`list ${isCustomerList ? 'active' : ''}`}>
								<Link to={'/sysadmin/customerlist'} onClick={props.toggleStateMethod} tabIndex="-1">
									<span className="nav-text">{StaticLabels.Sidenav_Customers}</span>
								</Link>
							</li>
							<li className={`list ${isResearchList ? 'active' : ''}`}>
								<Link to={'/sysadmin/research'} onClick={props.toggleStateMethod} tabIndex="-1">
									<span className="nav-text">{StaticLabels.Sidenav_ResearchPartner}</span>
								</Link>
							</li>
							<li className={`list ${isAuditReportList ? 'active' : ''}`}>
								<Link to={'/sysadmin/auditreport'} onClick={props.toggleStateMethod} tabIndex="-1">
									<span className="nav-text">{StaticLabels.Sidenav_AuditReport}</span>
								</Link>
							</li>
						</ul>
					)}
					{(isDAAdmin || isRoot) &&
					topLevelNavigation === 'DataAdmin' && (
						<ul
							className={`${endOfList ? 'nav-links-dadmin-scroll' : 'nav-links-dadmin'}`}
							onScroll={(e) => handleScroll(e)}
						>
							{activeDataset &&
								activeDataset.map((active) => {
									const unreadCount = unreadNotificationCount.filter((noti) => {
										return active.id === noti.researchPartnerId;
									});
									return (
										<li
											id={`rd_${active.id}`}
											className={`list dataset-list ${active.id.toString() === activeRP
												? 'active'
												: ''} `}
											key={active.id}
										>
											<OverlayTrigger
												trigger={[ 'hover', 'focus' ]}
												rootClose={true}
												id="email-popup"
												placement="top"
												overlay={
													<Popover bsPrefix="email-popover popover">
														<Popover.Content className="email-popover-content">
															<span>
																<span className="dataset-element">{active.name}</span>
															</span>
														</Popover.Content>
													</Popover>
												}
											>
												<Link key={active.id} to={`/dataadmin/${active.id}`} tabIndex="-1">
													<span className="dataset-element">{active.name}</span>

													{// 	active.available_dataset_count > 0 && (
													// 	<span className="badge">{active.available_dataset_count}</span>
													// )
													unreadCount.length > 0 &&
													unreadCount[0].unreadCount > 0 &&
													active.id.toString() !== activeRP && (
														<span className="badge">{unreadCount[0].unreadCount}</span>
													)}
												</Link>
											</OverlayTrigger>
										</li>
									);
								})}
						</ul>
					)}
					{
					// topLevelNavigation === 'KnowledgeBase' && (
					// 	<ul
					// 		className={`document-list ${endOfDocList ? 'document-list-scroll' : ''}`}
					// 		onScroll={(e) => handleScroll(e)}
					// 	>
					// 		{studyDocumentList.map((doc, indx) => {
					// 			let docIfActiveForStudy = [];
					// 			docIfActiveForStudy = doc.documentList.filter((list) => activeDoc === list.templateId);
					// 			return (
					// 				<li
					// 					key={indx}
					// 					className={`list document-item ${isDocumentation && docIfActiveForStudy.length
					// 						? 'active'
					// 						: ''}`}
					// 				>
					// 					<a
					// 						className="study-title"
					// 						onClick={() => toggleDocument(doc.studyId)}
					// 						tabIndex="-1"
					// 					>
					// 						<span className="nav-text text-capitalize dataset-element">
					// 							{doc.studyName}
					// 						</span>
					// 						<i
					// 							className={`fas caret-icon ${doc.studyId === toggleStudyDocId
					// 								? 'fa-caret-up'
					// 								: 'fa-caret-down'}`}
					// 						/>
					// 					</a>

					// 					{doc.studyId === toggleStudyDocId && (
					// 						<ul className="document-subList">
					// 							{doc.documentList.map((doclist, index) => {
					// 								return (
					// 									<li
					// 										key={index}
					// 										className={`sublist-element ${doclist.templateId ===
					// 										activeDoc
					// 											? 'active'
					// 											: ''}`}
					// 									>
					// 										<OverlayTrigger
					// 											trigger={[ 'hover', 'focus' ]}
					// 											rootClose={true}
					// 											id="email-popup"
					// 											placement="top"
					// 											overlay={
					// 												<Popover bsPrefix="email-popover popover">
					// 													<Popover.Content className="email-popover-content">
					// 														<span>
					// 															<span className="dataset-element">
					// 																{doclist.name}
					// 															</span>
					// 														</span>
					// 													</Popover.Content>
					// 												</Popover>
					// 											}
					// 										>
					// 											<Link
					// 												key={`${doclist.templateId}_${index}}`}
					// 												to={`/documentation/template/${doclist.templateId}`}
					// 												onClick={props.toggleStateMethod}
					// 												tabIndex="-1"
					// 											>
					// 												<span className="dataset-element">
					// 													{doclist.name[0].toUpperCase() +
					// 														doclist.name.slice(1)}
					// 												</span>
					// 											</Link>
					// 										</OverlayTrigger>
					// 									</li>
					// 								);
					// 							})}
					// 						</ul>
					// 					)}
					// 				</li>
					// 			);
					// 		})}
					// 	</ul>
					// )
					}
					{
						// 	topLevelNavigation === 'ObservationalResearch' && (
						// 	<ul className="nav-links">
						// 		<li className="list active">
						// 			<Link to={'/study/1'} onClick={props.toggleStateMethod} tabIndex="-1">
						// 				<span className="nav-text">{'Study 1'}</span>
						// 			</Link>
						// 		</li>
						// 		<li className="list">
						// 			<Link to={'/study/2'} onClick={props.toggleStateMethod} tabIndex="-1">
						// 				<span className="nav-text">{'Study 2'}</span>
						// 			</Link>
						// 		</li>
						// 		<li className="list">
						// 			<Link to={'/study/3'} onClick={props.toggleStateMethod} tabIndex="-1">
						// 				<span className="nav-text">{'Study 3'}</span>
						// 			</Link>
						// 		</li>
						// 	</ul>
						// )
					}
					<ul className="profile">
						<div className={` ${collapsed ? 'profile-content-onCollapsed' : 'profile-content'}`}>
							{topLevelNavigation === 'DataAdmin' && (
								<React.Fragment>
									{
										// <li
										// 	className={`virtual-app ${isVirtualApplianceMonitor
										// 		? 'active'
										// 		: ''}`}
										// >
										// 	<Link
										// 		to={'/virtualappliancemonitor'}
										// 		onClick={props.toggleStateMethod}
										// 		tabIndex="-1"
										// 	>
										// 		<span className="nav-text text-capitalize">{StaticLabels.Sidenav_VA}</span>
										// 	</Link>
										// </li>
									}
									<li className={`notification ${isNotificationList ? 'active' : ''}`}>
										<Link to={'/notification'} onClick={props.toggleStateMethod} tabIndex="-1">
											<img
												src={activeNotification ? logoBellIconActive : logoBellIcon}
												className="notification-icon"
												alt="notification icon"
											/>
											<span className="nav-text text-capitalize">
												{StaticLabels.Sidenav_Notification}
											</span>
										</Link>
									</li>
								</React.Fragment>
							)}
							<li className="profileInfo">
								<Link to={'/userprofile'} onClick={props.toggleStateMethod} tabIndex="-1">
									{/* <i className="fa fa-home fa-2x menu-icon" /> */}
									<img
										src={homeIcon}
										className="home-icon"
									/>
									<span className="nav-text text-capitalize">
										{StaticLabels.Sidenav_Greeting} {loggedInUser.firstName || ''}
									</span>
								</Link>
							</li>
							<li className="profileInfo">
								<Link to="/logout" id="logout-user" onClick={logoutUser} tabIndex="-1">
									{<i className="fa fa-power-off menu-icon logout" />}
									<span className="nav-text logout">{StaticLabels.Logout_Btn}</span>
								</Link>
							</li>
						</div>
					</ul>
				</div>
			</nav>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		availableDataset: state.redCommon.availableDataset,
		documentList: state.redCommon.documentList,
		notificationList: state.redCommon.notificationList,
		toastId: state.redCommon.toastId,
		unreadNotificationCount: state.redCommon.unreadNotificationCount,
		activeNotification: state.redCommon.activeNotification,
		listenChange: state.redCommon.listenChange,
		studyDocumentList: state.redCommon.studyDocumentList,
		notifiList: state.redCommon.notifiList
	};
};

export default connect(mapStateToProps)(SidebarContainer);

SidebarContainer.propTypes = {
	loggedInUser: PropTypes.object,
	availableDataset: PropTypes.array,
	documentList: PropTypes.array,
	history: PropTypes.object,
	toggleStateMethod: PropTypes.func,
	match: PropTypes.shape({
		params: PropTypes.shape({
			rp_id: PropTypes.any, //eslint-disable-line
			docid: PropTypes.any,
			id: PropTypes.any
		})
	}),
	notificationList: PropTypes.array,
	toastId: PropTypes.object,
	unreadNotificationCount: PropTypes.array,
	activeNotification: PropTypes.bool,
	listenChange: PropTypes.bool,
	studyDocumentList: PropTypes.array,
	notifiList: PropTypes.array
};

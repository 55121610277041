import store from '../../store';
import actionType from '../../utils/actionTypes';
import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const getUserInfo = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetLoggedInUserInfo, {})
			.then((resp) => {
				const options = {
					type: actionType.USER_INFO,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getRolesList = (option) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetRoles, {}, option)
			.then((resp) => {
				const options = {
					type: actionType.ROLE_LIST,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getActiveDataset = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetActiveDataset, {})
			.then((resp) => {
				const options = {
					type: actionType.AVAILABLE_DATA_SET,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getStudyDocumentList = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetStudyDocumentList, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const setStudyDocumentList = (docList) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.STUDY_DOCUMENT_LIST,
			payload: docList
		};
		store.dispatch(options);
		resolve()
	});
}
export const listenChange = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.LISTEN_CHANGE,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const getUpdatedDataset = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.UPDATED_DATASET,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const acquisitionProgressData = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.ACQUISITION_PROGRESS_DATA,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const studyProgressChange = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.STUDY_PROGRESS_CHANGE,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const getUnreadNotification = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetUnreadNotification, {})
			.then((resp) => {
				// resolve(resp);
				const options = {
					type: actionType.UNREAD_NOTIFICATION_COUNT,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getNotificationList = (researchPartnerId, pageNo, recordsPerPage) => {
	return new Promise((resolve, reject) => {
		const option = {
			researchPartnerId: researchPartnerId,
			pageNumber: pageNo,
			pageSize: recordsPerPage
		};
		apiGet(apiUrl.GetNotificationList, option)
			.then((resp) => {
				const options = {
					type: actionType.NOTIFI_LIST,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const setReadNotification = (notificationIds) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.SetReadNotification, { notificationIds: notificationIds })
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const setActiveNotification = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.ACTIVE_NOTIFICATION,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

/* eslint-disable */
const StaticLabels = {
	/*******************************************************
		Abbreviation used in this file
		MP - Modal Popup
		RP - Research Partner
		Btn - Button
		Theader - Table Header
		FP - Forgot Password
		AR - Audit Report
		UP - User Profile
		DS - Data Set
		SIG - Signature
		VA - Virtual appliance
	****************************************************** */

	//Login container
	Login: 'Login',
	Login_Email_Placeholder: 'Email',
	Login_Password_Placeholder: 'Password',
	Login_Btn: 'Login',
	Login_Forgot_Password: 'Forgot password?',
	Login_Error_Email: 'Email is required.',
	Login_Error_ValidEmail: 'Provide a valid email.',
	Login_Error_Password: 'Password is required.',
	Login_Blocked_User: 'Blocked user.',
	Login_Error_Inactive_Account: 'Incorrect email or password.',

	//Forgot password container
	Forgot_Passord: 'Forgot password?',
	FP_Email_Placeholder: 'Email',
	FP_Info_text: 'Please enter your email address. We will send you an email with a link to reset your password.',
	FP_Btn: 'Submit',
	FP_Back_Login: 'Back to login',
	FP_Error_Email: 'Email is required.',
	FP_Error_ValidEmail: 'Provide a valid email.',
	FP_Success: 'An email has been sent with a link to reset your password.',

	//MFA conatiner
	Verification_Code: 'Verification Code',
	MFA_Info_Text: 'Enter the verification code sent to: 000-000-0000',
	MFA_Resend: 'Resend code',
	MFA_Clear_Input: 'Clear',
	MFA_Btn: 'LOGIN',

	//Header
	Search_Placeholder: 'Search name',
	Logout_Btn: 'Logout',
	//Side Nav constants
	Sidenav_Logo_SA_Text: 'SYSTEM ADMINISTRATION',
	Sidenav_Logo_DA_Text: 'RESEARCH NETWORK',
	Sidenav_Logo_KB_Text: 'KNOWLEDGE BASE',
	Sidenav_Logo_OS_Text: 'OBSERVATIONAL RESEARCH',

	Sidenav_SA_Icon_Text: 'System administration logo',
	Sidenav_DA_Icon_Text: 'Data administration logo',
	Sidenav_KB_Icon_Text: 'Knowledge base logo',

	Sidenav_Users: 'Users',
	Sidenav_Customers: 'Customers',
	Sidenav_ResearchPartner: 'Research partners',
	Sidenav_AuditReport: 'Audit reports',
	Sidenav_Documentation: 'Documentation',
	Sidenav_Greeting: 'Hi, ',
	Sidenav_VA: 'Virtual Appliance Monitor',
	Sidenav_Notification: 'Notifications',
	Sidenav_SA: 'System administration',
	Sidenav_DA: 'Research network',
	Sidenav_Doc: 'Knowledge base',

	// User Table header
	User_Theader_Status: 'Status',
	User_Theader_Name: 'Name',
	User_Theader_Email: 'Email',
	User_Theader_Phone: 'Phone',
	User_Theader_Organization: 'Organization',
	User_Theader_Roles: 'Role(s)',
	User_Theader_Date: 'Creation Date',
	// Customer Table Header
	Cust_Theader_Status: 'Status',
	Cust_Theader_Name: 'Name',
	Cust_Theader_Type: 'Type',
	Cust_Theader_Address: 'Address',
	Cust_Theader_Date: 'Creation Date',
	// Research Partner Table Header
	RP_Theader_Status: 'Status',
	RP_Theader_Name: 'Name',
	RP_Theader_Type: 'Type',
	RP_Theader_Address: 'Address',
	RP_Theader_Date: 'Creation Date',

	//User list
	Userlist_Title: 'Users',
	User_AddUser_Btn: 'Add new user',
	// Add/Edit User Modal popup tabs
	MP_User_Detail_Tab: 'Details',
	MP_User_Organization_Tab: 'Organization',
	MP_User_Role_Tab: 'Role',
	MP_User_Setting_Tab: 'Settings',
	MP_User_Cancel_Btn: 'Cancel',
	MP_User_Next_Btn: 'Next',
	MP_User_Save_Btn: 'Save',
	// Add/Edit User popup labels
	MP_Add_User_Title: 'Add user',
	MP_Edit_User_Title: 'Edit user',
	MP_User_First_Name: 'First name',
	MP_User_Last_Name: 'Last name',
	MP_User_Email: 'Email',
	MP_User_Phone: 'Phone',
	MP_User_Organization: 'Organization',
	MP_User_Organization_Placeholder: 'Select',
	MP_User_Role: 'Select role',
	MP_User_E_Signature: 'E-signature authorization',
	MP_User_Password: 'Password',
	MP_User_Status: 'User status',
	MP_User_Password_Btn: 'Reset password',
	MP_User_Delete: 'Delete user',

	//Customer list
	Customer_Title: 'Customers',
	Customer_AddCustomer_Btn: 'Add new customer',
	// Add/Edit Customer Modal popup tabs
	MP_Customer_Detail_Tab: 'Details',
	MP_Customer_Setting_Tab: 'Settings',
	MP_Customer_Cancel_Btn: 'Cancel',
	MP_Customer_Next_Btn: 'Next',
	MP_Customer_Save_Btn: 'Save',
	// Add/Edit Customer popup labels
	MP_Add_Customer_Title: 'Add customer',
	MP_Edit_Customer_Title: 'Edit customer',
	MP_Customer_Name: 'Customer Name',
	MP_Customer_Type: 'Customer type',
	MP_Customer_Type_Placeholder: 'Select',
	MP_Customer_Status: 'Customer status',
	MP_Customer_Status_Placeholder: 'Select',
	MP_Customer_Delete: 'Delete customer',
	MP_Customer_Address: 'Address',

	//Research Partner list
	ResearchPartner_title: 'Research partners',
	RP_AddResearchP_Btn: 'Add new research partner',
	// Add/Edit Research Partner Modal popup tabs
	MP_RP_Detail_Tab: 'Details',
	MP_RP_Setting_Tab: 'Settings',
	MP_RP_Cancel_Btn: 'Cancel',
	MP_RP_Next_Btn: 'Next',
	MP_RP_Save_Btn: 'Save',
	// Add/Edit Research Partner popup labels
	MP_Add_RP_Title: 'Add research partner',
	MP_Edit_RP_Title: 'Edit research partner',
	MP_RP_Name: 'Research partner name',
	MP_RP_Type: 'Research partner type',
	MR_RP_Senderid: 'Sender id',
	MR_RP_SQS: 'Sqs queue url',
	MP_RP_Type_Placeholder: 'Select',
	MP_RP_Status: 'Research partner status',
	MP_RP_Status_Placeholder: 'Select',
	MP_RP_UPTIME_REPORTING: 'Uptime reporting',
	MP_RP_Delete: 'Delete research partner',
	MP_RP_Address: 'Address',

	// Audit Report
	AR_Title: 'Audit reports',
	AR_Subtitle: 'Generate audit report',
	AR_StartDate: 'Start date',
	AR_EndDate: 'End date',
	AR_Date_Mandatory: 'Start and end date are mandatory',
	AR_Button: 'Generate report',
	AR_Table_Title: 'Reports',
	AR_Refresh_List: 'Refresh list',
	AR_Date_Requested: 'Date requested',
	AR_Status: 'Status',
	AR_Date_Range: 'Report date range',
	AR_Last_Download: 'Last downloaded',
	AR_Download_Report: 'Download report',

	//User Profile
	UP_Title: 'Account',
	UP_Details: 'Details',
	UP_Fname: 'First name',
	UP_Lname: 'Last name',
	UP_Email: 'Email',
	UP_Phone: 'Phone',
	UP_Organization: 'Organization',
	UP_Role: 'Role',
	UP_Password: 'Password',
	UP_Password_Btn: 'Reset password',
	UP_Cancel_Btn: 'Cancel',
	UP_Save_Btn: 'Save',

	//Delete confirmation MP
	MP_Delete_Header: 'Are you sure?',
	MP_Delete_User: 'Do you really want to delete this user?',
	MP_Delete_Customer: 'Do you really want to delete this customer?',
	MP_Delete_RP: 'Do you really want to delete this research partner?',
	MP_Delete_Warning: 'This action cannot be undone.',
	MP_Delete_Cancel_Btn: 'Cancel',
	MP_Delete_Btn: 'Delete',

	//Errors
	Error_Fname: 'First name is required.',
	Error_Lname: 'Last name is required.',
	Error_Email: 'Email is required.',
	Error_Valid_Email: 'Provide a valid email.',
	Error_Phone: 'Phone number is required.',
	Error_Invalid_Phone: 'Invalid phone number.',
	Error_Organization: 'Organization is required.',
	Error_Roles: 'A role must be selected.',
	Error_Customer_Name: 'Customer name is required.',
	Error_Customer_Type: 'Customer type is required.',
	Error_RP_Name: 'Research partner name is required.',
	Error_RP_Type: 'Research partner type is required.',
	Error_Common: 'Something went wrong.',

	//Success Msg
	Success_User_Added: 'New user was successfully added',
	Success_User_Updated: 'User was successfully updated',
	Success_User_Deleted: 'User was successfully deleted',
	Success_Customer_Added: 'New customer was successfully added',
	Success_Customer_Updated: 'Customer was successfully updated',
	Success_Customer_Deleted: 'Customer was successfully deleted',
	Success_RP_Added: 'New research partner was successfully added',
	Success_RP_Updated: 'Research partner was successfully updated',
	Success_RP_Deleted: 'Research partner was successfully deleted',
	Success_Message_Type: 'delete',
	Success_User_Email_Sent: 'An email has been sent with a link to reset your password.',

	// Enroll otp screen
	Verfication_Enroll_Message:
		'Verification code has been sent for enrollment. After successfull enrollment, you will be redirected to login screen.',
	Success_UserProfile_Updated: 'Account was successfully updated',

	// Dataset
	DS_Error: "Error: ",
	DS_Error_Message: "There was a problem acquiring this dataset. Please try again.",
	DS_Description: "Description",
	DS_Date_Received: "Date received",
	DS_Date_Updated: "Date updated",
	DS_Studies: "Studies",
	DS_Profile: "Profile",
	DS_Total_Patients: "Total patients",
	DS_Total_Encounters: "Total encounters",
	DS_Earliest_Encounter:"Earliest encounter",
	DS_Latest_Encounter: "Latest encounter",
	DS_Gender: "Gender",
	DS_Age: "Age",
	DS_Activity: 'Activity',
	DS_Reject_Message: 'Are you sure you want to reject this dataset',
	DS_Cancel_Btn: 'Cancel',
	DS_Reject_Modal_Btn: 'Yes, reject dataset',
	DS_Reject_Btn: 'Reject dataset',
	DS_Acquire_Btn: 'Acquire dataset',
	DS_Activate_Btn: 'Activate dataset',
	DS_Process_Btn: 'Process dataset',
	DS_Deactivate_Btn: 'Deactivate dataset',
	DS_Error_Confirm: 'Ok',
	DS_InvalidRP_Error: 'You are not authorised to access these datasets.',

	//Dataset studies
	DS_std_processedby: "Processed by",
	DS_std_Records_Processed: "Records processed:",
	DS_std_Processing_time: "Processing time:",
	DS_std_ProcessedBy: "Processed by:",
	DS_std_Error: "Error",

	SIG_Title: 'E-Signature Authorization',
	SIG_Term_Condition: 'By checking this box I acknowledge that I performed the specified action',
	SIG_submit_btn: 'Submit',
	SIG_Cancel_Btn: 'Cancel',
	SIG_Check_Mendatory: 'Accepting terms and signature is mandatory',
	SIG_Check_Reason: 'Accepting terms, reason and signature is mandatory',
	// Study Modal
	MP_Study_Title: 'Available studies',
	MP_Study_Message: 'Select a study in which to apply this dataset.',
	MP_Study_Placeholder: 'Select study',
	MP_Study_Btn_Next: 'Next',
	MP_Study_Btn_Cancel: 'Cancel',
	MP_Study_Error: 'Study is required.',
	SIG_Verfication_Message1: "WE'VE SENT AN SMS TO: **phoneNum**.",
	SIG_Verfication_Message2: "Enter the 6-digit code we've just sent to your phone.",
	
	SIG_Term_Condition_Acquire: 'By checking this box I acknowledge that I initiated the acquisition of the dataset',
	SIG_Term_Condition_Reject: 'By checking this box I acknowledge that I rejected the dataset',
	SIG_Term_Condition_Activate: 'By checking this box I acknowledge that I activated the dataset',
	SIG_Term_Condition_Deactivate: 'By checking this box I acknowledge that I deactivated the dataset',
	SIG_Term_Condition_Process: 'By checking this box I acknowledge that I initiated the processing of the dataset',
	SIG_Reason_Label: 'Why are you rejecting/deleting this data set?',
	SIG_Reason_Placeholder: 'Select reason',
	
	// vitual appliance monitor
	VA_Title: 'Virtual appliance monitor',
	VA_Table_Header: 'Virtual appliances',

	//Documentation
	Doc_Overview: 'Overview',
	Doc_Scroll_Top: 'Scroll to top',

	//Notification
	Notification_Title: 'Notifications',
	Notification_Detail_Link: 'View details'
};

export default StaticLabels;

import React, { useState, useEffect, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import auth0 from 'auth0-js';
import AUTH_CONFIG from '../../constants';
import logo from '../../images/logo.svg';
import './logincontainer.scss';
import StaticLabels from '../../utils/messages';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { checkUserEnrolled, triggerEnrolleUser } from './loginHelper';

function LoginContainer(props) {
	const [ user, setUser ] = useState({
		email: '',
		password: ''
	});
	// const isDBVerificationFailed =
	// 	sessionStorage.getItem('dbverified') && sessionStorage.getItem('dbverified') === 'false' ? true : false;
	const [ error, setError ] = useState('');
	const [ disableForm, setDisableForm ] = useState(false);
	const [ showOTPScreen, setShowOTPScreen ] = useState(props.testEnableOtp ? true : false);
	const [ otpInputs, setOTPInputs ] = useState([]);
	const route = window.location.pathname;
	const [ mfaResponce, setMFAResponce ] = useState({});
	const { email, password } = user;
	const inputRef = useRef([]);
	const [ otpInput1, setOTPInput1 ] = useState('');
	const [ otpInput2, setOTPInput2 ] = useState('');
	const [ otpInput3, setOTPInput3 ] = useState('');
	const [ otpInput4, setOTPInput4 ] = useState('');
	const [ otpInput5, setOTPInput5 ] = useState('');
	const [ otpInput6, setOTPInput6 ] = useState('');

	useEffect(
		() => {
			sessionStorage.clear();
			const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.history.replaceState({}, document.title, url);
			if (route !== '/' && !sessionStorage.getItem('accessToken')) {
				props.history.push('/');
			}
			if ((route === '/' || route.indexOf('/verified') !== -1) && sessionStorage.getItem('accessToken')) {
				props.history.push('/sysadmin/userlist');
			}
		},
		[ route ]
	);

	const loginUser = () => {
		showLoader(true);
		const callbackUrl = AUTH_CONFIG.callbackUrl + '/verified';
		const webAuth = new auth0.WebAuth({
			domain: AUTH_CONFIG.domainName,
			clientID: AUTH_CONFIG.clientID
		});
		webAuth.login(
			{
				realm: AUTH_CONFIG.connection,
				username: email,
				password: password,
				audience: 'https://sysadmin.verantos.com',
				responseType: 'token id_token',
				redirectUri: callbackUrl
			},
			(err1, authResult) => {
				showLoader(false);
				if (err1) {
					setError(err1.error_description);
					setDisableForm(false);
				}
			}
		);
	};

	const isUserEnrolled = () => {
		showLoader(true);
		checkUserEnrolled(email, password)
			.then((resp) => {
				showLoader(false);
				if (resp && resp.data.payload && resp.data.payload.mfaToken) {
					setMFAResponce(resp.data.payload);
					setShowOTPScreen(true);
				} else {
					loginUser();
				}
			})
			.catch((err) => {
				showLoader(false);
				setError(err?.data?.status.toString());
				setDisableForm(false);
			});
	};

	const validate = (event) => {
		event.preventDefault();
		setError('');
		setDisableForm(true);

		if (!email.trim()) {
			setError(StaticLabels.Login_Error_Email);
			setDisableForm(false);
			return;
		}

		if (email) {
			const emailReg = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
			const res = emailReg.test(email);
			if (!res) {
				setError(StaticLabels.Login_Error_ValidEmail);
				setDisableForm(false);
				return;
			}
		}
		if (!password) {
			setError(StaticLabels.Login_Error_Password);
			setDisableForm(false);
			return;
		}
		isUserEnrolled();
	};

	const handleKeyPress = (event) => {
		if (event.charCode === 13) {
			validate(event);
		}
	};

	const updateValue = (e) => {
		const value = e.target.value;
		setUser({
			...user,
			[e.target.name]: value
		});
		setError('');
	};

	const gotoForgetPassword = () => {
		props.history.push('/forgotpassword');
	};

	const otpChange = (event, index) => {
		otpInputs[index] = event.target.value;
		if (index === 0) {
			setOTPInput1(event.target.value);
		}
		if (index === 1) {
			setOTPInput2(event.target.value);
		}
		if (index === 2) {
			setOTPInput3(event.target.value);
		}
		if (index === 3) {
			setOTPInput4(event.target.value);
		}
		if (index === 4) {
			setOTPInput5(event.target.value);
		}
		if (index === 5) {
			setOTPInput6(event.target.value);
		}
		setOTPInputs(otpInputs);
	};

	const onKeyUp = (event, index) => {
		if (
			index < 5 &&
			event.keyCode !== 8 &&
			((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))
		) {
			inputRef.current[index + 1].focus();
		}
		return;
	};

	const resendOTP = () => {
		setOTPInput1('');
		setOTPInput2('');
		setOTPInput3('');
		setOTPInput4('');
		setOTPInput5('');
		setOTPInput6('');
		isUserEnrolled();
	};

	const validateOTP = (event) => {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		setError('');
		if (otpInputs.length < 6) {
			setError('All fields are mendatory');
			return;
		}
		let isEmpty = false;
		otpInputs.map((items) => {
			if (items.trim() === '') {
				isEmpty = true;
			}
		});
		if (isEmpty) {
			setError('All fields are mendatory');
			return;
		}

		triggerEnrolleUser(mfaResponce.mfaToken, otpInputs.join(''), mfaResponce.oobCode)
			.then((resp) => {
				// setShowOTPScreen(false);
				// setDisableForm(false);
				props.history.push(`/verified#access_token=${resp.data.payload || ''}`);
			})
			.catch((err) => {
				setError(err.data.payload.toString());
			});
	};

	const handleOTPKeyPress = (event) => {
		if (event.charCode === 13) {
			validateOTP(event);
		}
	};

	return (
		<div id="main">
			{!showOTPScreen && (
				<form onSubmit={validate}>
					<section className="login-container">
						<div className="login">
							<h1 className="login_header">
								<img className="logo_img" src={logo} alt="Verantos_Logo" />
								<span className="text">{StaticLabels.Login}</span>
							</h1>
							<fieldset id="login_form" name="login_form" className="login_form" disabled={disableForm}>
								<div className="form-field-control">
									<FormControl
										type="text"
										name="email"
										className="form-field-input"
										id="email"
										autoFocus
										placeholder=" "
										// onBlur={() => setUserPlaceholder(StaticLabels.Login_Email_Placeholder)}
										// onFocus={() => setUserPlaceholder('')}
										// placeholder={userPlaceholder}
										onChange={updateValue}
										value={email}
										onKeyPress={handleKeyPress}
									/>
									<label htmlFor="email" id="email-label" className="form-field-label">
										Email
									</label>
								</div>

								<div className="form-field-control">
									<FormControl
										type="password"
										name="password"
										className="form-field-input"
										id="password"
										// onBlur={() => setPassPlaceholder(StaticLabels.Login_Password_Placeholder)}
										// onFocus={() => setPassPlaceholder('')}
										// placeholder={passPlaceholder}
										placeholder=" "
										onChange={updateValue}
										value={password}
										onKeyPress={handleKeyPress}
									/>
									<label htmlFor="password" id="password-label" className="form-field-label">
										Password
									</label>
								</div>

								<div className="error_field">
									{error ? error === StaticLabels.Login_Blocked_User ? (
										<span>{StaticLabels.Login_Error_Inactive_Account}</span>
									) : (
										<span>{error}</span>
									) : null}
								</div>
								<p className="submit">
									<ButtonNormal
										buttonType="2"
										name="commit"
										type="submit"
										id="login"
										className="button"
										onClick={validate}
										onKeyPress={handleKeyPress}
										text={StaticLabels.Login_Btn}
									/>
								</p>
								<p>
									<span onClick={gotoForgetPassword} className="forgot">
										{StaticLabels.Login_Forgot_Password}
									</span>
								</p>
							</fieldset>
						</div>
					</section>
				</form>
			)}
			{showOTPScreen && (
				<form>
					<section className="mfa-container">
						<div className="mfa">
							<h1 className="mfa_header">
								<img className="logo_img" src={logo} alt="Verantos_Logo" />
								<span className="text">{StaticLabels.Verification_Code}</span>
							</h1>
							<p className="mfa-info">
								<span>{StaticLabels.Verfication_Enroll_Message}</span>
							</p>
							<br />
							<fieldset id="reset" name="otp-screen" className="">
								<span className="digit-group">
									<input
										type="text"
										ref={(el) => (inputRef.current[0] = el)}
										value={otpInput1}
										onChange={(e) => otpChange(e, 0)}
										onKeyUp={(e) => onKeyUp(e, 0)}
									/>
									<input
										type="text"
										ref={(el) => (inputRef.current[1] = el)}
										value={otpInput2}
										onChange={(e) => otpChange(e, 1)}
										onKeyUp={(e) => onKeyUp(e, 1)}
									/>
									<input
										type="text"
										ref={(el) => (inputRef.current[2] = el)}
										value={otpInput3}
										onChange={(e) => otpChange(e, 2)}
										onKeyUp={(e) => onKeyUp(e, 2)}
									/>
									<input
										type="text"
										ref={(el) => (inputRef.current[3] = el)}
										value={otpInput4}
										onChange={(e) => otpChange(e, 3)}
										onKeyUp={(e) => onKeyUp(e, 3)}
									/>
									<input
										type="text"
										ref={(el) => (inputRef.current[4] = el)}
										value={otpInput5}
										onChange={(e) => otpChange(e, 4)}
										onKeyUp={(e) => onKeyUp(e, 4)}
									/>
									<input
										type="text"
										ref={(el) => (inputRef.current[5] = el)}
										value={otpInput6}
										onChange={(e) => otpChange(e, 5)}
										onKeyUp={(e) => onKeyUp(e, 5)}
									/>
								</span>
								<p className="resend-otp">
									<span id="otp-resend" onClick={resendOTP}>
										{StaticLabels.MFA_Resend}
									</span>
								</p>
								<div className="error_field">{error && <span className="">{error}</span>}</div>
								<ButtonNormal
									id="submit-otp"
									buttonType="2"
									type="button"
									text={StaticLabels.MFA_Btn}
									name="commit"
									value="Sign in"
									className="button"
									onClick={(event) => validateOTP(event)}
									onKeyPress={(event) => handleOTPKeyPress(event)}
								/>
							</fieldset>
						</div>
					</section>
				</form>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(LoginContainer);

LoginContainer.propTypes = {
	history: PropTypes.any,
	testEnableOtp: PropTypes.bool
};

const DocumentTable = (props) => {
	const tableContent = props;

	return `<table>
	           <tr>
			   ${tableContent.headers
					.map((header) => {
						return `<td colspan="1" rowspan="1"><p><span>${header.columnName}</span></p></td>`;
					})
					.join('')}
			   </tr>
			   <tr>
                ${tableContent.rows
					.map((row) => {
						return `<tr>
						${tableContent.headers
							.map((header) => {
								return Object.keys(row).map(
									(e) =>{
										if(header.key === e) {
										return `<td colspan="1" rowspan="1"><p><span>
									                ${row[e]}
								                    </span></p></td>`
										}
								}).join('')
							})
							.join('')}
						</tr>`;
					})
					.join('')}
			   </tr>
	        </table>`;
};

export default DocumentTable;

import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ModalComponent from '../../component/modal/ModalComponent';
import CustomSelect from '../../component/customselect/CustomSelect';
import { showError, showSuccess } from '../../utils/toaster';
import './addnewcustomer.scss';
import { getCustomerTypes, updateCustomerDetail, addNewCustomer, deleteCustomerDetails } from './addCustomerHelper';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';
import CustomerDetail from './CustomerDetail';
import { showLoader } from '../../utils/commonUtils';
import CustomModalFooter from '../../component/modal/CustomModalFooter';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { connect } from 'react-redux';

function AddCustomerContainer(props) {
	const [ key, setKey ] = useState('1');
	const [ customerDetail, setCustomerDetail ] = useState({
		name: '',
		type: '',
		address: '',
		status: 'true',
		id: 0
	});

	const [ errors, setError ] = useState([]);
	const [ customerType, setCustomerType ] = useState();
	const [ apiError, setApiError ] = useState('');
	const [ disabled, setDisabled ] = useState(false);
	const [ customerTypes, setCustomerTypes ] = useState([]);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const statusOption = [
		{
			value: 'true',
			label: 'Active'
		},
		{
			value: 'false',
			label: 'Inactive'
		}
	];

	const getCustomerTypeList = () => {
		showLoader(true);
		getCustomerTypes()
			.then((resp) => {
				showLoader(false);
				// const response = resp && resp.data.payload.sort();
				// const updateList = response.map((ctype, index) => {
				// 	return {
				// 		label: ctype,
				// 		value: ctype,
				// 		type: ctype,
				// 		status: true
				// 	};
				// });
				// setCustomerTypes(updateList);
			})
			.catch((error) => {
				showLoader(false);
			});
	};

	useEffect(
		() => {
			getCustomerTypeList();
			if (props.customerInfo) {
				setCustomerDetail(props.customerInfo);
				setDisabled(false);
				setCustomerType(props.customerInfo.type);
			}
		},
		[ props.addCustomer, props.customerInfo ]
	);

	useEffect(()=>{
		if(props.cutomerTypeList.length >0){
			const response =props.cutomerTypeList.sort();
			const updateList = response.map((ctype, index) => {
				return {
					label: ctype,
					value: ctype,
					type: ctype,
					status: true
				};
			});
			setCustomerTypes(updateList);
		}
	}, [ props.cutomerTypeList ])

	const updateTypeValue = (e) => {
		setCustomerType(e.value);
		customerDetail.type = e.value;
		setError([]);
	};

	const updateValue = (e) => {
		const value = e.target.value;
		setCustomerDetail({
			...customerDetail,
			[e.target.name]: value
		});
	};

	const resetCustomerList = ()=>{
		props.handleClose();
		props.getCustomers();
		showLoader(false);
	}
	
	const onSave = (errorr) => {
		customerDetail.type = customerType;
		customerDetail.status = customerDetail.status.toString() === 'true' ? true : false;
		setCustomerDetail(customerDetail);
		if (Object.keys(errorr).length === 0) {
			showLoader(true);
			if (props.customerInfo) {
				updateCustomerDetail(customerDetail)
					.then(() => {
						resetCustomerList();
						showSuccess(StaticLabels.Success_Customer_Updated);
					})
					.catch((error) => {
						showLoader(false);
						setApiError(error.data.payload);
					});
			} else {
				addNewCustomer(customerDetail)
					.then((resp) => {
						resetCustomerList();
						showSuccess(StaticLabels.Success_Customer_Added);
					})
					.catch((error) => {
						showLoader(false);
						setApiError(error.data.payload);
					});
			}
		}
	};

	const handleNextClick = (newKey) => {
		const error = [];
		setError([]);
		setApiError();
		if (key === '1') {
			const { name, type } = customerDetail;
			if (!name.trim()) {
				error.push({ name: StaticLabels.Error_Customer_Name });
			} else if (!type) {
				error.push({ name: StaticLabels.Error_Customer_Type });
			}
		}
		setError(error);
		if(key === '1' && !props.customerInfo){
			if(!error.length){
				onSave(error)
			}
		}else if (props.customerInfo && key === '1') {
			if(!error.length){
					setKey('2')
			}
		} else if(key === '2') {
			if(!error.length){
				if(typeof(newKey) === 'string'){
					setKey(newKey)
				}else{
					onSave(error)
				}
			}
		}
	};

	const handleSelect = (newKey) => {
		handleNextClick(newKey)
	};

	const onStatusChange = (e) => {
		setCustomerDetail({
			...customerDetail,
			status: e.value
		});
	};

	const removeCustomer = () => {
		setConfirmDelete(true);
	};

	const closeDeleteModal = () => {
		setConfirmDelete(false);
	};

	const deleteCustomer = (customerId) => () => {
		showLoader(true);
		deleteCustomerDetails(customerId)
			.then((resp) => {
				resetCustomerList();
				showError(StaticLabels.Success_Customer_Deleted)
			})
			.catch((error) => {
				showLoader(false);
				if (error && error.response) {
					closeDeleteModal();
					if (error.response.status === 500) {
						setApiError(error.response.data.status);
					} else {
						setApiError(error.response.data.payload);
					}
				} else {
					setApiError(StaticLabels.Error_Common);
				}
			});
	};

	return (
		<ModalComponent
			open={props.open}
			handleClose={props.handleClose}
			centered
			dialogClassName={props.className ? props.className : 'setting-modals'}
			keyboard
			className="addcustomer-modal"
			header={props.customerInfo ? StaticLabels.MP_Edit_Customer_Title : StaticLabels.MP_Add_Customer_Title}
			content={
				<React.Fragment>
					<div className="modal-tabs">
						<Tabs
							className={`nav-pills ${ props.addCustomer ? 'nav-tabs-add' : 'nav-tabs-update' }`}
							activeKey={key}
							onSelect={handleSelect}
							id="controlled-tab-example"
						>
							<Tab eventKey={1} title={StaticLabels.MP_Customer_Detail_Tab}>
								{' '}
								{
									<CustomerDetail
										onChange={updateValue}
										className="customerDetail"
										onSubmit={handleNextClick}
										errors={errors}
										customerDetail={customerDetail}
										disabled={disabled}
										customerTypes={customerTypes}
										onTypeChange={updateTypeValue}
										apiError={apiError}
									/>
								}{' '}
							</Tab>{' '}
							{props.customerInfo && (
								<Tab eventKey={2} title={StaticLabels.MP_Customer_Setting_Tab}>
									<div className="section">
										{
											<div className="section setting">
												<label id="cust-status">{StaticLabels.MP_Customer_Status}</label>{' '}
												<CustomSelect
													className={'custom-status-select'}
													placeholder={StaticLabels.MP_Customer_Status_Placeholder}
													options={statusOption}
													value={
														customerDetail.status.toString() === 'true' ? (
															{
																value: 'true',
																label: 'Active'
															}
														) : (
															{
																value: 'false',
																label: 'Inactive'
															}
														)
													}
													onChange={onStatusChange}
												/>{' '}
											</div>
										}
										<div className="delete_field">
											<span className="deletecustomer" onClick={removeCustomer}>
												{StaticLabels.MP_Customer_Delete}
											</span>{' '}
										</div>
										{confirmDelete && (
											<ModalComponent
												open={true}
												header={StaticLabels.MP_Delete_Header}
												className="delete-modal"
												overlayClassName="delete-modal-overlay"
												handleClose={closeDeleteModal}
												content={
													<div>
														<p>{StaticLabels.MP_Delete_Customer}</p>
														<p>{StaticLabels.MP_Delete_Warning}</p>
													</div>
												}
												footer={
													<React.Fragment>
														<ButtonNormal
															buttonType="1"
															className="buttons cancel-btn"
															onClick={closeDeleteModal}
															text={StaticLabels.MP_Delete_Cancel_Btn}
														/>
														<ButtonNormal
															buttonType="3"
															className="buttons delete-btn"
															onClick={deleteCustomer(props.customerInfo.id)}
															text={StaticLabels.MP_Delete_Btn}
														/>
													</React.Fragment>
												}
											/>
										)}
										{
											<div className="error_field" id="api-error">
												{' '}
												{apiError ? (
													<span>
														{' '}
														{apiError[0].message ? apiError[0].message : apiError}{' '}
													</span>
												) : null}{' '}
											</div>
										}{' '}
									</div>{' '}
								</Tab>
							)}{' '}
						</Tabs>{' '}
					</div>{' '}
				</React.Fragment>
			}
			footer={
				<CustomModalFooter
					tabKey={(props.addCustomer ? key === '1' : key === '2') ? true : false}
					handleClose={props.handleClose}
					handleNextClick={handleNextClick}
					Info={props.customerInfo}
				/>
			}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		cutomerTypeList:state.redCommon.customerTypeList
	};
};

export default connect(mapStateToProps)(AddCustomerContainer);

AddCustomerContainer.propTypes = {
	customerInfo: PropTypes.object,
	addCustomer: PropTypes.bool,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	getCustomers: PropTypes.func,
	className: PropTypes.string,
	cutomerTypeList: PropTypes.array
};
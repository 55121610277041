const apiUrl = {
    GetLoggedInUserInfo: '/users/info',
    GetRoles : '/Roles',
    GetUserList: '/users/list',
    GetOrganizationList: '/organization/list',
    UserDetail: '/User',
    GetUserInfo: '/User',
    UserProfile: '/userprofile',

    GetCustomerList: '/customer/list', 
    CustomerDetail: '/customer',
    GetCustomerInfo: '/customer',
    GetCustomerTypes: '/customer/type',

    GetResearcPartnerList: '/researchPartner/list',
    GetResearchPartnerInfo: '/researchPartner',
    GetResearchPartnerTypes: '/researchPartner/type',
    ResearchPartnerDetail: '/researchPartner',
   
    GenerateReport: '/Job/GenerateReport',
    GetAuditReport: '/auditreport',
    TriggerAuditReport: '/trigger-audit-report',
    DownloadAuditReport: '/download-auditreport',

    GetDatasetActivity: '/data-admin/dataset',

    IsUserEnrolled: '/mfa/checkenrollment',
    EnrolleUser: '/mfa/enroll',
    UpdateDatasetStatus: '/data-admin/dataset',
    UpdateDatasetDetail: '/data-admin/dataset/##ID##/editDescription',
    GetActiveDataset: '/researchPartner/active-dataset',
    AcquireDataset: '/data-admin/acquire',
    GetDataset: '/data-admin/list',
    RejectDataset : '/dataset/reject/',
    DeactivateDataset :'/dataset/deactivate/',
    ActivateDataset: '/dataset/activate/',
    ProcessDataset: '/dataset/process/',
    DatasetDetail : '/dataset/',
    GetDatasetProfile: '/dataset/##DATASETID##/data-profile',
    GetStudyActiveList: '/study/active-list',
    SendOTP: '/sendOTP',
    VerifyOTP: '/verifyOTP',
    GetStatusCount: '/dataset/status-count/',
    VAMonitorList: '/researchPartner/virtual-appliance/health',
    ReasonsForAction: '/dataset/action-reasons',
    GetStudyDocumentList: '/metadata/document/get-latest-study-documents',
    GetDocumentContent: '/documentation/##DOCUMENTNAME##',
    GetUnreadNotification: '/notification/unread',
    GetNotificationList: '/notification/list',
    SetReadNotification: '/notification/read',
    getDocumentTemplate: '/documentation/template/##TEMPLATE-NAME##',
    getMetadataTableContent: '/metadata/##TABLE-ID##/get-for-study',
    // getMetaDataProfilingMetrics: '/metadata/profiling-metrics/get-for-study',
    // getMetaDataStructuredDataTransform: '/metadata/structured-data-transform/get-for-study',
    getMetatdataImage: '/documentation/image/##IMAGE-ID##'
}

export default apiUrl;
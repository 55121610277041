import actionTypes from '../utils/actionTypes';

const initState = {
	roles: [],
	loader: false,
	availableDataset: [],
	datasetList: [],
	researchPartnerList: [],
	userList: [],
	customerList: [],
	customerTypeList: [],
	researchPartnerTypeList: [],
	auditReportList: [],
	listenChange: true,
	studyProgressChange: {},
	documentList: [],
	notificationList: [],
	notifiList: [],
	toastId: {},
	acquisitionProgressData: {},
	unreadNotificationCount: [],
	activeNotification: false,
	updatedDataset: {},
	studyDocumentList: []
};

export const CommonReducer = (state = initState, action) => {
	switch (action.type) {
		case actionTypes.ROLE_LIST:
			return {
				...state,
				roles: action.payload
			};
		case actionTypes.SHOW_LOADING:
			return {
				...state,
				loader: action.payload
			};
		case actionTypes.AVAILABLE_DATA_SET:
			return {
				...state,
				availableDataset: action.payload
			};
		case actionTypes.DATA_SET_LIST:
			return {
				...state,
				datasetList: [ ...state.datasetList, ...action.payload ]
			};
		case actionTypes.UPDATED_DATASET_LIST:
			return {
				...state,
				datasetList: action.payload
			};
		case actionTypes.RESEARCH_PARTNER_LIST:
			return {
				...state,
				researchPartnerList: action.payload
			};
		case actionTypes.USER_LIST:
			return {
				...state,
				userList: action.payload
			};
		case actionTypes.CUSTOMER_LIST:
			return {
				...state,
				customerList: action.payload
			};
		case actionTypes.CUSTOMER_TYPE_LIST:
			return {
				...state,
				customerTypeList: action.payload
			};
		case actionTypes.RP_TYPE_LIST:
			return {
				...state,
				researchPartnerTypeList: action.payload
			};
		case actionTypes.AUDIT_REPORT_LIST:
			return {
				...state,
				auditReportList: action.payload
			};
		case actionTypes.LISTEN_CHANGE:
			return {
				...state,
				listenChange: action.payload
			};
		case actionTypes.DOCUMENT_LIST:
			return {
				...state,
				documentList: action.payload
			};
		case actionTypes.STUDY_PROGRESS_CHANGE:
			return {
				...state,
				studyProgressChange: action.payload
			};
		case actionTypes.NOTIFICATION_LIST:
			return {
				...state,
				notificationList: action.payload
			};
		case actionTypes.TOAST_ID:
			return {
				...state,
				toastId: action.payload
			};
		case actionTypes.ACQUISITION_PROGRESS_DATA:
			return {
				...state,
				acquisitionProgressData: action.payload
			};
		case actionTypes.UNREAD_NOTIFICATION_COUNT:
			return {
				...state,
				unreadNotificationCount: action.payload
			};
		case actionTypes.ACTIVE_NOTIFICATION:
			return {
				...state,
				activeNotification: action.payload
			};
		case actionTypes.UPDATED_DATASET:
			return {
				...state,
				updatedDataset: action.payload
			};
		case actionTypes.STUDY_DOCUMENT_LIST:
			return {
				...state,
				studyDocumentList: action.payload
			};
		case actionTypes.NOTIFI_LIST:
			return {
				...state,
				notifiList: action.payload
			};
		default:
			return state;
	}
};

export default CommonReducer;

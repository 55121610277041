import React from 'react';
import ModalComponent from './../modal/ModalComponent';
import './signaturepopup.scss';
import PropTypes from 'prop-types'

const SignaturePopup = (props) => {
	return (
		<ModalComponent
			open={true}
			className="display-signature"
			handleClose={props.handleClose}
			header={' '}
			closeButton={true}
			content={
				<div>
					<img className="image" src={props.imageString} alt="signature" />
				</div>
			}
		/>
	);
};

export default SignaturePopup;

SignaturePopup.propTypes = {
	handleClose: PropTypes.func,
	imageString: PropTypes.string
}
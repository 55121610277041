import React from 'react';
import StaticLabels from '../../utils/messages';
import CustomSelect from '../../component/customselect/CustomSelect';
import PropTypes from 'prop-types';

const UserOrganization = (props) => {
	const errors = props.errors;
	return (
		<div className="section">
			<label className="org-label">{StaticLabels.MP_User_Organization}</label>{' '}
			<CustomSelect
				className="custom-search-select"
				placeholder={StaticLabels.MP_User_Organization_Placeholder}
				id="selectOrgInput"
				name="selectOrgInput"
				options={props.organization}
				value={props.orgId && props.organization.filter(({ value }) => value === props.orgId)}
				onChange={props.onChange}
				isDisabled={props.disabled}
			/>{' '}
			<div className="error_org error_field">{errors ? <span>{errors.organization}</span> : null}</div>
		</div>
	);
};

export default UserOrganization;

UserOrganization.propTypes = {
	orgId: PropTypes.oneOfType([ PropTypes.array, PropTypes.number ]),
	onChange: PropTypes.func,
	organization: PropTypes.array,
	disabled: PropTypes.bool,
	errors: PropTypes.array
};

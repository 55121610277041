import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginContainer from './container/login/LoginContainer';
import AuthenticateContainer from './container/authenticate/AuthenticateContainer';
import UserListContainer from './container/userlist/UserListContainer';
import CustomerListContainer from './container/customerlist/CustomerListContainer';
import ResearchPartnerContainer from './container/researchpartner/ResearchPartnerContainer';
import Sidebar from './container/sidebar/SidebarContainer';
import ForgotPasswordContainer from './container/forgotpassword/ForgotPasswordContainer';
import AuditReportContainer from './container/auditreport/AuditReportContainer';
import store from './store';
import PropTypes from 'prop-types';
import MFAContainer from './container/mfa/MFAContainer';
import UserProfileContainer from './container/userprofile/UserProfile';
import ResearchNetworkContainer from './container/researchnetwork/ResearchNetworkContainer';
import ResearchNetworkDetailContainer from './container/researchnetworkdetail/ResearchNetworkDetailContainer';
import VirtualApplianceMonitor from './container/virtualappliancemonitor/VirtualApplianceMonitor';
import DocumentContainer from './container/documentContainer/DocumentContainer';
import NotificationContainer from './container/notification/NotificationContainer';
import StudyContainer from './container/study/StudyContainer';
import SockJsClientConnection from './SockJsClientConnection';

const WrapperLayout = (props) => {
	const isAuthenticated = sessionStorage.getItem('accessToken') ? true : false;
	const isDocumentation = window.location.href.indexOf('/documentation') !== -1 ? true : false;

	return (
		<div className={`sa-container ${!isAuthenticated ? 'opacitylow' : ''}`}>
			<div className="left-panel">
				<Sidebar firstName={''} {...props} key={props.location.key} />
			</div>
			<div className={`${isDocumentation ? 'right-panel document-panel' : 'right-panel'}`}>
				{React.cloneElement(props.children, { ...props, key: props.location.key })}
			</div>
		</div>
	);
};

WrapperLayout.propTypes = {
	children: PropTypes.element,
	location: PropTypes.object
};

const PageNotFound = (props) => {
	const isAuthenticated = sessionStorage.getItem('accessToken') ? true : false;
	return (
		<div className={`NF-container ${!isAuthenticated ? 'opacitylow' : ''}`}>
			<h1> Page not found. Please use left menu to go to a particular page.</h1>
		</div>
	);
};
ReactDOM.render(
	<Provider store={store}>
		<Router>
			<App>
				<React.Fragment>
					<SockJsClientConnection />
					<Switch>
						<Route exact path="/" component={LoginContainer} />
						<Route path="/verified" component={AuthenticateContainer} />
						<Route path="/forgotpassword" component={ForgotPasswordContainer} />
						<Route path="/mfa" component={MFAContainer} />

						<Route
							path="/sysadmin/userlist"
							component={(props) => (
								<WrapperLayout {...props}>
									<UserListContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/sysadmin/research"
							component={(props) => (
								<WrapperLayout {...props}>
									<ResearchPartnerContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/sysadmin/customerlist"
							component={(props) => (
								<WrapperLayout {...props}>
									<CustomerListContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/sysadmin/auditreport"
							component={(props) => (
								<WrapperLayout {...props}>
									<AuditReportContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/userprofile"
							component={(props) => (
								<WrapperLayout {...props}>
									<UserProfileContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/dataadmin/:id"
							component={(props) => (
								<WrapperLayout {...props}>
									<ResearchNetworkContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/researchnetworkdetail/:id/:rp_id"
							component={(props) => (
								<WrapperLayout {...props}>
									<ResearchNetworkDetailContainer />
								</WrapperLayout>
							)}
						/>
						{/* <Route
							path="/virtualappliancemonitor"
							component={(props) => (
								<WrapperLayout {...props}>
									<VirtualApplianceMonitor />
								</WrapperLayout>
							)}
						/> */}
						{/* <Route
							path="/documentation/template/:docid"
							component={(props) => (
								<WrapperLayout {...props}>
									<DocumentContainer />
								</WrapperLayout>
							)}
						/> */}
						<Route
							path="/notification"
							component={(props) => (
								<WrapperLayout {...props}>
									<NotificationContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path="/study/:id"
							component={(props) => (
								<WrapperLayout {...props}>
									<StudyContainer />
								</WrapperLayout>
							)}
						/>
						<Route
							path=""
							component={(props) => (
								<WrapperLayout {...props}>
									<PageNotFound />
								</WrapperLayout>
							)}
						/>
					</Switch>
				</React.Fragment>
			</App>
		</Router>
	</Provider>,
	document.getElementById('root')
);

import React from 'react';
import StaticLabels from '../../utils/messages';
import CustomSelect from '../../component/customselect/CustomSelect';
import PropTypes from 'prop-types'

const CustomerDetail = (props) => {
	const { name, type, address } = props.customerDetail;
	const errors = props.errors;

	return (
		<form id="customerDetail" className="customerDetail" onSubmit={props.handleNextClick}>
			<section className="section">
				<div className="labels">
					<label id="cust-name">{StaticLabels.MP_Customer_Name}</label>
					<label id="cust-type">{StaticLabels.MP_Customer_Type}</label>{' '}
				</div>{' '}
				<div className="inputfields">
					<input type="text" id="cust-name-input" name="name" value={name} onChange={props.onChange} />{' '}
					<CustomSelect
						className="custom-search-select"
						placeholder={StaticLabels.MP_Customer_Type_Placeholder}
						id="cust-type-input"
						name="selectTypeInput"
						options={props.customerTypes}
						value={type && props.customerTypes.filter(({ value }) => value === type)}
						onChange={props.onTypeChange}
                        isDisabled={props.disabled}
					/>
				</div>{' '}
			</section>
			<section className="section">
				<div className="labels">
					<label id="address">{StaticLabels.MP_Customer_Address}</label>
				</div>{' '}
				<div className="inputfields">
					<textarea name="address" id="address-input" value={address} onChange={props.onChange} />{' '}
				</div>{' '}
			</section>
			<div className="error_field">
				{' '}
				{errors.length > 0 ? (
					<span> {errors[0].name ? errors[0].name : ''} </span>
				) : props.apiError ? (
					<span> {props.apiError[0].message ? props.apiError[0].message : props.apiError} </span>
				) : null}{' '}
			</div>{' '}
		</form>
	);
};

export default CustomerDetail;

CustomerDetail.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    handleNextClick: PropTypes.func,
    errors: PropTypes.array,
    customerDetail: PropTypes.object,
    disabled: PropTypes.bool,
    customerTypes: PropTypes.array,
    onTypeChange: PropTypes.func,
    apiError: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	  ])
}
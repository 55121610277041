import React, { useState, useRef, useEffect } from 'react';
// import './sortcolumn.scss';
import PropTypes from 'prop-types';
import ModalComponent from '../modal/ModalComponent';
import CanvasDraw from 'react-canvas-draw';
import html2canvas from 'html2canvas';
import ButtonNormal from '../buttonnormal/ButtonNormal';
import StaticLabels from '../../utils/messages';
import './signaturemodal.scss';
import { format } from 'date-fns';
import { showLoader } from '../../utils/commonUtils';
import IconCheckboxChecked from '../../images/Sign_checkbox_active.png';
import IconCheckboxUnchecked from '../../images/check box inactive.svg';
import CustomSelect from '../customselect/CustomSelect';

const defaultProps = {
	onChange: null,
	loadTimeOffset: 5,
	lazyRadius: 1,
	brushRadius: 1,
	brushColor: '#324054',
	catenaryColor: '#324054',
	gridColor: 'rgba(150,150,150,0.17)',
	hideGrid: true,
	canvasWidth: '100%',
	canvasHeight: 150,
	disabled: false,
	imgSrc: '',
	saveData: null,
	immediateLoading: false,
	hideInterface: true
};

const SignatureModal = (props) => {
	const fname = (props.loggedInUser && props.loggedInUser.firstName) || '';
	const lname = (props.loggedInUser && props.loggedInUser.lastName) || '';
	const [ checked, setChecked ] = useState(false);
	const [ showError, setShowError ] = useState(false);
	const [ showSignature, setShowSignature ] = useState(props.testShowSig ? true : false);
	const [ showOTPScreen, setShowOTPScreen ] = useState(true);
	const saveableCanvas = useRef('');
	const inputRef = useRef([]);
	const [ otpInputs, setOTPInputs ] = useState([]);
	const [ error, setError ] = useState('');
	const [ code, setCode ] = useState('');

	const [ otpInput1, setOTPInput1 ] = useState('');
	const [ otpInput2, setOTPInput2 ] = useState('');
	const [ otpInput3, setOTPInput3 ] = useState('');
	const [ otpInput4, setOTPInput4 ] = useState('');
	const [ otpInput5, setOTPInput5 ] = useState('');
	const [ otpInput6, setOTPInput6 ] = useState('');
	const maskedPhoneNo = props.loggedInUser.phone.replace(/^.{9}/g, 'XXXXXXXXX');

	useEffect(
		() => {
			if (props.reasonForAction) {
				defaultProps.disabled = false;
			}
		},
		[ props.reasonForAction ]
	);

	const clearOTP = () => {
		setOTPInput1('');
		setOTPInput2('');
		setOTPInput3('');
		setOTPInput4('');
		setOTPInput5('');
		setOTPInput6('');
		setError('');
		inputRef.current[0].focus();
	};

	const sendOTP = () => {
		if (props.sendOTP) {
			props
				.sendOTP()
				.then((resp) => {
					setCode(resp);
				})
				.catch((err) => {
					setError('Please try again');
				});
		}
	};

	const resendOTP = () => {
		clearOTP();
		sendOTP();
	};

	useEffect(() => {
		sendOTP();
		inputRef.current[0].focus();
	}, []);

	const validateOTP = (event) => {
		// event.stopPropagation();
		// setError('');
		// event.nativeEvent.stopImmediatePropagation();
		// setError('');
		if (!otpInput1 || !otpInput2 || !otpInput3 || !otpInput4 || !otpInput5 || !otpInput6) {
			setError('All fields are mendatory');
			return;
		}

		if (props.verfiyOtp) {
			props
				// .verfiyOtp(code, otpInputs.join(''))
				.verfiyOtp(code, `${otpInput1}${otpInput2}${otpInput3}${otpInput4}${otpInput5}${otpInput6}`)
				.then((resp) => {
					setShowSignature(true);
					setShowOTPScreen(false);
				})
				.catch((err) => {
					console.log(err);
					setError(err.data.payload || 'An error occured');
				});
		}
	};

	const handleOTPKeyPress = (event) => {
		if (event.charCode === 13) {
			validateOTP(event);
		}
	};

	const otpChange = (event, index) => {
		setError('');
		otpInputs[index] = event.target.value;

		if (index === 0) {
			setOTPInput1(event.target.value);
		}
		if (index === 1) {
			setOTPInput2(event.target.value);
		}
		if (index === 2) {
			setOTPInput3(event.target.value);
		}
		if (index === 3) {
			setOTPInput4(event.target.value);
		}
		if (index === 4) {
			setOTPInput5(event.target.value);
		}
		if (index === 5) {
			setOTPInput6(event.target.value);
		}
		setOTPInputs(otpInputs);
	};

	const onKeyDown = (event, index) => {
		if (index < 5 && event.keyCode !== 8 && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
			inputRef.current[index + 1].focus();
		}
		return;
	};

	const closeModal = () => {
		props.closeSignatureModal();
	};

	const captureScreen = (datasetId) => {
		// showLoader(true)
		setShowError(false);
		const signature = JSON.parse(saveableCanvas.current.getSaveData());
		if (checked && signature.lines.length > 0) {
			if (props.sigFlag === 2 || props.sigFlag === 4) {
				if (props.reasonForAction) {
					// showLoader(true);
					setShowError(false)
					html2canvas(document.querySelector(`#signature-container-${datasetId}`)).then((canvas) => {
						// document.querySelector('#capturedImage').appendChild(canvas);
						var dataURL = canvas.toDataURL();
						props.captureSignature(datasetId, dataURL);
					});
				} else {
					showLoader(false);
					setShowError(true);
				}
			} else {
				// showLoader(true);
				html2canvas(document.querySelector(`#signature-container-${datasetId}`)).then((canvas) => {
					// document.querySelector('#capturedImage').appendChild(canvas);
					var dataURL = canvas.toDataURL();
					props.captureSignature(datasetId, dataURL);
				});
			}
		} else {
			showLoader(false);
			setShowError(true);
		}
		// showLoader(false);
	};

	const getTermsMessage = () => {
		// 1 - Acquire
		// 2 - Reject
		// 3 - Activate
		// 4 - Deactivate
		// 5 - Process
		let msg = '';
		switch (props.sigFlag) {
			case 1:
				msg = StaticLabels.SIG_Term_Condition_Acquire;
				break;
			case 2:
				msg = StaticLabels.SIG_Term_Condition_Reject;
				break;
			case 3:
				msg = StaticLabels.SIG_Term_Condition_Activate;
				break;
			case 4:
				msg = StaticLabels.SIG_Term_Condition_Deactivate;
				break;
			case 5:
				msg = StaticLabels.SIG_Term_Condition_Process;
				break;
			default:
				msg = StaticLabels.SIG_Term_Condition;
		}
		return msg;
	};
	return (
		<ModalComponent
			open={true}
			className="signature-modal"
			handleClose={closeModal}
			header={
				!showOTPScreen ? (
					<p className="signature-title">{StaticLabels.SIG_Title}</p>
				) : (
					<p className="signature-title">{StaticLabels.Verification_Code}</p>
				)
			}
			closeButton={true}
			content={
				<React.Fragment>
					{showSignature && (
						<div id={`signature-container-${props.datasetId}`} className="signature-container">
							<div className="signature-sutitle">
								{
									// <input
									// 	className="chk-acknowlegde"
									// 	checked={!checked}
									// 	onChange={() => {
									// 		setChecked(!checked);
									// 		setShowError(false);
									// 	}}
									// 	id="signcheckbox"
									// 	type="checkbox"
									// />
								}
								<span
									className="sign-checktick"
									onClick={() => {
										setChecked(!checked);
										setShowError(false);
									}}
								>
									{checked ? (
										<img className="sign-img-checked" src={IconCheckboxChecked} alt="checked" />
									) : (
										<img
											className="sign-img-unchecked"
											src={IconCheckboxUnchecked}
											alt="unchecked"
										/>
									)}
								</span>
								<label htmlFor="signcheckbox" className="check-label">
									{getTermsMessage()}
								</label>
							</div>
							{(props.sigFlag == 2 || props.sigFlag == 4) && (
								<div className="signature-reason">
									<label className="sign-reason-label">{StaticLabels.SIG_Reason_Label}</label>
									<CustomSelect
										className="sign-select"
										placeholder={StaticLabels.SIG_Reason_Placeholder}
										name="sign-select"
										options={props.actionReason}
										value={
											props.reasonForAction &&
											props.actionReason.filter(
												(reason) => reason.value === props.reasonForAction
											)
										}
										onChange={(e) => {
											props.selectReason(e);
											setShowError(false)
										}}
									/>
								</div>
							)}
							<br />
							<div className="canvas-container">
								<CanvasDraw {...defaultProps} ref={saveableCanvas} />
								<div className="signature-footer">
									<div className="signature-name"> {`${fname} ${lname}`} </div>
									<div className="signature-date">{format(new Date(), 'MM/dd/yy hh:mm a z')} </div>
								</div>
							</div>
						</div>
					)}
					{showError ? (props.sigFlag == 2 || props.sigFlag == 4) && props.reasonForAction === '' ? (
						<div className="error">{StaticLabels.SIG_Check_Reason}</div>
					) : (
						<div className="error">{StaticLabels.SIG_Check_Mendatory}</div>
					) : (
						<div className="error">{''}</div>
					)}

					{showOTPScreen && (
						<form id="otpform">
							<section className="mfa-container">
								<div className="mfa">
									<p className="mfa-info">
										<span>
											{StaticLabels.SIG_Verfication_Message1.replace(
												'**phoneNum**',
												maskedPhoneNo
											)}
										</span>
										<br />
										<span>{StaticLabels.SIG_Verfication_Message2}</span>
									</p>
									<br />
									<span className="digit-group">
										<input
											type="number"
											ref={(el) => (inputRef.current[0] = el)}
											value={otpInput1}
											onChange={(e) => otpChange(e, 0)}
											onKeyUp={(e) => onKeyDown(e, 0)}
										/>
										<input
											type="number"
											ref={(el) => (inputRef.current[1] = el)}
											value={otpInput2}
											onChange={(e) => otpChange(e, 1)}
											onKeyUp={(e) => onKeyDown(e, 1)}
										/>
										<input
											type="number"
											ref={(el) => (inputRef.current[2] = el)}
											value={otpInput3}
											onChange={(e) => otpChange(e, 2)}
											onKeyUp={(e) => onKeyDown(e, 2)}
										/>
										<input
											type="number"
											ref={(el) => (inputRef.current[3] = el)}
											value={otpInput4}
											onChange={(e) => otpChange(e, 3)}
											onKeyUp={(e) => onKeyDown(e, 3)}
										/>
										<input
											type="number"
											ref={(el) => (inputRef.current[4] = el)}
											value={otpInput5}
											onChange={(e) => otpChange(e, 4)}
											onKeyUp={(e) => onKeyDown(e, 4)}
										/>
										<input
											type="number"
											ref={(el) => (inputRef.current[5] = el)}
											value={otpInput6}
											onChange={(e) => otpChange(e, 5)}
											onKeyUp={(e) => onKeyDown(e, 5)}
										/>
									</span>
								</div>
							</section>
						</form>
					)}
					{error && <div className="error"> <span>{error}</span> </div>}
				</React.Fragment>
			}
			footer={
				<div className="footer">
					{showSignature && (
						<React.Fragment>
							<ButtonNormal
								className="buttons captureScreen"
								onClick={() => {
									showLoader(true);
									captureScreen(props.datasetId);
								}}
								text={StaticLabels.SIG_submit_btn}
							/>
							<ButtonNormal
								buttonType="1"
								className="buttons cancelbtn"
								onClick={closeModal}
								text={StaticLabels.SIG_Cancel_Btn}
							/>
						</React.Fragment>
					)}
					{showOTPScreen && (
						<React.Fragment>
							<ButtonNormal
								buttonType="2"
								type="button"
								text={StaticLabels.SIG_submit_btn}
								name="commit"
								value="Sign in"
								className="buttons validateOTP"
								onClick={(event) => validateOTP(event)}
								onKeyPress={(event) => handleOTPKeyPress(event)}
							/>
							<div className="cancelbtn">
								<span onClick={clearOTP} className="clear-otp">
									{StaticLabels.MFA_Clear_Input}
								</span>
								<span className="resend-otp" onClick={resendOTP}>
									{StaticLabels.MFA_Resend}
								</span>
							</div>
						</React.Fragment>
					)}
				</div>
			}
		/>
	);
};

export default SignatureModal;

SignatureModal.propTypes = {
	datasetId: PropTypes.any.isRequired,
	closeSignatureModal: PropTypes.func.isRequired,
	captureSignature: PropTypes.func.isRequired,
	loggedInUser: PropTypes.object.isRequired,
	sendOTP: PropTypes.func.isRequired,
	verfiyOtp: PropTypes.func.isRequired,
	sigFlag: PropTypes.number.isRequired,
	testShowSig: PropTypes.bool,
	reasonForAction: PropTypes.string,
	actionReason: PropTypes.array,
	selectReason: PropTypes.func
};

import React, { useState } from 'react';
import './userprofile.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../../component/header/Header';
import StaticLabels from '../../utils/messages';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import auth0 from 'auth0-js';
import { showLoader } from '../../utils/commonUtils';
import AUTH_CONFIG from '../../constants';
import { showSuccess } from '../../utils/toaster';
import { updateUserDetail, getUserInfo } from './userprofileHelper';
import { getRoleKeys } from '../../utils/commonUtils';

const UserProfileContainer = (props) => {
	const { loggedInUser } = props;
	const [ editProfile, setEditProfile ] = useState(false);
	const [ userInfo, setUserInfo ] = useState({});
	const [ apiError, setApiError ] = useState('');
	const [ errors, setErrors ] = useState([]);

	const getUserDetail = (id) => {
		getUserInfo()
			.then((resp) => {
				setUserInfo(resp.data.payload);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const editDetails = () => {
		setUserInfo(loggedInUser);
		setEditProfile(true);
	};

	const changeUserDetail = (e) => {
		const value = e.target.value;

		setUserInfo({
			...userInfo,
			[e.target.name]: value
		});
	};

	const cancel = () => {
		setEditProfile(false);
		getUserDetail(loggedInUser.id);
		setErrors([]);
	};

	const saveChanges = () => {
		const error = [];

		// delete userInfo.user_roles;
		setUserInfo(userInfo);

		setErrors([]);
		/* eslint-disable */
		const { firstName, lastName } = userInfo;

		if (!firstName.trim()) {
			error['firstName'] = StaticLabels.Error_Fname;
		}

		if (!lastName.trim()) {
			error['lastName'] = StaticLabels.Error_Lname;
		}
		/* eslint-enable */

		setErrors(error);

		if (Object.keys(error).length === 0) {
			showLoader(true);
			/* eslint-disable */
			userInfo.first_name = userInfo.firstName;
			userInfo.last_name = userInfo.lastName;
			userInfo.phone_number = userInfo.phone;
			userInfo.organization_id = userInfo.organizationId;
			userInfo.roles = getRoleKeys(userInfo.user_roles);
			delete userInfo.user_roles;
			/* eslint-enable */
			updateUserDetail(userInfo)
				.then(() => {
					showLoader(false);
					setEditProfile(false);
					showSuccess(StaticLabels.Success_UserProfile_Updated);
					getUserInfo();
				})
				.catch((err) => {
					showLoader(false);
					if (err && err.data) {
						setApiError(err.data.payload);
						console.log(err);
					} else {
						setApiError(StaticLabels.Error_Common);
					}
				});
		}
	};

	const getUserRoles = (roles) => {
		const roleName = [];

		if (roles && roles.length > 0) {
			roles.map((role) => {
				roleName.push(role.display_name);
			});
		}
		return roleName.join(', ');
	};

	const generatePassword = () => {
		let pass = '';
		const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const str2 = 'abcdefghijklmnopqrstuvwxyz';
		const num = '0123456789';
		const specialChar = '@!"£$%^&*()';

		for (let i = 1; i <= 2; i++) {
			let char = Math.floor(Math.random() * str.length);
			pass += str.charAt(char);
			char = Math.floor(Math.random() * str2.length);
			pass += str2.charAt(char);
			char = Math.floor(Math.random() * num.length);
			pass += num.charAt(char);
			char = Math.floor(Math.random() * specialChar.length);
			pass += specialChar.charAt(char);
		}

		const newPass = pass
			.split('')
			.sort(() => {
				return 0.5 - Math.random();
			})
			.join('');

		setUserInfo({ ...userInfo, password: newPass });
		return newPass;
	};

	const resetPassword = () => {
		showLoader(true);

		if (loggedInUser.email) {
			const webAuth = new auth0.WebAuth({
				domain: AUTH_CONFIG.domainName,
				clientID: AUTH_CONFIG.clientID
			});
			// Validating the change password api end point for Auth0.
			webAuth.changePassword(
				{
					connection: AUTH_CONFIG.connection,
					email: loggedInUser.email
				},
				(err, authResult) => {
					loggedInUser.password = generatePassword();
					/* eslint-disable */
					loggedInUser.first_name = loggedInUser.firstName;
					loggedInUser.last_name = loggedInUser.lastName;
					loggedInUser.phone_number = loggedInUser.phone;
					loggedInUser.organization_id = loggedInUser.organizationId;
					loggedInUser.roles = getRoleKeys(loggedInUser.user_roles);
					delete loggedInUser.user_roles;
					/* eslint-enable */
					updateUserDetail(loggedInUser)
						.then(() => {
							showSuccess(StaticLabels.Success_User_Email_Sent);
							showLoader(false);
						})
						.catch((error) => {
							showLoader(false);
							if (error && error.data) {
								setApiError(error.data.payload);
								console.log(error);
							} else {
								setApiError(StaticLabels.Error_Common);
							}
						});
				}
			);
		}
	};

	return (
		<div id="user-profile-container">
			<Header title={StaticLabels.UP_Title} showAddButton={false} hideSearch={true} />
			<div className="user-profile">
				<div className="section">
					<div id="detail" className="sub-title">
						{StaticLabels.UP_Details}
						{!editProfile && (
							<span className="edit" onClick={editDetails}>
								Edit
							</span>
						)}
					</div>
					{editProfile ? (
						<div className="edit-detail-container">
							<div className="detail-body">
								<div className="edit-detail-label">
									<div className="fname">{StaticLabels.UP_Fname}</div>
									<div className="lname">{StaticLabels.UP_Lname}</div>
									<div className="email">{StaticLabels.UP_Email}</div>
									<div className="phone">{StaticLabels.UP_Phone}</div>
								</div>
								<div className="edit-detail-value">
									<input
										type="text"
										className=""
										value={userInfo.firstName}
										name="firstName"
										onChange={changeUserDetail}
									/>
									<input
										type="text"
										className=""
										value={userInfo.lastName}
										name="lastName"
										onChange={changeUserDetail}
									/>
									<div className="email">{userInfo.email}</div>
									<div className="phone">{userInfo.phone}</div>
								</div>
								<div className="error_field">
									<span>
										{errors['firstName'] ? (
											errors.firstName
										) : errors['lastName'] ? (
											errors.lastName
										) : (
											''
										)}
									</span>
								</div>
							</div>
							<div className="detail-footer">
								<ButtonNormal
									className="cancel-btn edit-btn"
									buttonType="1"
									text={StaticLabels.UP_Cancel_Btn}
									onClick={cancel}
								/>
								<ButtonNormal
									className="edit-btn"
									buttonType="2"
									text={StaticLabels.UP_Save_Btn}
									onClick={saveChanges}
								/>
							</div>
						</div>
					) : (
						<div className="detail-container">
							<div className="detail-label">
								<div className="fname">{StaticLabels.UP_Fname}</div>
								<div className="lname">{StaticLabels.UP_Lname}</div>
								<div className="email">{StaticLabels.UP_Email}</div>
								<div className="phone">{StaticLabels.UP_Phone}</div>
							</div>

							<div className="detail-value">
								<div className="fname">{loggedInUser.firstName}</div>
								<div className="lname">{loggedInUser.lastName}</div>
								<div className="email">{loggedInUser.email}</div>
								<div className="phone">{loggedInUser.phone}</div>
							</div>
						</div>
					)}
				</div>
				<div className="section">
					<div id="organization" className="sub-title">
						{StaticLabels.UP_Organization}
					</div>
					<div className="values">{loggedInUser.organizationName}</div>
				</div>

				<div className="section">
					<div id="roles" className="sub-title">
						{StaticLabels.UP_Role}
					</div>
					<div className="values">{loggedInUser.user_roles && getUserRoles(loggedInUser.user_roles)}</div>
				</div>

				<div className="section">
					<div id="password" className="sub-title">
						{StaticLabels.UP_Password}
					</div>
					<ButtonNormal
						className="password-btn"
						text={StaticLabels.UP_Password_Btn}
						onClick={resetPassword}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo
	};
};

export default connect(mapStateToProps)(UserProfileContainer);

UserProfileContainer.propTypes = {
	loggedInUser: PropTypes.object
};

// import store from '../../store';
// import actionType from '../../utils/actionTypes'
import { apiGet, apiPut, apiPost, apiDelete } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const getOrganization = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetOrganizationList, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const addNewUser = (userDetail) => {
	return new Promise((resolve, reject) => {
		apiPost(apiUrl.UserDetail, userDetail)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const updateUserDetail = (userDetail) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.UserDetail, userDetail)
			.then((resp) => {
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const deleteUserDetails = (userId) => {
	return new Promise((resolve, reject) => {
		apiDelete(`${apiUrl.UserDetail}/${userId}`)
			.then((resp) => {
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

import { apiGet, apiPut } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';
import store from '../../store';
import actionType from '../../utils/actionTypes';

export const getDatasetList = (pageNo, recordsPerPage, researchPartnerId, showActiveSets) => {
	return new Promise((resolve, reject) => {
		const option = {
			pageNumber: pageNo,
			pageSize: recordsPerPage,
			researchPartnerId,
			active: showActiveSets
		};
		apiGet(apiUrl.GetDataset, option)
			.then((resp) => {
				const options = {
					type: actionType.DATA_SET_LIST,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const updateDatasetList = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.UPDATED_DATASET_LIST,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};

export const rejectDataset = (datasetId, imageBytes, reason) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.RejectDataset + datasetId, {
			action_reason: reason, //eslint-disable-line
			digital_signature: imageBytes //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getActiveDataset = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetActiveDataset, {})
			.then((resp) => {
				const options = {
					type: actionType.AVAILABLE_DATA_SET,
					payload: resp.data.payload || ''
				};
				store.dispatch(options);
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const acquireDataSet = (datasetId, imageBytes) => {
	return new Promise((resolve, reject) => {
		apiPut(`${apiUrl.AcquireDataset}?datasetId=${datasetId}`, {
			digital_signature: imageBytes //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const activateDataSet = (datasetId, imageBytes) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.ActivateDataset + datasetId, {
			digital_signature: imageBytes //eslint-disable-line
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const sendOTP = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.SendOTP)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const verifyOTP = (code, otp) => {
	return new Promise((resolve, reject) => {
		apiPut(apiUrl.VerifyOTP, {
			authentication_code: code,
			otp_code: otp
		})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getStatusCount = (researchPartnerId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetStatusCount + researchPartnerId)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getDatasetProfile = (datasetId) => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.GetDatasetProfile.replace('##DATASETID##', datasetId))
			.then((resp) => {
				resolve(resp.data.payload);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getReasonForAction = () => {
	return new Promise((resolve, reject) => {
		apiGet(apiUrl.ReasonsForAction, {})
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
export const getUpdatedDataset = (value) => {
	return new Promise((resolve, reject) => {
		const options = {
			type: actionType.UPDATED_DATASET,
			payload: value
		};
		store.dispatch(options);
		resolve();
	});
};
export const getResearchPartnerInfo = (id) => {
	return new Promise((resolve, reject) => {
		apiGet(`${apiUrl.GetResearchPartnerInfo}/${id}`)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

export const updateDatasetStatus = (datasetDetail) => {
	return new Promise((resolve, reject) => {
		const option = {
			...datasetDetail
		}
		apiPut(`${apiUrl.UpdateDatasetStatus}`, option)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.error(err);
				reject(err);
			});
	});
};

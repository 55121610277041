import React, { useState } from 'react';
import './datasetactivity.scss';
import IconSign from './../../images/sig_icon.svg';
import { format } from 'date-fns';
import SignaturePopup from '../../component/popupsignature/SignaturePopup';
import PropTypes from 'prop-types';

// Possible values for activity type:
// AVAILABLE,
//     ACQUISITION_STARTED,
//     ACQUISITION_COMPLETE,
//     ACQUISITION_FAILED,
//     PROCESSING_STARTED,
//     PROCESSING_COMPLETED,
//     PROCESSING_FAILED

const DatasetActivity = (props) => {
	// const [ datasetActivity, setDatasetActivity ] = useState([]);
	const [ showImage, setShowImage ] = useState(false);
	const [ imageString, setImageString ] = useState('');

	// useEffect(
	// 	() => {
	// 		setDatasetActivity(props.datasetActivity);
	// 	},
	// 	[ props.datasetActivity ]
	// );

	const showSignImage = (imgString) => {
		setShowImage(true);
		setImageString(imgString);
	};

	const closeImage = () => {
		setShowImage(false);
	};

	return (
		<div className="">
			<div className="list-wrapper">
				<div className="list">
					{props.datasetActivity && props.datasetActivity.length > 0 && props.datasetActivity.map((data, index) => {
						const actType = data.activityType.toLowerCase();
						return (
							<div className="activity" key={`${actType}_${index}`}>
								<div
									className={`bullets ${actType === 'available'
										? 'available'
										: actType === 'acquisition_started'
											? 'acquisition-started'
											: actType === 'acquisition_complete'
												? 'acquisition-complete'
												: actType === 'acquisition_failed'
													? 'acquisition-failed'
														: actType === 'processing_started'
															? 'processing-started'
															: actType === 'processing_completed'
																? 'processing-complete'
																: actType === 'processing_failed'
																	? 'processing-failed'
																	: actType === 'activated'
																		? 'activated'
																		: actType === 'inactivated'
																			? 'inactivated'
																			: actType === 'rejected' ? 'rejected' : ''}`}
								/>
								<div className={`${index === props.datasetActivity.length - 1 ? '' : 'line'}`} />
								<div className="activity-description">
									<span className="time">
										{format(new Date(data.activityDate), 'MM/dd/yy hh:mm a z')}
									</span>
									<br />
									<span>
										{' '}
										{data.activityDescription}{' '}
										{data.signatureBase64 ? (
											<img
												className="sign-icon"
												src={IconSign}
												alt="Sign icon"
												onClick={() => showSignImage(data.signatureBase64)}
											/>
										) : (
											''
										)}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{showImage && <SignaturePopup imageString={imageString} handleClose={closeImage} />}
		</div>
	);
};

export default DatasetActivity;

DatasetActivity.propTypes = {
	datasetActivity: PropTypes.array
}
const domain = window.location.origin;
const apiUrl = domain.indexOf('localhost') === -1 ? domain + '/api' : 'http://localhost:8080';
const websocketUrl = domain.indexOf('localhost') === -1 ? domain + '/websocket' : 'http://localhost:8080/websocket';

export const AUTH_CONFIG = {
	domainName: 'verantos.auth0.com',
	// clientID : 'IZHqPsz4PKqQz8Oh0PHx2H0RfQCshDaw',
	clientID: 'uNRJFpIKLbLnFGDYqWGAjuI64qwpFzVb',
	callbackUrl: domain,
	API_URL: apiUrl,
	returnTo: domain,
	connection: 'Verantos-System-Admin-Staging',
	webSocketUrl: websocketUrl
};

export default AUTH_CONFIG;

import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const getNotificationList = (pageNo, recordsPerPage) => {
	return new Promise((resolve, reject) => {
		const option = {
			pageNumber: pageNo,
			pageSize: recordsPerPage
		};
		apiGet(apiUrl.GetNotificationList, option)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
import React from 'react';
import logo from '../../images/logo.svg';
import StaticLabels from '../../utils/messages';
import './mfa.scss';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';

function MFAContainer(props) {
	return (
		<div id="main">
			<form onSubmit={() => {}}>
				<section className="mfa-container">
					<div className="mfa">
						<h1 className="mfa_header">
							<img className="logo_img" src={logo} alt="Verantos_Logo" />
							<span className="text">{StaticLabels.Verification_Code}</span>
						</h1>
						<p className="mfa-info">
							<span>{StaticLabels.MFA_Info_Text}</span>
						</p>
						<br />
						<fieldset id="reset" name="otp-screen" className="">
							<span className="digit-group">
								<input type="text" />
								<input type="text" />
								<input type="text" />
								<input type="text" />
								<input type="text" />
								<input type="text" />
							</span>
							<p className="resend-otp">
								<span>{StaticLabels.MFA_Resend}</span>
							</p>
							<ButtonNormal
								buttonType="2"
								type="submit"
								text={StaticLabels.MFA_Btn}
								name="commit"
								value="Sign in"
								className="button"
								onClick={() => {}}
							/>
						</fieldset>
					</div>
				</section>
			</form>
		</div>
	);
}

export default MFAContainer;

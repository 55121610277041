import React from 'react';
import Select, { components, createFilter } from 'react-select';
import PropTypes from 'prop-types';

const CustomSelect = (props) => {
	const customStyles =
		// props.className === 'pagination-records'
		props.className === 'study-select' || props.className === 'sign-select'
			? {
					control: (provided, state) => ({
						...provided,
						background: '#fff',
						border: state.isFocused
							? '1px solid rgba(156, 156, 156, 0.5)'
							: '1px solid rgba(156, 156, 156, 0.5)',
						minHeight: '40px',
						height: '40px',
						boxShadow: state.isFocused ? null : null,
						'&:hover': {
							border: state.isFocused ? null : null
						},
						borderRadius: '5px 5px 0px 0px ',
					}),
					valueContainer: (provided, state) => ({
						...provided,
						height: '40px',
						padding: '0 6px'
					}),
					input: (provided, state) => ({
						...provided,
						margin: '0px',
						padding: '10px 0',
						minHeight: '40px',
						height: '40px'
					}),
					indicatorSeparator: (state) => ({
						display: 'none'
					}),
					indicatorsContainer: (provided, state) => ({
						...provided,
						height: '40px',
						fontSize: '18px'
					}),
					menu: (provided) => ({
						...provided,
						zIndex: 50,
						borderRadius: '0px 0px 5px 5px',
					})
				}
			: {
					control: (provided, state) => ({
						...provided,
						background: '#fff',
						border: state.isFocused
							? '1px solid rgba(156, 156, 156, 0.5)'
							: '1px solid rgba(156, 156, 156, 0.5)',
						minHeight: '40px',
						height: '40px',
						//   boxShadow: state.isFocused ? null : null,
						'&:hover': {
							border: state.isFocused ? null : null
						},
						borderRadius: '5px',
						boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.12)'
					}),
					valueContainer: (provided, state) => ({
						...provided,
						height: '40px',
						padding: '0 6px'
					}),
					input: (provided, state) => ({
						...provided,
						margin: '0px',
						minHeight: '40px',
						height: '40px'
					}),
					indicatorSeparator: (state) => ({
						display: 'none'
					}),
					indicatorsContainer: (provided, state) => ({
						...provided,
						height: '40px',
						fontSize: '18px'
					}),
					menu: (provided) => ({
						...provided,
						zIndex: 50,
						borderRadius: '0px 0px 5px 5px',
					}),
					menuList: (provided) => ({
						...provided,
						paddingTop: '0px',
					}),	
				};

	const DropdownIndicator = (prop) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...prop}>
					{prop.selectProps.menuIsOpen ? (
						<i className="fas fa-caret-up" />
					) : (
						<i className="fas fa-caret-down" />
					)}
				</components.DropdownIndicator>
			)
		);
	};

	return (
		<Select
			name="customSearch"
			maxMenuHeight={150}
			className={props.className || ''}
			placeholder={props.placeholder}
			options={props.options}
			value={props.value}
			onChange={props.onChange}
			isDisabled={props.isDisabled}
			styles={customStyles}
			isSearchable={props.isSearchable}
			filterOption={createFilter({ matchFrom: 'start' })}
			menuPlacement= {props.menuPlacement || 'bottom'}
			// menuIsOpen= {true}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				colors: {
					color: 'neutral0',
					...theme.colors,
					primary: '#2E3F55'
				}
			})}
			components={{ DropdownIndicator }}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	);
};
export default CustomSelect;

CustomSelect.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
	value: PropTypes.any,
	onChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	isSearchable: PropTypes.bool,
	selectProps: PropTypes.func,
	menuPlacement: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func
};
import React from 'react';
import './sortcolumn.scss';
import PropTypes from 'prop-types'

const SortColumn = (props) => {
	const { columnName, sortOrder, sortedColumnName } = props;
	const sortFunc = (columName, order) => {
		if (props.sortColumn) {
			props.sortColumn(columName, order);
		}
	};
	const sortAsc = (event) => {
		sortFunc(columnName, 'asc');
		event.stopPropagation();
	};
	const sortDsc = (event) => {
		event.stopPropagation();
		sortFunc(columnName, 'dsc');
	};
	const toggleSort = (event) => {
		event.stopPropagation();
		if(columnName !== 'userRoles'){
			if (sortOrder === 'asc') {
				sortFunc(columnName, 'dsc');
			} else {
				sortFunc(columnName, 'asc');
			}
		}
	};

	return (
		<div className={props.className}>
			<div className="sort-container">
				<div className="text-container" onClick={toggleSort}>
					{props.displayName}
				</div>
				{columnName !== 'userRoles' && (
					<div className="icon-cotainer">
						<div className="icon-sort" onClick={sortAsc}>
							{columnName === sortedColumnName && sortOrder === 'asc' ? (
								<i className="fas fa-caret-up sorted" />
							) : (
								<i className="fas fa-caret-up unsorted" />
							)}
						</div>
						<div className="icon-sort" onClick={sortDsc}>
							{columnName === sortedColumnName && sortOrder === 'dsc' ? (
								<i className="fas fa-caret-down sorted" />
							) : (
								<i className="fas fa-caret-down unsorted" />
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SortColumn;

SortColumn.propTypes = {
	columnName: PropTypes.string,
	sortOrder: PropTypes.string,
	sortedColumnName: PropTypes.string,
	displayName: PropTypes.string,
	sortColumn: PropTypes.func,
	className: PropTypes.string
}
import store from '../../store';
import actionTypes from '../../utils/actionTypes';
import { apiGet, apiPut, apiPost, apiDelete } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const getResearchPartnerTypes = () => {
    return new Promise((resolve, reject) => {
        apiGet(apiUrl.GetResearchPartnerTypes, {}).then((resp) => {
            const options = {
                type: actionTypes.RP_TYPE_LIST,
                payload: resp.data.payload || ''
            }
            store.dispatch(options)
            resolve(resp);
        }).catch((err) =>{
            console.log(err);
            reject(err);
        })
    })
}

export const addNewResearchPartner = (researchPartnerDetail, attributes) => {
    return new Promise((resolve, reject) => {
        const option = {
            ...researchPartnerDetail,
            attributes: {
                S3_BUCKET_NAME: attributes.senderId,
                SQS_QUEUE_URL: attributes.sqsQueueUrl
            }
        }
        apiPost(apiUrl.ResearchPartnerDetail, option).then((resp) =>{
            resolve(resp)
        }).catch((err) =>{
            console.log(err);
            reject(err);
        })
    })
}

export const updateResearchPartnerDetail = (researchPartnerDetail, attributes) => {
    return new Promise((resolve, reject) => {
        const option = {
            ...researchPartnerDetail,
            attributes: {
                S3_BUCKET_NAME: attributes.senderId,
                SQS_QUEUE_URL: attributes.sqsQueueUrl,
                UPTIME_AVAILABILITY: attributes.uptimeAvailability
            }
        }
        apiPut(apiUrl.ResearchPartnerDetail, option).then((resp) =>{
            resolve(resp);
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    }) 
}

export const deleteResearchPartnerDetails = (id) => {
    return new Promise((resolve,reject) => {
        apiDelete(`${ apiUrl.ResearchPartnerDetail }/${ id }`).then((resp) => {
            resolve()
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
import store from '../../store';
import actionType from '../../utils/actionTypes'
import { apiGet } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl';

export const getUserlist = (pageNo, recordsPerPage, searchText, sortedColumn, isDescending) => {
    return new Promise((resolve, reject)=>{
        const option = {
            pageNumber:pageNo,
            pageSize:recordsPerPage,
            searchQuery: searchText,  
            sort_by: sortedColumn,  // eslint-disable-line
            descending: isDescending
        }
        apiGet(apiUrl.GetUserList, option).then((resp)=>{
            const options = {
                type: actionType.USER_LIST,
                payload: resp.data.payload || ''
            };
            store.dispatch(options);
            resolve(resp);
        }).catch((err) => {
			console.log(err);
            reject(err);
        })
    });
}

export const getUserdetail = (userId) =>{
    return new Promise((resolve, reject) => {
        apiGet(`${ apiUrl.GetUserInfo }/${ userId }`).then(resp => {
            resolve(resp);
        }).catch((err) => {
            console.error(err);
            reject(err);
        })
    })
};
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import './researchnetwork.scss';
import { connect } from 'react-redux';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import ReasearchDataset from '../../component/researchdataset/ResearchDataset';
import ModalComponent from '../../component/modal/ModalComponent';
import StaticLabels from '../../utils/messages';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import { getNotificationList } from '../sidebar/sidebarHelper';
import { getNotificationListForRP } from '../../utils/commonUtils';

// import PaginationComponent from '../../component/pagination/PaginationComponent';
import {
	getDatasetList,
	rejectDataset,
	getActiveDataset,
	acquireDataSet,
	activateDataSet,
	sendOTP,
	verifyOTP,
	getStatusCount,
	getReasonForAction,
	updateDatasetList,
	getUpdatedDataset,
	getResearchPartnerInfo,
	updateDatasetStatus,
	getDatasetDetail
} from './researchNetworkHelper';
import { listenChange as listenChanges } from './../sidebar/sidebarHelper';

const InfiniteScroll = lazy(() => import('../../component/infiniteScroll/InfiniteScroll'));

const legendTypes = {
	AVAILABLE: 'Available',
	ACQUIRING: 'Acquiring',
	ACTIVE: 'Active',
	PROCESSING: 'Processing',
	PROCESSED: 'Processed'
};

const legendTypesKey = {
	AVAILABLE: 1,
	ACQUIRING: 2,
	ACTIVE: 3,
	PROCESSING: 4,
	PROCESSED: 5
};

const defaultCounts = {
	Active: 0,
	Available: 0,
	Acquisition: 0,
	Processing: 0,
	Processed: 0
};

const ResearchNetworkContainer = (props) => {
	const { datasetList, updatedDataset, listenChange } = props;
	const [ showActiveSets, setShowActiveSets ] = useState(true);
	// const [ datasetList, setDatasetList ] = useState([]);
	// const [ totalItemsCount, setTotalItemsCount ] = useState(0);
	const [ pageNo, setPageNo ] = useState(1);
	// const [ recordPerPage, setRecordsPerPage ] = useState(10);
	const recordPerPage = 10;
	const [ defaultTab, setDefaultTab ] = useState(false);
	const [ statusCount, setStatusCount ] = useState(defaultCounts);
	const researchPartnerId = props.match.params.id;
	const [ actionReason, setActionReason ] = useState([]);

	const [ acquireDS, setAcquireDS ] = useState(false);
	const [ haveMoreDatasets, setHaveMoreDatasets ] = useState(false);
	const [ loading, setloading ] = useState(false);
	const isInitialMount = useRef(true);
	const [ refresh, setRefresh ] = useState(false);
	const [ datasetErrorMsg, setDatasetErrorMsg ] = useState('');
	const [ datasetError, setDatasetError ] = useState(false);
	const [ invalidRPError, setInvalidRPError ] = useState('');

	const setNextPage = () => {
		setPageNo((prevPageNo) => prevPageNo + 1);
	};

	const getCounts = (researchPartnrId) => {
		getStatusCount(researchPartnrId).then((resp) => {
			setStatusCount(resp.data.payload);
		});
	};

	const getRecords = (noloader) => {
		if (researchPartnerId) {
			if (noloader) {
				showLoader(true);
			}
			const pageNum = 1;
			const pgSize = 10;
			if (pageNo === 1) {
				getNotificationList(researchPartnerId, pageNum, pgSize).then((resp) => {
					const message = [];
					const notificationIds = [];
					if (resp.data.payload.length) {
						resp.data.payload.map((list) => {
							if (list.readByUser === false) {
								notificationIds.push(list.id);
								message.push({ id: list.id, msg: list.message, status: list.type.toLowerCase() });
							} else {
								return;
							}
						});
						getNotificationListForRP(message);
					}
				});
			}
			getDatasetList(pageNo, recordPerPage, researchPartnerId, showActiveSets)
				.then((resp) => {
					// let updatedList = [ ...datasetList, ...resp.data.payload ];
					// setDatasetList(updatedList);
					setHaveMoreDatasets(resp.data.totalEntries > recordPerPage * pageNo);

					// setTotalItemsCount(resp.data.totalEntries);
					// const profileInfo = resp.data.payload.map((dataset) => {
					// 	if (dataset.status === 'ACTIVE') {
					// 		getProfileDetail(dataset.datasetId);
					// 	}
					// });
					if (showActiveSets && resp.data.payload.length === 0) {
						if (defaultTab) {
							setloading(false);
						} else if (!defaultTab) {
							setDefaultTab(true);
						}
					} else if (!showActiveSets && resp.data.payload.length === 0) {
						if (!defaultTab) {
							setloading(false);
						} else {
							setShowActiveSets(true);
							setloading(false);
						}
						// showLoader(false);
					} else {
						setloading(false);
					}
				})
				.catch((err) => {
					if (err.status === 404) {
						setInvalidRPError(StaticLabels.DS_InvalidRP_Error);
					}
					setloading(false);
					showLoader(false);
				});
			getCounts(researchPartnerId);
			getReasonForAction()
				.then((resp) => {
					const updatedList = resp.data.payload.map((reason) => {
						return {
							label: reason,
							value: reason
						};
					});
					setActionReason(updatedList);
				})
				.catch((err) => {
					console.log(err);
				});
			showLoader(false);
		}
	};
	useEffect(() => {
		updateDatasetList([]);
		getUpdatedDataset({});
		getRecords(true);
		getCounts(researchPartnerId);
	}, []);

	useEffect(
		() => {
			showLoader(true);
			if (defaultTab) {
				setShowActiveSets(false);
			}
		},
		[ defaultTab ]
	);

	useEffect(
		() => {
			if (isInitialMount.current) {
				showLoader(false);
				setloading(true);
				// getRecords();
				isInitialMount.current = false;
			} else {
				if (!invalidRPError) {
					setloading(true);
					setTimeout(() => {
						getRecords();
					}, 1000);
				}
			}
		},
		[ acquireDS, pageNo, recordPerPage, showActiveSets ]
	);

	useEffect(
		() => {
			getActiveDataset().then((resp) => {
				const activeRp = resp.data.payload.filter((rp) => rp.id.toString() === researchPartnerId);
			});
		},
		[ datasetList, listenChange ]
	);

	const rejectDatasetClick = (datasetId, imageBytes, reason) => {
		showLoader(true);
		if (datasetId) {
			rejectDataset(datasetId, imageBytes, reason)
				.then((resp) => {
					showLoader(false);
				})
				.catch((err) => {
					if (err.data.status === 'Error') {
						setDatasetError(true);
						setDatasetErrorMsg(err.data.errorDetails.message);
					}
					showLoader(false);
				});
		}
	};

	const acquireDataSetById = (datasetId, imageBytes, researchPartnerId, datasetName) => {
		acquireDataSet(datasetId, imageBytes)
			.then((resp) => {
				if (resp.status === 200) {
					getResearchPartnerInfo(researchPartnerId).then((resp) => {
						const dataset = {
							datasetName: datasetName,
							senderId: resp.data.payload.attributes.S3_BUCKET_NAME,
							status: 'ACQUISITION_REQUESTED',
							lastActionSignature: imageBytes
						};
						updateDatasetStatus(dataset);
					});

					listenChanges(!listenChange);
					showLoader(false);
				}
			})
			.catch((err) => {
				if (err.data.status === 'Error') {
					setDatasetError(true);
					setDatasetErrorMsg(err.data.errorDetails.message);
				}
				showLoader(false);
			});
	};

	const activateDataset = (datasetId, imageBytes) => {
		showLoader(true);
		activateDataSet(datasetId, imageBytes)
			.then((resp) => {
				showLoader(false);
			})
			.catch((err) => {
				if (err.data.status === 'Error') {
					setDatasetError(true);
					setDatasetErrorMsg(err.data.errorDetails.message);
				}
				showLoader(false);
			});
	};

	const sendOtp = () => {
		return new Promise((resolve, reject) => {
			showLoader(true);
			sendOTP()
				.then((resp) => {
					showLoader(false);
					resolve(resp.data.payload);
				})
				.catch((err) => {
					reject(err);
					showLoader(false);
				});
		});
	};

	const verfiyOtp = (code, otp) => {
		// verifyOTP(code,otp).
		return new Promise((resolve, reject) => {
			showLoader(true);
			verifyOTP(code, otp)
				.then((resp) => {
					showLoader(false);
					resolve(resp.data.payload);
				})
				.catch((err) => {
					reject(err);
					showLoader(false);
				});
		});
	};

	useEffect(
		() => {
			if (
				updatedDataset &&
				Object.keys(updatedDataset).length &&
				updatedDataset.researchPartnerId.toString() === researchPartnerId &&
				!updatedDataset.hasOwnProperty('studyStatus')
			) {
				let newDatasetList = datasetList;
				const datasetIndex = newDatasetList.findIndex((ds) => ds.datasetId == updatedDataset.datasetId);
				if (datasetIndex === -1) {
					if (showActiveSets) {
						if (updatedDataset.status === 'AVAILABLE') {
							newDatasetList = [ updatedDataset, ...datasetList ];
							if (newDatasetList.length > 10) {
								if (pageNo === 1) {
									newDatasetList.splice([ newDatasetList.length - 1 ], 1);
									setHaveMoreDatasets(true);
								} else if (haveMoreDatasets && newDatasetList.length % 10 !== 0) {
									newDatasetList.splice([ newDatasetList.length - 1 ], 1);
								} else if (!haveMoreDatasets && newDatasetList.length % 10 === 1) {
									setHaveMoreDatasets(true);
									newDatasetList.splice([ newDatasetList.length - 1 ], 1);
								}
							}
						} else {
							newDatasetList.push(updatedDataset);
							newDatasetList.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
							const sortedIndex = newDatasetList.findIndex(
								(ds) => ds.datasetId == updatedDataset.datasetId
							);
							if (newDatasetList.length > 10) {
								if (sortedIndex === newDatasetList.length - 1) {
									if (haveMoreDatasets) {
										newDatasetList.splice(sortedIndex, 1);
									} else if (!haveMoreDatasets && newDatasetList.length % 10 === 1) {
										setHaveMoreDatasets(true);
										newDatasetList.splice(sortedIndex, 1);
									}
								}
							}
						}
					}
					if (!showActiveSets) {
						if (updatedDataset.status === 'INACTIVE' || updatedDataset.status === 'REJECTED') {
							newDatasetList.push(updatedDataset);
							newDatasetList.sort(
								(a, b) => Date.parse(new Date(b.dateCreated)) - Date.parse(new Date(a.dateCreated))
							);
							const sortedIndex = newDatasetList.findIndex(
								(ds) => ds.datasetId == updatedDataset.datasetId
							);

							if (newDatasetList.length > 10) {
								if (sortedIndex === newDatasetList.length - 1) {
									if (haveMoreDatasets) {
										newDatasetList.splice(sortedIndex, 1);
									} else if (!haveMoreDatasets && newDatasetList.length % 10 === 1) {
										setHaveMoreDatasets(true);
										newDatasetList.splice(sortedIndex, 1);
									}
								}
							}
						}
					}
				} else if (datasetList !== -1) {
					if (
						(!showActiveSets && updatedDataset.status === 'REJECTED') ||
						(!showActiveSets && updatedDataset.status === 'INACTIVE')
					) {
						newDatasetList.splice(datasetIndex, 1, updatedDataset);
					} else if (
						(showActiveSets &&
							(updatedDataset.status === 'REJECTED' || updatedDataset.status === 'INACTIVE')) ||
						(!showActiveSets &&
							(updatedDataset.status !== 'REJECTED' || updatedDataset.status !== 'INACTIVE'))
					) {
						newDatasetList.splice(datasetIndex, 1);
					} else {
						newDatasetList.splice(datasetIndex, 1, updatedDataset);
					}
				}
				updateDatasetList(newDatasetList);
				setRefresh(!refresh);
			}
		},
		[ updatedDataset ]
	);

	return (
		<div className="research-network-container">
			<div className="header-container">
				<div className="status-tabs">
					<div
						id="active-dataset-tab"
						className={`dataset-status ${showActiveSets ? 'active' : ''}`}
						onClick={() => {
							if (!loading) {
								if (!showActiveSets) {
									updateDatasetList([]);
									setPageNo(1);
									setShowActiveSets(true);
								} else {
									updateDatasetList([]);
									setAcquireDS(!acquireDS);
									setPageNo(1);
								}
							}
						}}
					>
						Active data sets
					</div>
					<div
						id="inactive-dataset-tab"
						className={`dataset-status ${!showActiveSets ? 'active' : ''}`}
						onClick={() => {
							if (!loading) {
								if (showActiveSets) {
									updateDatasetList([]);
									setShowActiveSets(false);
									setPageNo(1);
								} else {
									updateDatasetList([]);
									setAcquireDS(!acquireDS);
									setPageNo(1);
								}
							}
						}}
					>
						Inactive data sets
					</div>
				</div>
				<div className="legends">
					<Legend type="AVAILABLE" value={statusCount.Available} />
					<Legend type="ACQUIRING" value={statusCount.Acquisition} />
					<Legend type="ACTIVE" value={statusCount.Active} />
					<Legend type="PROCESSING" value={statusCount.Processing} />
					<Legend type="PROCESSED" value={statusCount.Processed} />
				</div>
			</div>

			{invalidRPError ? (
				<div className="unauthorised-user-forRP">
					<h3>{invalidRPError}</h3>
				</div>
			) : (
				datasetList.length > 0 &&
				datasetList.map((item) => {
					const dsDetail = { ...item };

					return (
						<ReasearchDataset
							{...dsDetail}
							refresh
							key={`${item.datasetId}_${item.status}`}
							rejectDatasetClick={rejectDatasetClick}
							acquireDataSet={acquireDataSetById}
							activateDataset={activateDataset}
							sendOTP={sendOtp}
							verfiyOtp={verfiyOtp}
							researchPartnerId={researchPartnerId}
							actionReason={actionReason}
							showDetail={false}
						/>
					);
				})
			)}
			<Suspense fallback={<div>Loading...</div>}>
				<InfiniteScroll
					id="dataset-list"
					loading={loading}
					haveMoreRecords={haveMoreDatasets}
					setNextPage={setNextPage}
				/>
			</Suspense>

			{!loading &&
			!haveMoreDatasets &&
			pageNo > 1 && (
				<div id="no-more-records" className="no-records no-more-records">
					No more records.
				</div>
			)}
			{!invalidRPError.length &&
			!loading &&
			datasetList.length === 0 && (
				<div id="no-more-records" className="no-records no-more-records">
					No records found.
				</div>
			)}
			{loading && (
				<div className="loading-list">
					<div className="loading">loading</div>
					<div className="dot-pulse" />
				</div>
			)}
			{datasetError && (
				<ModalComponent
					open={true}
					className="reject-modal"
					handleClose={() => {
						setDatasetError(false);
					}}
					header={'  '}
					closeButton={true}
					content={
						<div>
							<p>{datasetErrorMsg}</p>
						</div>
					}
					footer={
						<React.Fragment>
							<ButtonNormal
								className="buttons"
								onClick={() => {
									setDatasetError(false);
								}}
								text={StaticLabels.DS_Error_Confirm}
							/>
						</React.Fragment>
					}
				/>
			)}
			{
				// <PaginationComponent totalItemsCount={totalItemsCount} gotoPage={gotoPage} currentPage={pageNo} />
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		datasetList: state.redCommon.datasetList,
		listenChange: state.redCommon.listenChange,
		updatedDataset: state.redCommon.updatedDataset,
		notificationList: state.redCommon.notificationList
	};
};

export default connect(mapStateToProps)(ResearchNetworkContainer);

ResearchNetworkContainer.propTypes = {
	loggedInUser: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.any
		})
	}),
	listenChange: PropTypes.bool,
	datasetList: PropTypes.array,
	updatedDataset: PropTypes.object,
	notificationList: PropTypes.array
};

const Legend = (props) => {
	return (
		<div className="legend-container">
			<div className={`counts type_${legendTypesKey[props.type]}`}>{props.value}</div>
			<div className={`status-text type-${legendTypesKey[props.type]}`}>{legendTypes[props.type]}</div>
		</div>
	);
};

Legend.propTypes = {
	type: PropTypes.string,
	value: PropTypes.number
};

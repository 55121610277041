import React, { useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import auth0 from 'auth0-js';
import AUTH_CONFIG from '../../constants';
import logo from '../../images/logo.svg';
import './forgotpassword.scss';
import StaticLabels from '../../utils/messages';
import { showLoader } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';

function ForgetPasswordContainer(props) {
	const [ user, setUser ] = useState({
		email: ''
	});
	const [ err, setErr ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');
	const [ userPlaceholder, setUserPlaceholder ] = useState(StaticLabels.FP_Email_Placeholder);
	const [ isEmailSent, SetIsEmailSent ] = useState(false);
	const { email } = user;

	const sendEmail = () => {
		showLoader(true);
		const webAuth = new auth0.WebAuth({
			domain: AUTH_CONFIG.domainName,
			clientID: AUTH_CONFIG.clientID
		});

		// Validating the change password api end point for Auth0.
		webAuth.changePassword(
			{
				connection: AUTH_CONFIG.connection,
				email: email
			},
			(error, authResult) => {
				setErr('');
				SetIsEmailSent(true);
				setSuccessMessage(StaticLabels.FP_Success);
				showLoader(false);
			}
		);
	};

	const validate = (event) => {
		event.preventDefault();
		setErr('');
		setSuccessMessage('');
		if (!email.trim()) {
			setErr(StaticLabels.FP_Error_Email);
			return;
		}

		if (email) {
			const emailReg = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
			const res = emailReg.test(email);
			if (!res) {
				setErr(StaticLabels.FP_Error_ValidEmail);
				return;
			}
		}
		sendEmail();
	};

	const handleKeyPress = (event) => {
		if (event.charCode === 13) {
			validate(event);
		}
	};

	const updateValue = (e) => {
		const value = e.target.value;
		setUser({
			...user,
			[e.target.name]: value
		});
		setErr('');
	};

	const gotoLogin = () => {
		if (props.history) {
			props.history.push('/');
		}
	};

	return (
		<div id="main">
			<form onSubmit={validate}>
				<section className="forget-container">
					<div className="forget">
						<h1 className="forget_header">
							<img className="logo_img" src={logo} alt="Verantos_Logo" />
							<span className="text">{StaticLabels.Forgot_Passord}</span>
						</h1>
						{isEmailSent && (
							<fieldset id="forget_form" name="forget_form" className="forget_form">
								<div className="msg_field">{successMessage}</div>
								<br />
								<br />
								<br />
								<p>
									<span onClick={gotoLogin} className="forgot">
										{StaticLabels.FP_Back_Login}
									</span>
								</p>
							</fieldset>
						)}
						{!isEmailSent && (
							<React.Fragment>
								<p>{StaticLabels.FP_Info_text}</p>
								<br />
								<br />
								<br />
								<fieldset id="forget_form" name="forget_form" className="forget_form">
									<p>
										<FormControl
											type="text"
											name="email"
											className="input"
											id="email"
											autoFocus
											onBlur={() => setUserPlaceholder(StaticLabels.FP_Email_Placeholder)}
											// onFocus={() => setUserPlaceholder('')}
											placeholder={userPlaceholder}
											onChange={updateValue}
											value={email}
											onKeyPress={handleKeyPress}
										/>
									</p>
									{
										<div className="msg_field">
											{err ? <span className="error">{err}</span> : <span>{successMessage}</span>}
										</div>
									}
									<p className="submit">
									<ButtonNormal
										buttonType="2"
										type="submit"
										name="commit"
										value="Sign in"
										id="forget"
										className="button"
										onClick={validate}
										onKeyPress={handleKeyPress}
										text={StaticLabels.FP_Btn}
									/>
									</p>
									<p>
										<span onClick={gotoLogin} className="forgot">
											{StaticLabels.FP_Back_Login}
										</span>
									</p>
								</fieldset>
							</React.Fragment>
						)}
					</div>
				</section>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(ForgetPasswordContainer);

ForgetPasswordContainer.propTypes = {
	history: PropTypes.array
};

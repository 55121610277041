import { combineReducers } from 'redux';
import UserAuth from './UserAuth';
import UserInfo from './UserInfo';
import CommonReducer from './CommonReducer';

const rootReducer = combineReducers({
    redAuth: UserAuth,
    redUserInfo: UserInfo,
    redCommon: CommonReducer,
});

export default rootReducer;
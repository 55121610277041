import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const toastOptions = {
	position: 'top-center',
	autoClose: false,
	hideProgressBar: true,
	closeOnClick: false,
	pauseOnHover: false,
	draggable: false,
	progress: undefined,
	className: '',
	onClose: () => {}
};

const iconClass = (status) => {
	switch (status) {
		case 'available':
			return 'available-icon';
		case 'acquisition_requested':
		case 'acquiring':
			return 'acquisition-icon';
		case 'processed':
			return 'processed-icon';
		case 'rejected':
			return 'rejected-icon';
		case 'inactive':
			return 'inactive-icon';
		case 'acquisition_failed':
		case 'processing_failed':
		case 'error':
			return 'error-icon';
		case 'active':
			return 'active-icon';
		case 'processing':
			return 'processing-icon';
		default: return;
	}
};

export function showToast(message, toastId, onClickToast) {
	const msgCount = message.length;

	const onClickViewAll = () => {
		var element = document.getElementsByClassName('toast-container success-ds');
		var child = element[0].firstChild.firstChild;
		child.classList.add('toast-msg-container-viewall');

		var viewLink = element[0].firstChild.lastChild;
		viewLink.firstChild.innerHTML = `Viewing ${msgCount} of ${msgCount}`;
		viewLink.lastChild.classList.add('hide-view-link');
	};

	toastId.current = toast(
		<React.Fragment>
			<div className="toast-msg-container">
				{message.map((m) => (
					<div className="toast-msg" key={`msg_${m.msg}`}>
						{m.status === 'acquisition_failed' ||
						m.status === 'processing_failed' ||
						m.status === 'error' ||
						m.status === 'rejected' ||
						m.status === 'inactive' ? (
							<i className={`fa fa-exclamation toast-icon ${iconClass(m.status)}`} />
						) : (
							<i className={`fa fa-check toast-icon ${iconClass(m.status)}`} aria-hidden="true" />
						)}
						<span className="capitalise">{m.status.replace('_', ' ')}: </span> {m.msg}
					</div>
				))}
			</div>
			{msgCount <= 3 ? (
				<div className="record-text">
					<i>
						Viewing {msgCount} of {msgCount}
					</i>
				</div>
			) : msgCount > 3 ? (
				<div className="record-text">
					<i>Viewing 3 of {msgCount}</i>
					<span className="view-link" onClick={onClickViewAll}>
						View all
					</span>
				</div>
			) : (
				''
			)}
		</React.Fragment>,
		toastOptions,
		(toastOptions.className = 'toast-container success-ds'),
		(toastOptions.onClose = onClickToast)
	);

	return toastId.current;
}

export const updateToast = (message, toastId, onClickToast) => {
	const msgCount = message.length;

	const onClickViewAll = () => {
		var element = document.getElementsByClassName('toast-container success-ds');
		var child = element[0].firstChild.firstChild;
		child.classList.add('toast-msg-container-viewall');

		var viewLink = element[0].firstChild.lastChild;
		viewLink.firstChild.innerText = `Viewing ${msgCount} of ${msgCount}`;
		viewLink.lastChild.classList.add('hide-view-link');
	};

	const viewText = () => {
		var recordText = document.getElementsByClassName('record-text');

		recordText[0].firstChild.innerHTML = `Viewing ${msgCount} of ${msgCount}`;

		return (
			<div className="record-text">
				<i>Viewing {msgCount}</i>
			</div>
		);
	};

	toast.update(
		toastId.current,
		{
			render: (
				<React.Fragment>
					<div className="toast-msg-container">
						{message.map((m) => (
							<div className="toast-msg" key={`msg_${m.msg}`}>
								{m.status === 'acquisition_failed' ||
								m.status === 'processing_failed' ||
								m.status === 'rejected' ||
								m.status === 'inactive' ||
								m.status === 'error' ? (
									<i className={`fa fa-exclamation toast-icon ${iconClass(m.status)}`} />
								) : (
									<i className={`fa fa-check toast-icon ${iconClass(m.status)}`} aria-hidden="true" />
								)}
								<span className="capitalise">{m.status.replace('_', ' ')}: </span> {m.msg}
							</div>
						))}
					</div>

					{msgCount <= 3 ? (
						<div className="record-text">
							<i>
								Viewing {msgCount} of {msgCount}
							</i>
						</div>
					) : msgCount >= 4 &&
					document.getElementsByClassName('view-link hide-view-link').length === 0 &&
					document.getElementsByClassName('toast-msg-container-viewall').length === 0 ? (
						<div className="record-text">
							<i>Viewing 3 of {msgCount}</i>
							{
								<span className="view-link" onClick={onClickViewAll}>
									View all
								</span>
							}
						</div>
					) : (
						viewText()
					)}
				</React.Fragment>
			)
		},
		toastOptions,
		(toastOptions.className = 'toast-container success-ds'),
		(toastOptions.onClose = onClickToast)
	);
	return <div />;
};

export function showSuccess(message) {
	toast.success(
		message,
		toastOptions,
		(toastOptions.autoClose = 10000),
		(toastOptions.closeOnClick = true),
		(toastOptions.className = 'toast-notification success')
	);
}

export function showError(message) {
	toast.error(
		message,
		toastOptions,
		(toastOptions.autoClose = 10000),
		(toastOptions.closeOnClick = true),
		(toastOptions.className = 'toast-notification error')
	);
}

import store from '../../store';
import actionType from '../../utils/actionTypes'
import { apiGet, apiPut, apiPost, apiDelete } from '../../utils/apiRequest';
import apiUrl from '../../utils/apiUrl'

export const getCustomerTypes = () => {
    return new Promise((resolve, reject)=>{
        apiGet(apiUrl.GetCustomerTypes, {}).then((resp)=>{
            const options = {
                type: actionType.CUSTOMER_TYPE_LIST,
                payload: resp.data.payload || ''
            }
            store.dispatch(options)
            resolve(resp);
        }).catch((err) => {
			console.log(err);
            reject(err);
        })
    });
}

export const addNewCustomer = (customerDetail) => {
    return new Promise((resolve, reject) => {
        apiPost(apiUrl.CustomerDetail, customerDetail).then((resp) =>{
            resolve(resp)
        }).catch((err) =>{
            console.log(err);
            reject(err);
        })
    })
}

export const updateCustomerDetail = (customerDetail) => {
    return new Promise((resolve, reject) => {
        apiPut(apiUrl.CustomerDetail, customerDetail).then((resp) =>{
            resolve(resp);
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    }) 
}

export const deleteCustomerDetails = (userId) => {
    return new Promise((resolve,reject) => {
        apiDelete(`${ apiUrl.CustomerDetail }/${ userId }`).then((resp) => {
            resolve()
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
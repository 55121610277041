import React, { useState, useEffect, Suspense, useRef, lazy } from 'react';
import './userlistcontainer.scss';
import AddUser from '../adduser/AddUserContainer';
import { getUserlist, getUserdetail } from './userlistHelper';
import SortColumn from '../../component/sortcolumn/SortColumn';
// import PaginationComponent from '../../component/pagination/PaginationComponent';
import { connect } from 'react-redux';
import Header from '../../component/header/Header';
import StaticLabels from '../../utils/messages';
import emailIconActive from '../../images/mail_icon_blue.svg';
import emailIconInactive from '../../images/mail_icon_grey.svg';
import { showLoader, getRoleKeys } from '../../utils/commonUtils';
import PropTypes from 'prop-types';
import HighlightText from '../../component/highlighttext/HighlightText';
import { OverlayTrigger, Popover } from 'react-bootstrap';
// import InfiniteScroll from '../../component/infiniteScroll/InfiniteScroll';

const InfiniteScroll = lazy(() => import('../../component/infiniteScroll/InfiniteScroll'));
// --TODO: Add lazy loading
// const AddUser = React.lazy(() => import('../adduser/AddUserContainer'));

const UserListContainer = (props) => {
	const userSortCol = sessionStorage.getItem('userSortCol') || 'created_date';
	const userSortOrd = sessionStorage.getItem('userSortOrd') || 'dsc';
	const sKey = 'userRecPerPage';
	const sValue = sessionStorage.getItem(sKey);
	const [ userList, setUserList ] = useState([]);
	const [ addUser, setAddUser ] = useState(false);
	const [ editUser, setEditUser ] = useState(false);
	const [ userInfo, setUserInfo ] = useState({});
	const [ isDescending, setIsDescending ] = useState(userSortOrd === 'dsc' ? true : false);
	const [ sortOrder, setSortOrder ] = useState(userSortOrd);
	const [ sortedColumn, setSortedColumn ] = useState(userSortCol);
	const [ pageNo, setPageNo ] = useState(1);
	const [ recordsPerPage, setRecordsPerPage ] = useState(sValue || 10);
	const [ searchText, setSearchText ] = useState('');
	const [ toggleState, setToggleState ] = useState(false);
	const { loggedInUser } = props;

	const [ haveMoreUsers, setHaveMoreUsers ] = useState(false);
	const [ loading, setloading ] = useState(false);
	const isInitialMount = useRef(true);

	// const isRoot =
	// 	loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ROOT');
	// const isAdmin =
	// 	loggedInUser.user_roles && loggedInUser.user_roles.length > 0 && loggedInUser.user_roles.indexOf('ROLE_ADMIN');

	const userRoles = getRoleKeys(loggedInUser.user_roles);
	// const isDAAdmin = userRoles.indexOf('ROLE_DADMIN') !== -1 ? true : false;
	const isAdmin = userRoles.indexOf('ROLE_ADMIN') !== -1 ? true : false;
	const isRoot = userRoles.indexOf('ROLE_ROOT') !== -1 ? true : false;
	const SysAdmin = isRoot || isAdmin ? true : false;

	const setNextPage = () => {
		setPageNo((prevPageNo) => prevPageNo + 1);
	};

	const getUsers = () => {
		getUserlist(pageNo, recordsPerPage, searchText, sortedColumn, isDescending)
			.then((resp) => {
				// add new records to userlist while srolling
				const updatedList = [ ...userList, ...resp.data.payload ];
				setUserList(updatedList);
				setHaveMoreUsers(resp.data.totalEntries > recordsPerPage * pageNo);
				setloading(false);
				showLoader(false);
			})
			.finally(() => {
				showLoader(false);
			});
	};

	useEffect(
		() => {
			const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.history.replaceState({}, document.title, url);

			if (isInitialMount.current) {
				showLoader(true);
				getUsers();
				isInitialMount.current = false;
				//call useEffect with setLoader(true) only when component mounts
			} else {
				setloading(true);
				setTimeout(() => {
					getUsers();
				}, 2000);
			}
		},
		[ recordsPerPage, pageNo, searchText, isDescending, sortedColumn, toggleState ]
	);

	const handleClose = () => {
		setAddUser(false);
		setEditUser(false);
	};

	const getUserDetail = (userId) => () => {
		getUserdetail(userId)
			.then((resp) => {
				setUserInfo(resp.data.payload);
				setEditUser(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getUserRoles = (roles) => {
		const roleName = [];
		if (roles && roles.length > 0) {
			roles.map((role) => {
				roleName.push(role.display_name);
			});
		}
		return roleName.join(', ');
	};

	const sortColumn = (columnName, order) => {
		setloading(false);
		setSortedColumn(columnName);
		setSortOrder(order);
		sessionStorage.setItem('userSortCol', columnName);
		sessionStorage.setItem('userSortOrd', order);
		setPageNo(1);
		setUserList([]);
		setIsDescending(order === 'asc' ? false : true);
	};

	// const gotoPage = (pageno, recordsperpage) => {
	// 	setPageNo(pageno);
	// 	setRecordsPerPage(recordsperpage);
	// };

	const searchUser = (text) => {
		setUserList([]);
		setSearchText(text);
		setPageNo(1);
	};

	const resetUser = () => {
		setUserList([]);
		setSearchText('');
		setSortOrder('dsc');
		setIsDescending(true);
		setPageNo(1);
		setSortedColumn('created_date');
		sessionStorage.setItem('userSortCol', 'created_date');
		sessionStorage.setItem('userSortOrd', 'dsc');
		setToggleState(!toggleState);
	};

	return (
		((isRoot && SysAdmin) || (isAdmin && SysAdmin)) && (
			<div id="user-container">
				<Header
					title={StaticLabels.Userlist_Title}
					addClick={() => setAddUser(true)}
					showAddButton={isRoot || isAdmin}
					showAddButtonText={StaticLabels.User_AddUser_Btn}
					searchFunction={searchUser}
					searchText={searchText}
				/>
				<div className="userlist">
					<table className="tbl-list">
						<thead className="tbl-header">
							<tr>
								<td>
									<SortColumn
										className="status"
										displayName={StaticLabels.User_Theader_Status}
										columnName={'status'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="name"
										displayName={StaticLabels.User_Theader_Name}
										columnName={'name'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="email"
										displayName={StaticLabels.User_Theader_Email}
										columnName={'email'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="phone"
										displayName={StaticLabels.User_Theader_Phone}
										columnName={'phone'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="organisation"
										displayName={StaticLabels.User_Theader_Organization}
										columnName={'organizationName'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="role"
										displayName={StaticLabels.User_Theader_Roles}
										columnName={'userRoles'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td>
									<SortColumn
										className="date"
										displayName={StaticLabels.User_Theader_Date}
										columnName={'created_date'}
										sortOrder={sortOrder}
										sortedColumnName={sortedColumn}
										sortColumn={sortColumn}
									/>
								</td>
								<td className="edit" />
							</tr>
						</thead>
						<tbody className="tbl-body">
							{//props.userList.length > 0 &&
							//   props.userList.map((list, index) => {
							userList.length > 0 &&
								userList.map((list) => {
									const status = list.status.toLowerCase();
									return (
										<tr
											key={list.id}
											className={`tbl-row ${status !== 'active' ? 'inactive-row' : ''}`}
										>
											<td>
												<div
													className={`status  ${status === 'active' ? 'active' : 'inactive'}`}
												>
													●{' '}
												</div>
											</td>
											<td>
												{
													<OverlayTrigger
														trigger={[ 'hover', 'focus' ]}
														rootClose={true}
														id="email-popup"
														placement="auto"
														overlay={
															<Popover bsPrefix="email-popover popover">
																<Popover.Content className="email-popover-content">
																	<span>
																		{list.firstName} {list.lastName}
																	</span>
																</Popover.Content>
															</Popover>
														}
													>
														<div className="email-text">
															<div className="line-brk">
																<HighlightText
																	text={`${list.firstName} ${list.lastName}`}
																	searchText={searchText}
																	className="name"
																/>
															</div>
														</div>
													</OverlayTrigger>
												}
												{
													// <HighlightText
													// 	text={`${list.firstName} ${list.lastName}`}
													// 	searchText={searchText}
													// 	className="name"
													// />
												}
											</td>
											<td>
												<OverlayTrigger
													trigger={[ 'hover', 'focus' ]}
													rootClose={true}
													id="email-popup"
													placement="right"
													overlay={
														<Popover bsPrefix="email-popover popover">
															<Popover.Content className="email-popover-content">
																<span className="email-icon">
																	{status !== 'active' ? (
																		<img
																			src={emailIconInactive}
																			alt="Inactive_email"
																		/>
																	) : (
																		<img src={emailIconActive} alt="Active_email" />
																	)}
																</span>
																<span
																	className={`${status !== 'active'
																		? 'inactive-email'
																		: 'text'}`}
																>
																	{list.email}
																</span>
															</Popover.Content>
														</Popover>
													}
												>
													<div className="email-text">
														<div className="line-brk">
															<span>
																{status !== 'active' ? (
																	<img
																		className="active_email"
																		src={emailIconInactive}
																		alt="Inactive_email"
																	/>
																) : (
																	<img
																		className="active_email"
																		src={emailIconActive}
																		alt="Active_email"
																	/>
																)}
															</span>
															<HighlightText
																text={list.email}
																searchText={searchText}
																type="email"
																className={`email email-blue ${status !== 'active'
																	? 'inactive-email'
																	: ''}`}
															/>
														</div>
													</div>
												</OverlayTrigger>
											</td>
											<td>
												<div className="phone">{list.phone}</div>
											</td>
											<td>
												<HighlightText
													text={list.organizationName}
													searchText={searchText}
													className="organisation"
												/>
											</td>
											<td>
												<OverlayTrigger
													trigger={[ 'hover', 'focus' ]}
													rootClose={true}
													id="email-popup"
													placement="auto"
													overlay={
														<Popover bsPrefix="email-popover popover">
															<Popover.Content className="email-popover-content">
																<span>{getUserRoles(list.user_roles)}</span>
															</Popover.Content>
														</Popover>
													}
												>
													<div className="email-text">
														<div className="line-brk">
															<div className="role">
																{
																	// list.userRoles &&
																	// list.userRoles.length > 0 &&
																	// list.userRoles[0].split('_')[1].toLowerCase()
																}
																{
																	// getUserRoles(list.userRoles)
																	<HighlightText
																		text={getUserRoles(list.user_roles)}
																		searchText={searchText}
																		className="organisation"
																	/>
																}
															</div>
														</div>
													</div>
												</OverlayTrigger>
											</td>
											<td>
												<div className="date creation-date">{list.created_date}</div>
											</td>
											{(isRoot || isAdmin) && (
												<td>
													<div className="edit" onClick={getUserDetail(list.id)}>
														<i className="fas fa-pencil-alt" aria-hidden="true" />
													</div>
												</td>
											)}
										</tr>
									);
								})}
							<Suspense fallback={<tr><td>Loading...</td></tr>}>
								<InfiniteScroll
									loading={loading}
									haveMoreRecords={haveMoreUsers}
									setNextPage={setNextPage}
								/>
							</Suspense>
						</tbody>
					</table>
					{!loading &&
					!haveMoreUsers && pageNo > 1 && (
						<div id="no-more-records" className="no-records no-more-records">
							No more records.
						</div>
					)}
					{loading && (
						<div className="loading-list">
							<div className="loading">loading</div>
							<div className="dot-pulse" />
						</div>
					)}
					{addUser && (
						<Suspense fallback={<div>Loading...</div>}>
							<AddUser
								open={addUser}
								addUser={addUser}
								handleClose={handleClose}
								getUserList={resetUser}
							/>
						</Suspense>
					)}
					{
						// 	props.userList && (
						// 	<PaginationComponent
						// 		totalItemsCount={totalUserCount}
						// 		sessionKey={sKey}
						// 		gotoPage={gotoPage}
						// 		currentPage={pageNo}
						// 	/>
						// )
					}
					{editUser && (
						<AddUser
							open={editUser}
							userInfo={userInfo}
							handleClose={handleClose}
							getUserList={resetUser}
						/>
					)}
				</div>
			</div>
		)
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.redUserInfo.userInfo,
		rolesList: state.redCommon.roles,
		userList: state.redCommon.userList
	};
};

export default connect(mapStateToProps)(UserListContainer);

UserListContainer.propTypes = {
	rolesList: PropTypes.arrayOf(PropTypes.object),
	loggedInUser: PropTypes.object
};

import React, { useState, useEffect } from 'react';
// import auth0 from 'auth0-js';
// import { AUTH_CONFIG } from '../../constants';
import ButtonNormal from '../../component/buttonnormal/ButtonNormal';
import './header.scss';
import IconSearch from '../../images/search_icon.svg';
import StaticLabels from '../../utils/messages';
import PropTypes from 'prop-types';

const Header = (props) => {
	const [ seachPlaceHolder, setSeachPlaceHolder ] = useState(StaticLabels.Search_Placeholder);
	const [ searchText, setSearchText ] = useState(props.searchText);
	// const logoutUser = () => {
	// 	sessionStorage.removeItem('accessToken');
	// 	const webAuth = new auth0.WebAuth({
	// 		domain: AUTH_CONFIG.domainName,
	// 		clientID: AUTH_CONFIG.clientID  //eslint-disable-line
	// 	});

	// 	webAuth.logout({
	// 		returnTo: AUTH_CONFIG.returnTo,
	// 		client_id: AUTH_CONFIG.clientID  //eslint-disable-line
	// 	});
	// };

	useEffect(
		() => {
			setSearchText(props.searchText);
		},
		[ props.searchText ]
	);

	const ClearText = (event) => {
		setSearchText('');
		props.searchFunction(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			props.searchFunction(event.target.value);
		}
	};

	const onChange = (event) => {
		setSearchText(event.target.value);
		if (event.target.value.trim() === '') {
			props.searchFunction(event.target.value);
		}
	};

	return (
		<div className="header">
			<div className="left">
				{props.preText &&(
					<span className="page-pretext">{props.preText}</span>
				)}
				<div className="page-title">{props.title}</div>
				{!props.hideSearch && (
					<div className="search-container">
						<img alt="" src={IconSearch} />
						<input
							type="text"
							placeholder={seachPlaceHolder}
							className="search"
							onFocus={() => {
								setSeachPlaceHolder('');
							}}
							onBlur={() => {
								setSeachPlaceHolder(StaticLabels.Search_Placeholder);
							}}
							onKeyDown={handleKeyDown}
							value={searchText}
							onChange={onChange}
						/>
						{searchText && (
							<span className="searchCancel" onClick={ClearText}>
								X
							</span>
						)}
					</div>
				)}
			</div>
			<div className="right">
				{props.showAddButton && (
					<ButtonNormal
						text={`${ props.showAddButtonText }`}
						className="header-btn"
						add="add"
						id="add-button"
						onClick={props.addClick}
					/>
				)}
			</div>
		</div>
	);
};
export default Header;

Header.propTypes = {
	searchFunction: PropTypes.func,
	title: PropTypes.string,
	showAddButton:  PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number
	  ]),
	showAddButtonText: PropTypes.string,
	addClick: PropTypes.func,
	hideSearch: PropTypes.bool,
	searchText: PropTypes.string,
	preText: PropTypes.string
};

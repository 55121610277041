import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getMetadataTableContent, getImageData } from './documentHelper';
import DocumentTable from '../../component/documenttable/Documenttable';
import DocumentImage from '../../component/documentimage/DocumentImage';
import PdfViewer from '../../component/pdfviewer/PdfViewer';
import { connect } from 'react-redux';
import IconSearch from '../../images/search_icon.svg';

const DocumentRawContent = (props) => {
	const [ rawData, setRawData ] = useState(props.fileContent);

	const studyId = sessionStorage.getItem('toggleStudyDocId') && sessionStorage.getItem('toggleStudyDocId');

	const searchText = (event) => {
		const allSearchBox = Array.from(document.querySelectorAll('.searchbox'));
		const sequence = allSearchBox.indexOf(event.target);
		const searchTextString = event.target.value;
		const relatedTable = document.querySelectorAll('table')[sequence];
		if (event.target.value) {
			event.target.parentElement.querySelector('.clearbutton').style.display = 'inline';
		} else {
			event.target.parentElement.querySelector('.clearbutton').style.display = 'none';
		}
		let hasData = false;
		relatedTable.querySelectorAll('tr:not(:first-child)').forEach(function(row) {
			let found = false;
			row.querySelectorAll('td').forEach(function(column) {
				if (column.innerText.toLocaleLowerCase().indexOf(searchTextString.toLocaleLowerCase()) !== -1) {
					found = true;
					hasData = true;
				}
			});
			if (found) {
				row.style.display = 'revert';
			} else {
				row.style.display = 'none';
			}
		});
		if (hasData) {
			event.target.parentElement.querySelector('.msg').style.display = 'none';
		} else {
			event.target.parentElement.querySelector('.msg').style.display = 'revert';
		}
	};

	const clearSearchText = (event) => {
		const searchInput = event.target.parentElement.querySelector('input');
		searchInput.value = '';
		searchInput.dispatchEvent(new Event('keyup'));
	};

	useEffect(
		() => {
			if (!props.docType) {
				var regex = /\bMETADATA_TABLE_([^\s^<>]+)+/g;
				var imageRegex = /\bMETADATA_IMAGE_([^\s^<>]+)+/g;
				let temp = props.fileContent;

				const imageIds = props.fileContent.match(imageRegex);
				const metadataImageAPI =
					imageIds &&
					imageIds.map((id) => {
						return getImageData(id.slice(15));
					});
				metadataImageAPI &&
					Promise.all(metadataImageAPI)
						.then((resp) => {
							resp.map((res) => {
								const imageId = res.config.url.match(/(?!.*\/).+/i);
								temp = temp.replace(imageRegex, function(word) {
									if (`METADATA_IMAGE_${imageId}` === word) {
										word.replace('METADATA_IMAGE_', '');
										return DocumentImage(res.config.url);
									} else {
										return word;
									}
								});
							});
							setRawData(temp);
						})
						.catch((err) => {
							console.log(err);
						});

				// metadataAPI = [ getMetaDataProfilingMetrics(studyId), getMetaDataStructuredDataTransform(studyId) ];
				const tableIds = props.fileContent.match(regex);
				const metadataAPI =
					tableIds &&
					tableIds.map((id) => {
						return getMetadataTableContent(id.slice(15), studyId);
						// return getMetadataTableContent(id.slice(15).replaceAll('_', '-'), studyId);
					});
				metadataAPI &&
					Promise.all(metadataAPI)
						.then((resp) => {
							resp.map((res) => {
								// baseUrl = "http://localhost:8080/metadata/structured-data-transform/get-for-study"

								// structured_data_transform
								// profiling_metrics
								const tableIdString = res.config.url.match(/\/metadata\/(.*?)\/get-for-study/i)[1];
								// .replaceAll('-', '_');

								temp = temp.replace(regex, function(word) {
									if (`METADATA_TABLE_${tableIdString}` === word) {
										word.replace('METADATA_TABLE_', '');
										return DocumentTable(res.data.payload);
									} else {
										return word;
									}
								});
							});
							setRawData(temp);
						})
						.catch((err) => {
							console.log(err);
						});
			}
		},
		[ studyId ]
	);

	useEffect(
		() => {
			document.querySelectorAll('table').forEach(function(tableElement) {
				var containerSearch = document.createElement('div');
				var searchInput = document.createElement('INPUT');
				// var searchImg = document.createElement('img');
				// searchImg.src= {IconSearch};
				searchInput.className = 'searchbox';
				searchInput.placeholder = 'Search table';
				searchInput.style.marginBottom = '10px';
				searchInput.style.padding= '8px';
				searchInput.style.paddingLeft= '18px';
				searchInput.addEventListener('keyup', searchText);
				var clearButton = document.createElement('span');
				clearButton.className = 'clearbutton';
				clearButton.innerText = 'X';
				clearButton.style.marginLeft = '-12px';
				clearButton.style.cursor = 'pointer';
				clearButton.style.display = 'none';
				clearButton.addEventListener('click', clearSearchText);
				var msgNoData = document.createElement('span');
				msgNoData.className = 'msg';
				msgNoData.innerText = 'No records found';
				msgNoData.style.display = 'none';
				msgNoData.style.marginLeft = '10px';
				searchInput.style.borderRadius = '6px';
				searchInput.style.width= '700px';
				searchInput.style.border= '0.5px solid #bbbbbb';
				searchInput.style.fontStyle= 'italic';
				containerSearch.append(searchInput);
				containerSearch.append(clearButton);
				containerSearch.append(msgNoData);
				tableElement.prepend(containerSearch);
			});
		},
		[ rawData ]
	);

	return (
		<React.Fragment>
			{props.docType === 'application/pdf' ? (
				<PdfViewer fileUrl={rawData.config.url} />
			) : (
				<div
					id="document-content"
					dangerouslySetInnerHTML={{
						__html: rawData
					}}
				/>
			)}
		</React.Fragment>
	);
};

const mapStateToProps = () => {
	return {};
};
export default connect(mapStateToProps)(DocumentRawContent);

DocumentRawContent.propTypes = {
	fileContent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	docType: PropTypes.string
};
